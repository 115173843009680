import classnames from 'classnames';

import { Condition, ConditionsList, Defaults } from '~/components/conditions';

type SectionHeaderPropsType = {
  title: string;
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'div';
};

export function SectionHeader({ title, className, variant = 'h1' }: SectionHeaderPropsType) {
  const mergedClassName = classnames('text-[36px]', 'font-bold', 'text-white', className);
  return (
    <ConditionsList>
      <Condition condition={variant === 'h2'}>
        <h2 className={mergedClassName}>{title}</h2>
      </Condition>
      <Condition condition={variant === 'h3'}>
        <h3 className={mergedClassName}>{title}</h3>
      </Condition>
      <Condition condition={variant === 'div'}>
        <div className={mergedClassName}>{title}</div>
      </Condition>
      <Defaults>
        <h1 className={mergedClassName}>{title}</h1>
      </Defaults>
    </ConditionsList>
  );
}
