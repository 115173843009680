import { Divider } from '@mui/material';
import classnames from 'classnames';
import { NavLink, Route, Switch } from 'react-router-dom';

import { AuthWrapper } from '~/components/auth/auth-wrapper';
import { WalletCredentialsModal } from '~/components/common/credentials';
import { Header } from '~/components/layout/header';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppProvider } from '~/context/app-provider';
import { FileUploadProvider } from '~/context/file-upload-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { uploader } from '~/model/uploader';
import { Router } from '~/routes/router';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import packageJson from '../../package.json';
import styles from './app.module.css';
import { AppFrame } from './app-frame';

export const App = () => {
  console.log(
    `%cFreeport Creator Suite Version: ${packageJson.version}`,
    `color:white; background-color:black; border-left: 1px solid yellow; padding: 4px;`,
  );

  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();
  const videoUrl = putUrlParam(routes[RoutesEnum.VIDEO_LIBRARY].url, {}, activeTenant);
  const videoSubscriptionUrl = putUrlParam(routes[RoutesEnum.VIDEO_SUBSCRIPTIONS].url, {}, activeTenant);
  const videoCollectionsUrl = putUrlParam(routes[RoutesEnum.VIDEO_COLLECTIONS].url, {}, activeTenant);

  return (
    <AppProvider>
      <AppFrame>
        <AuthWrapper>
          <WalletCredentialsModal />
          <FileUploadProvider uploader={uploader}>
            <div className="flex flex-col h-full">
              <Header />
              <div className="flex grow">
                <Switch>
                  <Route path={routes[RoutesEnum.VIDEO].url}>
                    <aside className={classnames(styles['side-menu'])}>
                      <ul>
                        <li>
                          <NavLink className={styles.link} activeClassName={classnames(styles.active)} to={videoUrl}>
                            My videos
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={styles.link}
                            activeClassName={classnames(styles.active, 'text-white')}
                            to={videoCollectionsUrl}
                          >
                            Collections
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={styles.link}
                            activeClassName={classnames(styles.active, 'text-white')}
                            to={videoSubscriptionUrl}
                          >
                            Subscriptions
                          </NavLink>
                        </li>
                      </ul>
                    </aside>
                  </Route>
                </Switch>
                <div className="grow flex flex-col">
                  <Divider sx={{ mb: 11 }} />
                  <Router />
                </div>
              </div>
            </div>
          </FileUploadProvider>
        </AuthWrapper>
      </AppFrame>
    </AppProvider>
  );
};
