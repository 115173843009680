import { Field, useField } from 'react-final-form';

import { SelectValueType } from '~/components/form/fields/select/custom-select/custom-select.types';
import { SelectField } from '~/components/form/fields/select/select-field';
import { SelectFieldPropsType } from '~/components/form/fields/select/select-field/select-field.types';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { getFieldError } from '~/utils/form';

export function CustomSelectFormFiled({ name, required, ...props }: SelectFieldPropsType) {
  const fieldValidator = useTextInputValidator<SelectValueType | undefined>({
    required,
  });
  const {
    input,
    meta: { error, touched },
  } = useField<SelectValueType | undefined>(name, { validate: fieldValidator });
  const { value, onChange } = input;
  return (
    <Field name={name}>
      {(iProps) => (
        <SelectField
          name={name}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iProps}
          onChange={onChange}
          error={getFieldError({ error, touched: touched as boolean })}
        />
      )}
    </Field>
  );
}
