import { Backdrop, Dialog as MuiDialog, Theme } from '@mui/material';
import { ReactNode } from 'react';

import styles from './dialog.module.css';

type DialogProps = Parameters<typeof MuiDialog>[0];

type Props = {
  open?: boolean;
  children: ReactNode;
  onClose?: () => unknown;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
};

export function Dialog({ open = false, children, onClose, maxWidth, fullWidth = true }: Props) {
  return (
    <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: (theme: Theme) => theme.zIndex.appBar + 1 }} open={open}>
      <MuiDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={onClose}
        classes={{ paper: styles.paper }}
        open={open}
      >
        {children}
      </MuiDialog>
    </Backdrop>
  );
}
