import { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Field, useField } from 'react-final-form';

import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { getFieldError } from '~/utils/form';

import { TextAreaField } from '../text-area-field';
import { TextAreaFormFieldPropsType } from './text-area-form-field.types';

export function TextAreaFormField({
  name,
  required,
  onChange,
  onBlur,
  maxLength,
  ...props
}: TextAreaFormFieldPropsType) {
  const fieldValidator = useTextInputValidator<string>({
    required,
  });
  const {
    input,
    meta: { error, touched },
  } = useField<string>(name, { validate: fieldValidator });
  const { value, onChange: setValue } = input;

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      onBlur?.(e);

      input.onBlur(e);
    },
    [input, onBlur],
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(event);

      setValue(event.target.value);
    },
    [onChange, setValue],
  );

  return (
    <Field name={name}>
      {(iProps) => (
        <TextAreaField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...iProps}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          onBlur={handleOnBlur}
          value={value || ''}
          onChange={handleOnChange}
          maxLength={maxLength}
          currentSymbolsLength={value.length}
          error={getFieldError({ error, touched: touched as boolean })}
        />
      )}
    </Field>
  );
}
