import { Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';

import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { Identicon } from '~/components/layout/identicon';

export function AccountTitle() {
  const { accountId } = useParams<{ accountId: string }>();
  return (
    <div className="flex items-center gap-x-2">
      <Avatar alt={accountId} sx={{ bgcolor: 'white', width: 32, height: 32 }}>
        <Identicon value={accountId} />
      </Avatar>
      <h4 className="px-2 text-[2rem]">Account</h4>
      <div className="ml-2 flex gap-x-2 items-center">
        <span className="text-gray-300">{accountId}</span>
        <CopyToClipboard value={accountId} />
      </div>
    </div>
  );
}
