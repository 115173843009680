import { isNil, omitBy } from 'lodash';

import { AuthParams, getAuthHeaders, getUrl, typeGuardMiddleWare, xhrMiddleware } from '~/routes/video/services/shared';
import { getSigner } from '~/services/get-signer';
import { generateWalletCredentials } from '~/services/wallet-auth/wallet-auth.service';

import {
  isSeries,
  isSeriesEdit,
  isSeriesFullArray,
  isShortSeasonsWithParentArray,
  SeasonWithParent,
  Series,
  SeriesEdit,
  SeriesFull,
} from './types';

type Params = {
  id: number | undefined;
  name: string;
  description: string;
  numberOfSeasons: number;
  cover: File | null;
  logo: File | null;
} & AuthParams;

const VIDEO_COLLECTION_BASE_URL = 'api/video/video-collections';

export function saveCollection({
  id,
  name,
  description,
  numberOfSeasons,
  cover,
  logo,
  address,
  signature,
  message,
}: Params): Promise<unknown> {
  const form = new FormData();
  const fields = omitBy({ name, description, numberOfSeasons }, isNil);
  Object.entries(fields).forEach(([key, value]) => {
    form.append(key, value.toString());
  });

  if (cover) {
    form.append('cover', cover);
  }
  if (logo) {
    form.append('logo', logo);
  }

  const method = id == null ? 'POST' : 'PUT';
  const url = getUrl([VIDEO_COLLECTION_BASE_URL, 'series', id].filter(Boolean).join('/'));

  return fetch(url, {
    method,
    body: form,
    headers: getAuthHeaders({ message, address, signature }),
  }).then(xhrMiddleware);
}

export const getSeriesUrl = (): string => getUrl(`${VIDEO_COLLECTION_BASE_URL}/series`);

export const getSeries = async (url: string): Promise<SeriesFull[]> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(url, { headers }).then(xhrMiddleware).then(typeGuardMiddleWare(isSeriesFullArray));
};

export const getEdiCollectionUrl = (id: number): string => getUrl(`${VIDEO_COLLECTION_BASE_URL}/series/${id}`);

export const getCollection = async (url: string): Promise<SeriesEdit> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(url, { headers }).then(xhrMiddleware).then(typeGuardMiddleWare(isSeriesEdit));
};

export const getVideoSeasonsUrl = (): string => getUrl(`${VIDEO_COLLECTION_BASE_URL}/video/seasons`);

export const getVideoSeasons = async (url: string): Promise<SeasonWithParent[]> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(url, { headers }).then(xhrMiddleware).then(typeGuardMiddleWare(isShortSeasonsWithParentArray));
};

export const getParentSeasonForSeriesUrl = (id: number): string =>
  getUrl(`${VIDEO_COLLECTION_BASE_URL}/season-parent/${id}`);

export const getParentSeasonForSeries = async (url: string): Promise<Series> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(url, { headers }).then(xhrMiddleware).then(typeGuardMiddleWare(isSeries));
};
