import classnames from 'classnames';
import { Field } from 'react-final-form';

import fieldStyles from './form-field.module.css';

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: 'text' | 'password' | 'textarea' | 'number' | 'email' | 'url' | 'date';
  rows?: number;
  min?: number | string;
  max?: number | string;
  minLength?: number;
};

const inputClassname = classnames(
  fieldStyles.input,
  'rounded-lg',
  'py-2',
  'px-4',
  'border-2',
  'outline-0',
  'border-white/10',
  'box-border',
  'focus:border-white/40',
  'bg-transparent',
  'w-full',
);

export function FormField({
  disabled = false,
  label,
  max,
  min,
  minLength,
  name,
  placeholder,
  required = false,
  rows = 6,
  type = 'text',
}: Props) {
  return (
    <Field type={type} name={name}>
      {({ input: { onFocus, onChange, onBlur, value, name: inputName, type: inputType } }) => {
        const input = (
          <input
            disabled={disabled}
            required={required}
            minLength={minLength}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            type={inputType}
            value={value}
            className={inputClassname}
            name={inputName}
            min={min}
            max={max}
          />
        );
        const textarea = (
          <textarea
            required={required}
            rows={rows}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            value={value}
            className={inputClassname}
            name={name}
          />
        );
        if (label) {
          return (
            <label className={classnames('flex', 'flex-col', 'gap-y-2', { 'opacity-80': disabled })}>
              <span className="relative left-1">{`${label}${required ? ' *' : ''}`}</span>
              {type === 'textarea' ? textarea : input}
            </label>
          );
        }
        return <>{type === 'textarea' ? textarea : input}</>;
      }}
    </Field>
  );
}
