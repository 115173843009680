import classNames from 'classnames';

import styles from './field-label.module.css';
import { FieldLabelPropsType } from './field-label.types';

export function FieldLabel({
  as: Component = 'label',
  required = false,
  disabled = false,
  title,
  className,
  children,
  isDate,
}: FieldLabelPropsType) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <Component className={classNames(styles.label, className, { [styles['is-date']]: isDate })}>
      <div className={classNames(styles.title, { [styles.disabled]: disabled })}>
        {title && <span>{title}</span>}
        {required && <span className="ml-[5px]">*</span>}
      </div>
      {children}
    </Component>
  );
}
