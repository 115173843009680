import { Backdrop, CircularProgress, TextField } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import { useNetwork, useUserPublicKey } from '~/context/app-context';
import { useMessages } from '~/lib/notificator';
import { getOwnCollections } from '~/services/collections-service';
import { Collection } from '~/types/collection';

import { required } from './validators/required';

const filter = createFilterOptions<Collection>();

export const CollectionField = () => {
  const userPubKey = useUserPublicKey();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [collections, setCollections] = useState([] as Collection[]);
  const { showMessage } = useMessages();
  const network = useNetwork();

  useEffect(() => {
    getOwnCollections(userPubKey)
      .then(setCollections)
      .catch((error: Error) => {
        showMessage(`error during fetching collections by wallet -  ${error.message}`, 'error');
      });
  }, [showMessage, userPubKey]);

  return (
    <Field<Collection | null> validate={(state) => required(state?.name ?? '')} name="collection" type="text">
      {({ input, meta }) => {
        const helperText = 'Select or add new collection in which your item will appear';
        const helpText: unknown = meta.touched ? meta.error || helperText : helperText;

        const createNewCollection = (_: unknown, newValue: string | Collection | null) => {
          const create = async (collectionName: string) => {
            setLoading(true);
            showMessage(`Collection ${collectionName} is going to be created`, 'info');

            // eslint-disable-next-line no-unsafe-optional-chaining
            const { collectionAddress: address } = await network?.driver?.createCollection(collectionName);

            input.onChange({
              name: collectionName,
              address,
            });
            setLoading(false);
            showMessage('Your collection has been created', 'success');
          };

          const onError = (error: Error) => {
            showMessage(`error during collection creation ${error.message}`, 'error');
            setLoading(false);
          };

          if (typeof newValue === 'string') {
            create(newValue).catch(onError);
          } else if (newValue?.address === '') {
            create(newValue.name).catch(onError);
          } else input.onChange(newValue);
        };
        return (
          <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Autocomplete<Collection, false, false, true>
              onChange={createNewCollection}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    address: '',
                    name: inputValue,
                    uri: '',
                    id: 0,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              freeSolo
              handleHomeEndKeys
              options={collections}
              getOptionLabel={(option) => (typeof option === 'string' ? '' : option.name)}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.address}>
                  {option.address ? option.name : `Add "${option.name}"`}
                </li>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  id={params.id}
                  color="primary"
                  disabled={params.disabled}
                  error={meta.touched && meta.invalid}
                  fullWidth
                  helperText={helpText ? String(helpText) : ''}
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  inputProps={params.inputProps}
                  /* eslint-disable-next-line react/jsx-no-duplicate-props */
                  InputProps={params.InputProps}
                  size={params.size}
                  type={input.type}
                  multiline={input.multiple}
                  value={input.value}
                />
              )}
            />
          </>
        );
      }}
    </Field>
  );
};
