import { isRecord } from '~/types/is-record';
import { isString, isStringUrl } from '~/types/string';

import { AssetContentType } from './asset-ddc';

export type NftAsset = {
  name: string;
  description: string;
  asset: string;
  preview: string;
  contentType?: AssetContentType;
};

export const isNftAsset = (val: unknown): val is NftAsset =>
  isRecord(val) && isString(val.name) && isString(val.description) && isStringUrl(val.preview);
