import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from '@mui/material';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';

import { CustomSelectPropsType } from '~/components/form/fields/select/custom-select/custom-select.types';
import { ControlContainer } from '~/components/ui';
import { shortenNftId } from '~/lib/utils';

import styles from './custom-select.module.css';

export const CustomSelect = forwardRef<HTMLDivElement, CustomSelectPropsType>(
  ({ value, onChange, isLoading, name, defaultValue, disabled, options }, ref) => {
    const [expanded, setExpand] = useState(false);

    const handleOnDropdownClick = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      setExpand((prevState) => !prevState);
    }, []);

    const handleOnChange = useCallback(
      (data) => {
        onChange?.(data);
      },
      [onChange],
    );

    return (
      <ControlContainer disabled={disabled || isLoading}>
        <Accordion
          ref={ref}
          sx={{
            '.MuiAccordionSummary-root': {
              padding: '14px 16px!important',
            },
            '.MuiAccordionSummary-content': {
              margin: 0,
            },
            'MuiAccordionSummary-root:before': {
              display: 'none',
            },
            'MuiAccordionDetails-root': {
              padding: '0 0 0 16px',
            },
            background: '#191E31',
            border: '1px solid rgb(255 255 255 / 0.1)',
            borderRadius: '12px',
          }}
          disableGutters
          onClick={handleOnDropdownClick}
          expanded={expanded}
        >
          <AccordionSummary
            sx={{
              padding: '14px 16px',
            }}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <input type="text" hidden value={value} name={name} disabled={disabled} defaultValue={defaultValue} />
            {value && value !== '' ? shortenNftId(value) : 'Choose NFT reward'}
          </AccordionSummary>
          <AccordionDetails>
            {options && options?.length > 0 ? (
              options.map(({ label, value: optionValue }) => (
                <MenuItem
                  key={optionValue}
                  onClick={() => handleOnChange(optionValue)}
                  value={optionValue}
                  className={classnames(styles['option-item'])}
                >
                  <div className="flex justify-between w-[100%]">
                    <div className="text-sm text-white">{label}</div>
                    <div className="text-sm text-white">
                      Supply: <b>2</b>
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <p>No options</p>
            )}
          </AccordionDetails>
        </Accordion>
      </ControlContainer>
    );
  },
);
