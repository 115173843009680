/* eslint-disable jsx-a11y/media-has-caption */
import { Stack } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import { AssetContentType } from '~/types/asset-ddc';

type Props = {
  contentUrl: Blob | string;
  type: AssetContentType;
  title: string;
};

export const AssetContentView = ({ contentUrl, type, title }: Props): ReactElement | null => {
  const [content, setContent] = useState('');
  const url = useMemo(
    () => (typeof contentUrl === 'string' ? contentUrl : URL.createObjectURL(contentUrl)),
    [contentUrl],
  );

  useEffect(() => {
    if (typeof contentUrl === 'string') {
      setContent(contentUrl);
    } else {
      // eslint-disable-next-line no-void
      void contentUrl.arrayBuffer().then((buffer) => {
        setContent(URL.createObjectURL(new Blob([buffer], { type })));
      });
    }
  }, [type, contentUrl]);

  if (type === 'image/svg+xml' || type === 'image/png' || type === 'image/jpeg' || type === 'image/gif') {
    return <Stack>{content && <img alt={title} src={content} />}</Stack>;
  }

  if (type === 'audio/mp4' || type === 'audio/mpeg' || type === 'audio/x-wav' || type === 'audio/ogg') {
    return (
      <audio title={title} controls autoPlay>
        <source src={url} />
      </audio>
    );
  }

  if (type === 'video/mp4' || type === 'video/webm' || type === 'video/ogg') {
    return (
      <video width="600" controls autoPlay>
        <source src={url} />
      </video>
    );
  }

  console.error(`Unhandled media type ${type}`);
  return null;
};
