import React, { useContext } from 'react';

import { Stepper } from '~/components/common/wizard/components/stepper';
import { StepsProgressPropsType } from '~/components/common/wizard/components/steps-progress/steps-progress.types';
import { WizardContext } from '~/components/common/wizard/wizard.context';

export function StepsProgress({ steps, titleArr }: StepsProgressPropsType) {
  const { step: currentStep, completed } = useContext(WizardContext);
  return <Stepper titleArr={titleArr} steps={steps} currentStep={currentStep} completed={completed} />;
}
