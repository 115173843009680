import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useSelectTenant } from '~/lib/hooks/use-select-tenant';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { setActiveTenant } from '~/services/tenant.service';

interface ConnectWalletModalProps {
  open: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 343,
  border: '1px solid #000',
  bgcolor: 'background.paper',
  borderRadius: 1.5,
  boxShadow: 24,
};

const TenantButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.75),
  color: theme.palette.text.secondary,
  borderRadius: '1.5rem',
  justifyContent: 'left',
}));

export const ChangeTenantModal = ({ open, onClose }: ConnectWalletModalProps) => {
  const { tenants } = useSelectTenant();
  const history = useHistory();
  const { putUrlParam } = useUrlParams();

  const selectTenant = (tenant: string) => {
    setActiveTenant(tenant);
    history.replace(putUrlParam(routes[RoutesEnum.HOME].url, {}, tenant));
    window.location.reload();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Change Tenant
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ p: 3 }}>
          <Stack spacing={2}>
            {tenants.map((tenant) => (
              <TenantButton
                key={tenant}
                variant="outlined"
                onClick={() => {
                  selectTenant(tenant);
                }}
              >
                <Typography sx={{ marginLeft: 1.25 }}>{tenant.toUpperCase()}</Typography>
              </TenantButton>
            ))}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
