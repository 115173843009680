import { TextField } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTimePicker as MUIDateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field } from 'react-final-form';

import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { SyncFieldValidator } from '~/components/form/fields/validators';

interface DatePickerProps extends Omit<DateTimePickerProps<any>, 'renderInput' | 'onChange' | 'value'> {
  name: string;
  label?: string;
  required: boolean;
  validate?: SyncFieldValidator;
  format?: string;
}

/* eslint-disable react/jsx-no-duplicate-props */
export const DateTimePicker = ({
  name,
  label,
  validate,
  required = false,
  format = 'MM/dd/yyyy hh:mm a',
}: DatePickerProps) => {
  const fieldValidator = useTextInputValidator({
    required,
    validate,
  });

  return (
    <Field validate={fieldValidator} type="text" name={name}>
      {({ input }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MUIDateTimePicker
            onChange={input.onChange}
            value={input.value}
            label={label}
            format={format}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            renderInput={(props: any) => (
              <TextField
                id={input.name}
                value={props.value}
                inputProps={props.inputProps}
                InputProps={props.InputProps}
                label={props.label}
                error={props.error}
                disabled={props.disabled}
                inputRef={props.inputRef}
                ref={props.ref}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      )}
    </Field>
  );
};
