import classnames from 'classnames';
import { FocusEvent, forwardRef, useCallback, useState } from 'react';

import { CustomInputPropsType } from '~/components/form/fields/custom-input/custom-input.types';
import { ControlContainer } from '~/components/ui/control-container';

import styles from './custom-input.module.css';

export const CustomInput = forwardRef<HTMLInputElement, CustomInputPropsType>(
  (
    {
      autoComplete,
      autoFocus = false,
      className,
      containerClassName,
      defaultValue,
      disabled = false,
      disabledPaste = false,
      hasError = false,
      inputMode,
      max,
      min,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      placeholder,
      prefix,
      readOnly = false,
      tabIndex,
      type = 'text',
      value,
      step,
      suffix,
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const onInputFocus = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setFocused(true);

        onFocus?.(e);
      },
      [onFocus],
    );

    const onInputBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        setFocused(false);

        onBlur?.(e);
      },
      [onBlur],
    );

    return (
      <ControlContainer
        containerClassName={containerClassName}
        disabled={disabled}
        focused={focused}
        hasError={hasError}
        prefix={prefix}
        suffix={suffix}
      >
        <input
          autoComplete={autoComplete}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={autoFocus}
          className={classnames(styles.input, className, {
            [styles['has-error']]: hasError,
          })}
          defaultValue={defaultValue}
          disabled={disabled}
          inputMode={inputMode}
          max={max}
          maxLength={max}
          min={min}
          minLength={minLength}
          name={name}
          onPaste={(e) => {
            if (disabledPaste) {
              e.preventDefault();
            }
          }}
          onBlur={onInputBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onInputFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          tabIndex={tabIndex}
          type={type}
          value={value}
          step={step}
        />
      </ControlContainer>
    );
  },
);
