import classnames from 'classnames';
import { Link } from 'react-router-dom';

import SmallArrow from '~/assets/small-arrow.svg?jsx';

import styles from './return-back-link.module.css';

type Props = {
  children: string;
  url: string;
  className?: string;
};

export function ReturnBackLink({ url, children, className = '' }: Props) {
  return (
    <Link
      className={classnames(
        className,
        'hover:opacity-90',
        'transition-opacity',
        'inline-flex',
        'gap-x-2',
        'text-base',
        'items-center',
      )}
      to={url}
    >
      <span className="rotate-180 text-[#e851c3]">
        <SmallArrow />
      </span>
      <span className={styles.text}>{children}</span>
    </Link>
  );
}
