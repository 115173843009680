import classnames from 'classnames';

import styles from '../video.module.css';

type Props = {
  submit?: boolean;
  children: string;
  disabled?: boolean;
  onClick?: () => unknown;
  form?: string;
  variant?: 'outlined' | 'contained';
};

export function Button({ submit = false, children, disabled = false, onClick, form, variant = 'contained' }: Props) {
  return (
    <button
      form={form}
      disabled={disabled}
      className={classnames(
        { [styles.contained]: variant === 'contained' },
        { [styles.outlined]: variant === 'outlined' },
        { 'w-[500px]': variant === 'contained' },
        'h-[52px]',
        'justify-center',
        'disabled:opacity-70',
        'disabled:pointer-events-none',
      )}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
    >
      {children}
    </button>
  );
}
