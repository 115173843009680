import { ItemLayout } from '~/components/layout/item-layout';
import { AuctionItem } from '~/routes/auctions/auction-item';

import { NftNavigation } from './nft-navigation';

export const NftAuction = () => (
  <ItemLayout navigation={NftNavigation}>
    <AuctionItem />
  </ItemLayout>
);
