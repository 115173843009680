import './css/main.pcss';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';

import { App } from './app';
import { reportWebVitals } from './report-web-vitals';

render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.querySelector('#root'),
);
reportWebVitals();
