import { useEffect } from 'react';
import useSWR from 'swr/immutable';

import { fetcher } from '~/constants/fetcher';
import { Tournament } from '~/routes/gaming/gaming.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

export function useTournamentQuery({ id }: { id?: number }) {
  const { data, isLoading, error, mutate } = useSWR<Tournament, Error>(
    id ? { url: TournamentsApiEndpoints.tournamentUrl(id) } : null,
    fetcher,
  );

  useEffect(() => {
    void mutate().then((r) => r);
  }, [mutate]);

  return { data, isLoading, error, mutate };
}
