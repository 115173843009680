import { Series } from '~/routes/video/services/types';
import { isBoolean } from '~/types/is-boolean';
import { isRecord } from '~/types/is-record';
import { isPositiveNumber } from '~/types/numbers';
import { isString, isStringOrNullish } from '~/types/string';

export type SubscriptionUnit = 'week' | 'day' | 'month';
export const isSubscriptionUnit = (val: unknown): val is SubscriptionUnit =>
  val === 'week' || val === 'day' || val === 'month';

export type SubscriptionPlanType = 'base' | 'premium' | 'platinum';
export const isSubscriptionPlanType = (val: unknown): val is SubscriptionPlanType =>
  val === 'base' || val === 'premium' || val === 'platinum';

export type SubscriptionDataAccessStrategy = 'FULL_VIDEO_SERIES';

type SubscriptionPlanMetadata = {
  duration: {
    amount: number;
    units: SubscriptionUnit;
  };
  plan: SubscriptionPlanType;
  coverCid?: string;
};

export type Subscription = {
  id: number;
  title: string;
  description: string;
  priceCents: number;
  status: boolean;
  metadata: SubscriptionPlanMetadata;
  collections: Series[];
};

export const isSubscription = (val: unknown): val is Subscription =>
  isRecord(val) &&
  isPositiveNumber(val.id) &&
  isString(val.title) &&
  isString(val.description) &&
  isPositiveNumber(val.priceCents) &&
  isBoolean(val.status) &&
  isRecord(val.metadata) &&
  isRecord(val.metadata.duration) &&
  isSubscriptionUnit(val.metadata.duration.units) &&
  isPositiveNumber(val.metadata.duration.amount) &&
  isStringOrNullish(val.metadata.coverCid);

export const isSubscriptionArray = (val: unknown): val is Subscription[] =>
  Array.isArray(val) && val.every(isSubscription);
