type ProvimeResolveValue<T> = PromiseLike<T> | T;
type PromiseLikeFn<T> = (value: ProvimeResolveValue<T>) => void;

type DefaultReason = string | Error;
type Reject<R = DefaultReason> = (reason?: R) => void;

type DeferredPromise<T, R> = Promise<T> & {
  isSettled: boolean;
  resolve: PromiseLikeFn<T>;
  reject: Reject<R>;
};

const noop: PromiseLikeFn<unknown> = () => undefined;

export function deferred<T, R = DefaultReason>(): DeferredPromise<T, R> {
  let resolve: PromiseLikeFn<T> = noop;
  let reject: Reject<R> = noop;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const p: DeferredPromise<T, R> = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  p.isSettled = false;
  p.resolve = (val: ProvimeResolveValue<T>) => {
    p.isSettled = true;
    resolve(val);
  };
  p.reject = (reason?: R) => {
    p.isSettled = true;
    reject(reason);
  };
  return p;
}
