import { Container } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';

import { AccountTitle } from '~/components/layout/inner-page-navigation/account-title';
import { NftsTitle } from '~/components/layout/inner-page-navigation/nfts-title';

export const InnerPageNavigation = () => {
  const location = useLocation();
  const isNftDetailsPath =
    matchPath(location.pathname, {
      path: '/:tenantId/creator/nft/:collection/:nftId/:section',
      exact: true,
    }) != null;

  return <Container>{isNftDetailsPath ? <NftsTitle /> : <AccountTitle />}</Container>;
};
