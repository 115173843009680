import classNames from 'classnames';
import { FocusEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ControlContainer } from '~/components/ui';

import styles from './text-area.module.css';
import { TextAreaPropsType } from './text-area.types';

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaPropsType>(
  (
    {
      autoComplete,
      autoFocus = false,
      className,
      containerClassName,
      defaultValue,
      disabled = false,
      expandable = true,
      hasError = false,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      placeholder,
      prefix,
      readOnly = false,
      rows,
      suffix,
      tabIndex,
      value,
    },
    forwardedRef,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleOnFocus = useCallback(
      (e: FocusEvent<HTMLTextAreaElement>) => {
        setFocused(true);

        onFocus?.(e);
      },
      [onFocus],
    );

    const handleOnBlur = useCallback(
      (e: FocusEvent<HTMLTextAreaElement>) => {
        setFocused(false);

        onBlur?.(e);
      },
      [onBlur],
    );

    const ref = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(forwardedRef, () => ref.current as HTMLTextAreaElement);

    useEffect(() => {
      if (!ref.current) {
        return undefined;
      }

      const target = ref.current;

      const observer = new IntersectionObserver(([e]) => {
        if (e.intersectionRatio > 0) {
          target.style.height = 'inherit';
          target.style.height = `${target.scrollHeight}px`;
        }
      });

      observer.observe(target);

      return () => {
        observer.unobserve(target);
      };
    }, []);

    useEffect(() => {
      if (expandable && ref.current) {
        ref.current.style.height = 'inherit';
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }, [value, ref, expandable]);

    return (
      <ControlContainer
        containerClassName={containerClassName}
        disabled={disabled}
        focused={focused}
        hasError={hasError}
        prefix={prefix}
        suffix={suffix}
      >
        <textarea
          autoComplete={autoComplete}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={autoFocus}
          className={classNames(styles['text-area'], className)}
          defaultValue={defaultValue}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
          onBlur={handleOnBlur}
          onChange={onChange}
          onFocus={handleOnFocus}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          rows={rows}
          tabIndex={tabIndex}
          value={value}
        />
      </ControlContainer>
    );
  },
);
