import { useParams } from 'react-router-dom';

import { isRecord } from '~/types/is-record';
import { isString } from '~/types/string';

export function useRouteParam(paramName: string): string | undefined {
  const params = useParams();
  if (isRecord(params)) {
    const result = params[paramName];
    return isString(result) ? result : undefined;
  }
  return undefined;
}
