import { PlayArrow } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import classnames from 'classnames';

import { operationButtonClass } from './constants';

interface ItemPreviewButtonProps {
  onClick?: () => void;
}

export const ItemPreviewButton = ({ onClick }: ItemPreviewButtonProps) => (
  <IconButton className={classnames(operationButtonClass, 'px-6')} onClick={onClick}>
    <PlayArrow />
  </IconButton>
);
