import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { JSXElementConstructor, ReactElement, SyntheticEvent } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

type TabType = {
  url: string;
  title: string;
  icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
};

type Props = {
  tabs: TabType[];
};

export const GenericNavigation = ({ tabs }: Props) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const match = useRouteMatch<{ nftId: string; collection: string }>(routes[RoutesEnum.NFT_AUCTION].url);
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  // TODO: Manage tab values properly
  const tabValue = match
    ? putUrlParam(
        routes[RoutesEnum.NFT_AUCTIONS].url,
        {
          collection: match.params.collection,
          nftId: match.params.nftId,
        },
        activeTenant,
      )
    : pathname;

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    history.push(newValue);
  };

  return (
    <Tabs
      value={tabValue}
      scrollButtons
      variant={isSmallScreen ? 'scrollable' : 'standard'}
      allowScrollButtonsMobile
      onChange={handleTabChange}
      centered={!isSmallScreen}
    >
      {tabs.map((tab) => (
        <Tab disableRipple key={tab.url} value={tab.url} icon={tab.icon} label={tab.title} />
      ))}
    </Tabs>
  );
};
