/* eslint-disable jsx-a11y/media-has-caption */
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import { FlexBox } from '~/components/layout/flex-box';
import { useMessages } from '~/lib/notificator';
import { getEncryptedAsset, TokenAssetDetails } from '~/services/assets.service';

import { EncryptedVideoPortal } from '../video/encrypted-video-portal';

type Props = {
  tokenAsset: TokenAssetDetails;
  title: string;
};

export const EncryptedAssetContentView = ({ tokenAsset, title }: Props): ReactElement | null => {
  const { contentType } = tokenAsset;
  const { showMessage } = useMessages();
  const [content, setContent] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isVideo = contentType && ['video/mp4', 'video/webm', 'video/ogg'].includes(contentType);

  const getContent = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isVideo) {
        setContent(tokenAsset.assetUrl);
      } else {
        setContent(URL.createObjectURL(await getEncryptedAsset(tokenAsset)));
      }
    } catch (e) {
      showMessage('Error getting encrypted asset', 'error');
      console.error(e);
    }
    setIsLoading(false);
  }, [isVideo, showMessage, tokenAsset]);

  if (isLoading) {
    return (
      <FlexBox sx={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </FlexBox>
    );
  }

  if (!contentType) {
    return (
      <FlexBox sx={{ width: '100%', height: '100%' }}>
        <Typography>Unknown Content Type</Typography>
      </FlexBox>
    );
  }

  if (!content) {
    return (
      <FlexBox sx={{ width: '100%', height: '100%' }}>
        <Button variant="outlined" onClick={getContent}>
          Get Encrypted Content
        </Button>
      </FlexBox>
    );
  }

  if (['image/png', 'image/jpeg', 'image/gif'].includes(contentType)) {
    return <Stack>{content && <img alt={title} src={content} />}</Stack>;
  }

  if (['audio/mp4', 'audio/mpeg', 'audio/x-wav', 'audio/ogg'].includes(contentType)) {
    return (
      <audio title={title} controls autoPlay>
        <source src={content} />
      </audio>
    );
  }

  if (isVideo) {
    return <EncryptedVideoPortal videoSrc={content} tokenAsset={tokenAsset} />;
  }

  console.error(`Unhandled media type ${contentType}`);
  return null;
};
