import { useRouteParam } from '~/lib/hooks/use-route-param';

import { EditCollection } from './edit-collection';

type Props = {
  open?: boolean;
  onClose?: () => unknown;
};

export function EditCollectionPage({ open = false, onClose }: Props) {
  const collectionIdParam = useRouteParam('collectionId');
  const id = Number.isNaN(Number(collectionIdParam)) ? undefined : Number(collectionIdParam);

  return <EditCollection onClose={onClose} open={open} id={id} />;
}
