import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { useUserPublicKey } from '~/context/app-context';
import { useWalletDisconnect } from '~/context/auth';
import { getSigner } from '~/services/get-signer';
import {
  clearCachedCredentials,
  generateWalletCredentials,
  getCachedCredentials,
  WalletCredentials,
} from '~/services/wallet-auth/wallet-auth.service';

interface UseWalletCredentialsReturn {
  credentials?: WalletCredentials;
  isAuthenticated: boolean;
  walletAddress?: string;
  isLoading: boolean;
  error: string;
  refetch: () => Promise<WalletCredentials | undefined>;
  authenticate: () => Promise<void>;
  cancel: () => Promise<void>;
}

export const useWalletCredentials = (): UseWalletCredentialsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const walletAddress = useUserPublicKey();
  const disconnect = useWalletDisconnect();

  const { data: credentials, mutate: refetch } = useSWR<WalletCredentials | undefined>(
    'credentials',
    getCachedCredentials,
  );

  const isAuthenticated = useMemo(
    () => !!credentials && credentials.address?.toLowerCase() === walletAddress?.toLowerCase(),
    [credentials, walletAddress],
  );

  useEffect(() => {
    if (!walletAddress) {
      clearCachedCredentials();
    }
  }, [walletAddress]);

  const authenticate = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const signer = await getSigner();
      await generateWalletCredentials(signer);
      await refetch();
    } catch (e: any) {
      setError(e.reason);
    }
    setIsLoading(false);
  };

  const cancel = async () => {
    await disconnect();
    clearCachedCredentials();
  };

  return { credentials, isAuthenticated, walletAddress, isLoading, error, refetch, authenticate, cancel };
};
