import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

import { Input, Textarea } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { TokenAssetDetails } from '~/services/assets.service';
import { isString } from '~/types/string';

import { AssetContent } from './asset-content';
import { FormAsset } from './types/form-asset';

type Props = {
  assetDetails: FormAsset;
  index: number;
  name: string;
  tokenAsset: TokenAssetDetails;
};

export const AssetItem = ({ name, assetDetails, index, tokenAsset }: Props): ReactElement | null => {
  const { fields } = useFieldArray('assets');

  const isReadOnly = Boolean(isString(assetDetails.preview) && assetDetails.preview !== '');
  const allowClose = !isReadOnly;

  return (
    <Box key={name} sx={{ mb: 6 }}>
      <Divider sx={{ mb: 4 }} />

      {allowClose && (
        <Box
          color="text.primary"
          sx={{
            display: 'flex',
            justifyContent: 'end',
            position: 'relative',
            left: '0.5rem',
          }}
        >
          <IconButton
            type="button"
            title="Remove"
            color="secondary"
            onClick={() => {
              fields.remove(index);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <FlexBox sx={{ mb: 3 }}>
        <Input required minLength={3} type="text" label="Title" readOnly={isReadOnly} name={`${name}.name`} />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset title</Typography>
        </HelpPopover>
      </FlexBox>
      <FlexBox>
        <Textarea required label="Description" name={`${name}.description`} readOnly={isReadOnly} />
        <HelpPopover>
          <Typography sx={{ p: 2 }}>Asset description</Typography>
        </HelpPopover>
      </FlexBox>
      <Box sx={{ my: 4 }}>
        <AssetContent asset={assetDetails} tokenAsset={tokenAsset} fileFieldName={`${name}.upload`} />
      </Box>
    </Box>
  );
};
