import { Condition, ConditionsList } from '~/components/conditions';
import { useTournamentsListQuery } from '~/hooks/tournaments';
import { TournamentItem } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournaments-list/components';

import styles from './tournaments-list.module.css';
import { TournamentsListPropsType } from './tournaments-list.types';

export function TournamentsList({ game, onEditTournament }: TournamentsListPropsType) {
  const { data: tournamentsList } = useTournamentsListQuery(game?.id);

  return (
    <ConditionsList>
      <Condition condition={tournamentsList?.length === 0}>{null}</Condition>
      <Condition condition={tournamentsList?.length > 0}>
        <div className={styles.container}>
          {tournamentsList?.map((tournament) => (
            <TournamentItem
              key={tournament.id}
              tournament={tournament}
              game={game}
              onEditTournament={onEditTournament}
            />
          ))}
        </div>
      </Condition>
    </ConditionsList>
  );
}
