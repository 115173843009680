import { forwardRef } from 'react';

import { CustomSelect } from '~/components/form/fields';
import { CustomSelectPropsType } from '~/components/form/fields/select/custom-select/custom-select.types';
import { SelectFieldPropsType } from '~/components/form/fields/select/select-field/select-field.types';
import { field } from '~/components/hoc';

export const SelectField = forwardRef<HTMLDivElement, SelectFieldPropsType>((props, ref) =>
  field<CustomSelectPropsType>({
    ...props,
    ref,
  })(CustomSelect),
);
