import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Container } from '@mui/material';
import { FormApi, getIn, MutableState, Mutator, Tools } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { useCallback, useContext, useMemo } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { SimpleForm } from '~/components/form/simple-form';
import { PageLayout } from '~/components/layout/page-layout';
import { useUserPublicKey } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { useNftMetadata } from '~/routes/wallet/hooks/use-nft-metadata';
import { NftAssetUpdate } from '~/routes/wallet/types/nft-asset-update';
import { putNftAssets } from '~/services/assets.service';
import { NftAsset } from '~/types/nft-asset';

import { AssetItem } from './asset-item';
import { AssetsProcessingBanner } from './assets-processing-banner';
import { FormAsset } from './types/form-asset';

type Props = {
  isOwner: boolean;
  saveButtonTitle?: string;
};

const assetTemplate: NftAsset = {
  asset: '',
  name: '',
  description: '',
  preview: '',
};

type AssetsForm = { assets: FormAsset[] };

const buildFormAsset = (asset: NftAsset = assetTemplate): FormAsset => ({
  ...asset,
  upload: {},
});

// ToDo: Rewrite this view completely
export const AssetsList = ({ isOwner, saveButtonTitle = 'Save' }: Props) => {
  const { nft } = useContext(ItemLayoutContext);
  const { nftId, collection: collectionEntity } = nft;
  const { address: collectionAddress } = collectionEntity;
  const userAddress = useUserPublicKey();
  const { metadata, isLoading, mutate } = useNftMetadata(collectionAddress, nftId);
  const { showMessage } = useMessages();

  const assets: NftAsset[] = useMemo(() => metadata?.assets ?? [], [metadata]);
  const assetContentUrlMutator = useCallback(
    ([index, preview]: [number, string], state: MutableState<AssetsForm>, { changeValue }: Tools<AssetsForm>) => {
      changeValue(state, `assets[${index}].preview`, () => preview);
    },
    [],
  );

  const assetContentMutator = useCallback(
    (
      [index, assetDetails]: [number, FormAsset],
      state: MutableState<AssetsForm>,
      { changeValue }: Tools<AssetsForm>,
    ) => {
      changeValue(state, `assets[${index}]`, () => assetDetails);
    },
    [],
  );

  const formMutators: Record<string, Mutator<AssetsForm, Partial<AssetsForm>>> = useMemo(
    () => ({
      ...arrayMutators,
      updateAssetContent: assetContentUrlMutator,
      updateAsset: assetContentMutator,
    }),
    [assetContentMutator, assetContentUrlMutator],
  );

  const attachToNft = useCallback(
    async (uploads: FormAsset[]) => {
      const allAssets: NftAssetUpdate[] = uploads.map(({ asset: assetUrl, preview: previewUrl, upload, ...rest }) => ({
        ...rest,
        assetUrl,
        previewUrl,
        asset: upload.asset && upload.asset.length > 0 ? upload.asset[0] : undefined,
        preview: upload.preview && upload.preview.length > 0 ? upload.preview[0] : undefined,
      }));

      await putNftAssets({ collection: collectionAddress, nftId, assets: allAssets, address: userAddress });
    },
    [collectionAddress, nftId, userAddress],
  );

  const submit = useCallback(
    async (values: AssetsForm, form: FormApi<AssetsForm, Partial<AssetsForm>>) => {
      const newAssets = values.assets;

      try {
        await attachToNft(newAssets);
        showMessage(`Data saved successfully!`, 'success');
        await mutate();
      } catch (error) {
        showMessage(`Failed to save data. ${formatError(error)}`, 'error');
      } finally {
        form.reset();
      }
    },
    [attachToNft, showMessage, mutate],
  );

  const initialValues = useMemo<{ assets: FormAsset[] }>(
    () => ({
      assets: assets.map((asset) => buildFormAsset(asset)),
    }),
    [assets],
  );

  return (
    <PageLayout title="Assets" isLoading={isLoading}>
      <Container sx={{ mb: 5 }} maxWidth="sm">
        <AssetsProcessingBanner collectionAddress={collectionAddress} nftId={Number(nftId)} trigger={mutate} />
        <Form initialValues={initialValues} onSubmit={submit} mutators={formMutators}>
          {({ submitting, handleSubmit, valid, pristine, form: { getState, mutators }, hasValidationErrors }) => (
            <SimpleForm
              onSubmit={handleSubmit}
              submitDisabled={pristine || hasValidationErrors || submitting}
              submitButtonTitle={saveButtonTitle}
              isValid={() => valid}
              actionInProgress={submitting}
              hideSubmit={!isOwner}
            >
              <>
                <FieldArray name="assets">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const currentAsset = getIn(getState().values, `${name}`) as FormAsset;
                      return (
                        <AssetItem
                          key={name}
                          assetDetails={currentAsset}
                          tokenAsset={{
                            index,
                            nftId,
                            collectionAddress,
                            contentType: currentAsset.contentType,
                            assetUrl: currentAsset.asset,
                          }}
                          index={index}
                          name={name}
                        />
                      );
                    })
                  }
                </FieldArray>
                {isOwner && (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 4,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      title="Add asset"
                      color="primary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        mutators.push('assets', buildFormAsset());
                      }}
                    >
                      Add asset
                    </Button>
                  </Box>
                )}
              </>
            </SimpleForm>
          )}
        </Form>
      </Container>
    </PageLayout>
  );
};
