import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { Form, FormSpy } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { FileInputFormField } from '~/components/common/form/file-input-form-field/file-input-form-field';
import { InputFormField } from '~/components/common/form/input-form-field';
import { FormGrid } from '~/components/common/form-grid';
import { FormSection } from '~/components/common/form-section';
import { WizardContext } from '~/components/common/wizard/wizard.context';
import { TextAreaFormField } from '~/components/form/fields/text-area';
import { ALLOWED_ATTACH_IMAGE_TYPES } from '~/constants/file';
import { GameUploadFormFourthStepPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-fourth-step/game-upload-form-fourth-step.types';

export function GameUploadFormFourthStep({ initialValues, onChange, onSubmit }: GameUploadFormFourthStepPropsType) {
  const { goToPrevStep } = useContext(WizardContext);

  const handleOnSubmit = useCallback(
    (values) => {
      void onSubmit(values);
    },
    [onSubmit],
  );
  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} />
          <FormGrid>
            <FormSection title="Add Preloader & welcome message">
              <div className="grid grid-cols-[2fr_1fr] gap-x-6">
                <Box
                  className="mb-8 p-3 rounded-lg border-1 border-white/10 w-full"
                  sx={{ backgroundColor: 'rgba(236, 240, 255, 0.2)' }}
                >
                  <div className="grid grid-cols-[20px_auto] gap-x-3 items-center">
                    <InfoOutlinedIcon />
                    <Typography className="font-medium text-xs">
                      This will be shown to the gamers before game starts
                    </Typography>
                  </div>
                </Box>
                <Box />
                <div className="flex flex-col">
                  <InputFormField name="messageTitle" placeholder="Example: Play & Win!" label="Title" />
                  <Box sx={{ my: 4, alignItems: 'center' }}>
                    <TextAreaFormField
                      name="messageDescription1"
                      placeholder="Example: Unlock NFT and token rewards, work your way to the top of the leaderboard and claim a bonus prize!"
                      label="Description 1"
                      rows={2}
                      showMaxSymbols
                      maxLength={250}
                    />
                  </Box>
                  <Box sx={{ my: -2, alignItems: 'center' }}>
                    <TextAreaFormField
                      name="messageDescription2"
                      placeholder="Example: Unlock NFT and token rewards, work your way to the top of the leaderboard and claim a bonus prize!"
                      label="Description 2"
                      rows={2}
                      showMaxSymbols
                      maxLength={250}
                    />
                  </Box>
                  <Box sx={{ my: 2, alignItems: 'center' }}>
                    <TextAreaFormField
                      name="messageDescription3"
                      placeholder="Example: Unlock NFT and token rewards, work your way to the top of the leaderboard and claim a bonus prize!"
                      label="Description 3"
                      rows={2}
                      showMaxSymbols
                      maxLength={250}
                    />
                  </Box>
                </div>
                <FileInputFormField name="preloaderImage" type="image" accept={ALLOWED_ATTACH_IMAGE_TYPES} />
              </div>
            </FormSection>
            <div className="flex justify-between">
              <Button className="min-w-[200px]" skin="outlined" icon={<KeyboardBackspaceIcon />} onClick={goToPrevStep}>
                Back
              </Button>
              <Button className="min-w-[266px]" skin="primary" type="submit">
                Done
              </Button>
            </div>
          </FormGrid>
        </form>
      )}
    </Form>
  );
}
