import { TOKEN_TITLE } from '~/lib/utils';

import { RoutesEnum } from './routes-enum';

type RouteItem = {
  url: string;
  title?: string;
  exact?: boolean;
};

export const tenantDetectPattern = '/:tenantId';

export const routes: { [key in RoutesEnum]: RouteItem } = {
  [RoutesEnum.ACCOUNT_COLLECTIONS]: {
    url: '/:tenantId/creator/account/:accountId/collections',
    title: 'Collections',
  },
  [RoutesEnum.ACCOUNT_MINTED]: {
    url: '/:tenantId/creator/account/:accountId/minted',
    title: 'Minted',
  },
  [RoutesEnum.ACCOUNT_OWNED]: {
    url: '/:tenantId/creator/account/:accountId/owned',
    title: 'Owned',
  },
  [RoutesEnum.ACCOUNT_BENEFITS]: {
    url: '/:tenantId/creator/account/:accountId/benefits',
    title: 'Benefits',
  },
  [RoutesEnum.ACCOUNT_JOINT_LIST]: {
    url: '/:tenantId/creator/account/:accountId/joint-list',
    title: 'Joint Accounts',
  },
  [RoutesEnum.NFT_ASSETS]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/assets',
    title: 'Assets',
  },
  [RoutesEnum.NFT_OFFERS]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/offers',
    title: 'Orders',
  },
  [RoutesEnum.NFT_AUCTION]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/auctions/:auctionId',
    title: 'Auction',
  },
  [RoutesEnum.NFT_AUCTIONS]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/auctions',
    title: 'Auctions',
  },
  [RoutesEnum.NFT_ROYALTIES]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/royalties',
    title: 'Royalties',
  },
  [RoutesEnum.NFT_TRANSFER]: {
    url: '/:tenantId/creator/nft/:collection/:nftId/transfer',
    title: 'Transfer',
  },

  [RoutesEnum.TRANSFER_CERE_TOKENS]: {
    url: '/:tenantId/creator/transfer-cere',
    title: `Transfer ${TOKEN_TITLE}`,
  },
  [RoutesEnum.CREATE_JOINT_ACCOUNT]: {
    url: '/:tenantId/creator/create-joint-account',
    title: 'Create JA',
  },
  [RoutesEnum.GAMES]: { url: '/:tenantId/games', title: 'Games portal' },
  [RoutesEnum.GAME_EDIT]: { url: '/:tenantId/games/:gameId', title: 'Edit your game' },
  [RoutesEnum.GAME_UPLOAD]: { url: '/:tenantId/games/upload', title: 'Upload your game' },
  [RoutesEnum.VIDEO]: { url: '/:tenantId/video', title: 'Video portal' },
  [RoutesEnum.VIDEO_LIBRARY]: { url: '/:tenantId/video/library', title: 'Video portal' },
  [RoutesEnum.VIDEO_EDIT]: { url: '/:tenantId/video/library/:videoId', title: 'Upload video' },
  [RoutesEnum.VIDEO_SUBSCRIPTIONS]: { url: '/:tenantId/video/subscriptions', title: 'Upload video' },
  [RoutesEnum.EDIT_VIDEO_SUBSCRIPTIONS]: {
    url: '/:tenantId/video/subscriptions/:subscriptionId',
    title: 'Upload video',
  },
  [RoutesEnum.VIDEO_COLLECTIONS]: { url: '/:tenantId/video/collections', title: 'Video collections' },
  [RoutesEnum.VIDEO_EDIT_COLLECTION]: { url: '/:tenantId/video/collections/:collectionId', title: 'Edit collection' },
  [RoutesEnum.MINT]: { url: '/:tenantId/creator/mint', title: 'Mint' },
  [RoutesEnum.LANDING]: { url: '/:tenantId/creator/', exact: true },
  [RoutesEnum.HOME]: { url: '/:tenantId', exact: true },

  [RoutesEnum.CONTENT_LIST]: {
    url: '/:tenantId/content/list',
    title: 'My Content',
  },

  [RoutesEnum.CONTENT_UPLOAD]: {
    url: '/:tenantId/content/upload',
    title: 'Upload Content',
  },
};
