import classnames from 'classnames';

export const operationButtonClass = classnames(
  'flex',
  'items-center',
  'bg-white/20',
  'rounded-full',
  'py-2',
  'transition-colors',
  'hover:bg-white/10',
);
