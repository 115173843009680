import { Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@mui/material';
import classnames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import useSWR from 'swr';

import { SectionHeader } from '~/components/common/section-header';
import { useUserPublicKey } from '~/context/app-context';
import { deferred } from '~/lib/deferred/deferred';
import { getSigner } from '~/services/get-signer';
import { generateWalletCredentials } from '~/services/wallet-auth/wallet-auth.service';

import CloseIcon from '../assets/close-icon.svg?jsx';
import UploadImageIcon from '../assets/upload-image-icon.svg?jsx';
import { Button } from '../components/button';
import { FormField } from '../components/form-field';
import { UploadField } from '../components/upload-field';
import formGroupStyles from '../form-group.module.css';
import { getCollection, getEdiCollectionUrl, saveCollection } from '../services/collections.service';
import { getDdcParams, getDdcParamsUrl } from '../services/ddc.service';
import { buildIFileUrl } from '../services/shared';
import collectionStyles from './collections.module.css';

type FormValues = {
  id: number | undefined;
  name: string;
  description: string;
  numberOfSeasons: number;
  onlySeason: boolean;
  cover: File | null;
  logo: File | null;
};

type Props = {
  id?: number;
  onClose?: () => unknown;
  open: boolean;
};

export function EditCollection({ onClose, open, id }: Props) {
  const url = id == null ? null : getEdiCollectionUrl(id);

  const { data } = useSWR(url, getCollection);
  const { data: ddcParams } = useSWR(getDdcParamsUrl(), getDdcParams, {
    revalidateOnFocus: false,
  });

  const imageUrl = buildIFileUrl(ddcParams?.cdnUrl, ddcParams?.publicBucketId, data?.metadata.coverCid);
  const logoUrl = buildIFileUrl(ddcParams?.cdnUrl, ddcParams?.publicBucketId, data?.metadata.logoCid);

  const isCreatePage = id == null;
  const [confirm, setConfirm] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const ref = useRef(deferred());
  const saveLabel = isCreatePage ? 'Create collection' : 'Edit collection';
  const [status, setStatus] = useState('');
  const address = useUserPublicKey();

  const saveData = useCallback(
    async (values: FormValues) => {
      setStatus('Preparing...');
      const signer = await getSigner();
      const { message, signature } = await generateWalletCredentials(signer);
      setStatus('Saving...');
      await saveCollection({
        ...values,
        address,
        name: values.name.trim(),
        message,
        signature,
      });
    },
    [address],
  );

  const submit = useCallback(
    (values: FormValues) =>
      saveData(values)
        .then(() => onClose?.())
        .catch(() => ({ [FORM_ERROR]: 'Saving failed. Please try again' })),
    [onClose, saveData],
  );

  const close = useCallback(() => {
    ref.current = deferred();
    setConfirm(true);
    return ref.current
      .then(() => {
        onClose?.();
      })
      .catch(() => null)
      .finally(() => {
        setConfirm(false);
      });
  }, [onClose]);

  const initialValues: FormValues = useMemo(
    () => ({
      id,
      name: data?.name ?? '',
      numberOfSeasons: data?.seasons ?? 1,
      description: data?.metadata.description ?? '',
      onlySeason: data?.seasons === 1,
      cover: null,
      logo: null,
    }),
    [data?.metadata.description, data?.name, data?.seasons, id],
  );

  return (
    <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: (theme: Theme) => theme.zIndex.appBar + 1 }} open={open}>
      <Form onSubmit={submit} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine, dirty, values, form }: FormRenderProps<FormValues>) => (
          <Dialog
            open={open}
            hideBackdrop
            classes={{ paper: collectionStyles.paper }}
            onClose={() => (dirty ? close() : onClose?.())}
            scroll="paper"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <SectionHeader variant="div" title={isCreatePage ? 'Create collection' : 'Edit collection'} />
            </DialogTitle>
            <DialogContent>
              <form id="collection-editor" ref={formRef} onSubmit={handleSubmit}>
                <button
                  aria-label="close"
                  type="button"
                  onClick={() => (dirty ? close() : onClose?.())}
                  className={classnames(
                    'absolute',
                    'transition-colors',
                    'w-8',
                    'h-8',
                    'flex',
                    'justify-center',
                    'items-center',
                    'rounded-full',
                    'bg-white/20',
                    'hover:bg-white/10',
                    'top-6',
                    'right-6',
                    'text-white/60',
                    'hover:text-white/90',
                  )}
                >
                  <CloseIcon />
                </button>
                <div className={formGroupStyles.group}>
                  <div className="flex flex-col gap-y-6">
                    <FormField label="Name" required placeholder="Collection name" name="name" />
                    <FormField
                      label="Description"
                      type="textarea"
                      rows={6}
                      required
                      placeholder="Collection description"
                      name="description"
                    />
                    <div className="grid grid-cols-[1fr_max-content] items-end gap-x-6">
                      <FormField
                        disabled={values.onlySeason}
                        min={1}
                        max={100}
                        type="number"
                        required
                        name="numberOfSeasons"
                        label="A number of seasons"
                      />
                      <Field name="onlySeason" type="checkbox">
                        {({ input: { name, checked, onChange, onFocus, onBlur } }) => (
                          <label className="flex text-lg items-center gap-x-2 relative top-[-10px]">
                            <input
                              name={name}
                              checked={checked}
                              type="checkbox"
                              onChange={onChange}
                              onBlur={onBlur}
                              onFocus={onFocus}
                            />
                            No seasons
                          </label>
                        )}
                      </Field>
                      <OnChange name="onlySeason">
                        {(value: boolean) => {
                          if (value) {
                            form.change('numberOfSeasons', 1);
                          }
                        }}
                      </OnChange>
                    </div>
                    <div className="grid grid-cols-2 gap-x-8">
                      <Field name="logo">
                        {({ input }) => (
                          <UploadField
                            preview={logoUrl}
                            types={['image/png', 'image/svg+xml']}
                            onChange={input.onChange}
                            type="image"
                          >
                            <div className="flex flex-col items-center select-none">
                              <UploadImageIcon />
                              <h4 className="text-lg mt-2 text-white">Add logo movie image</h4>
                              <p className="text-sm text-center px-6 text-white/50">
                                Drag and drop an image, or <span className="text-pink-500">Browse</span>
                                <br />
                                1024x768 or higher (png, svg) Max 10mb
                              </p>
                            </div>
                          </UploadField>
                        )}
                      </Field>
                      <Field name="cover">
                        {({ input }) => (
                          <UploadField preview={imageUrl} onChange={input.onChange} type="image">
                            <div className="flex flex-col items-center select-none">
                              <UploadImageIcon />
                              <h4 className="text-lg mt-2 text-white">Add background image</h4>
                              <p className="text-sm text-center px-6 text-white/50">
                                Drag and drop an image, or <span className="text-pink-500">Browse</span>
                                <br />
                                1024x768 or higher (png, jpg, gif) Max 10mb
                              </p>
                            </div>
                          </UploadField>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <div className={classnames('mb-4', 'mt-6', 'w-full', 'flex', 'justify-start', 'pl-[18px]')}>
                <Button
                  form="collection-editor"
                  onClick={() => formRef.current?.reportValidity()}
                  submit
                  disabled={submitting || pristine}
                >
                  {submitting ? status : saveLabel}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        )}
      </Form>
      <Dialog classes={{ paper: collectionStyles.paper }} open={confirm}>
        <DialogTitle>
          <span className="text-2xl">Some changes may lost</span>
        </DialogTitle>
        <DialogContent>
          <span className="text-lg text-white/70">
            There are unsaved changes in the form. Press <b className="text-white">Close</b> to close form without
            saving. Press <b className="text-white">Cancel</b> to continue editing.
          </span>
        </DialogContent>
        <DialogActions>
          <div className="text-lg flex justify-end items-center gap-x-6 px-6 py-2">
            <button onClick={() => ref.current.reject()} type="button">
              Cancel
            </button>
            <button onClick={() => ref.current.resolve(null)} type="button">
              Close
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
}
