import { NETWORK_ID } from '~/constants/env';
import { MetamaskConnector } from '~/model/wallet/metamask-connector';
import { WalletConnector } from '~/model/wallet/types';
import { WalletConnectConnector } from '~/model/wallet/wallet-connect-connector';

const LAST_CONNECTOR_KEY = 'last_connector_key';

export class LastConnectorStorage {
  setLastUsedConnector(connector: WalletConnector): void {
    const className = connector.constructor.name;
    window.localStorage.setItem(LAST_CONNECTOR_KEY, className);
  }

  getLastUsedConnector(): WalletConnector | null {
    const lastConnectorClassName = window.localStorage.getItem(LAST_CONNECTOR_KEY);
    switch (lastConnectorClassName) {
      case MetamaskConnector.name:
        return new MetamaskConnector(Number(NETWORK_ID));
      case WalletConnectConnector.name:
        return new WalletConnectConnector();
      default:
        return null;
    }
  }
}
