import arrowBackOutline from '@iconify/icons-eva/arrow-back-outline';
import { Icon } from '@iconify/react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { Form } from 'react-final-form';

import { NumberInput } from '~/components/form/fields';
import { DateTimePicker } from '~/components/form/fields/date-time-picker';
import { SimpleForm } from '~/components/form/simple-form';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { TOKEN_TITLE, tokensToUnits } from '~/lib/utils';
import { createAuction } from '~/services/create-auction';

type FormValues = {
  minPrice: number;
  closeTimeSec: Date;
};

type Props = {
  onBack: () => void;
  onSuccessCreate?: () => void;
};

export const CreateAuction = ({ onBack, onSuccessCreate }: Props) => {
  const { showMessage } = useMessages();
  const { nft } = useContext(ItemLayoutContext);
  const {
    nftId,
    collection: { address: collectionAddress },
  } = nft;

  const submit = useCallback(
    async (values: FormValues) => {
      const { minPrice, closeTimeSec } = values;
      const duration = Math.floor((closeTimeSec.getTime() - Date.now()) / 1000);
      try {
        const minPriceUnits = tokensToUnits(Number(minPrice)) ?? 0;
        const [hash] = await createAuction({
          collectionAddress,
          nftId,
          minBid: minPriceUnits,
          amount: 1,
          duration,
        });
        if (hash) {
          showMessage(`Transaction submitted successfully with hash: ${hash}!`, 'success');
        }
        if (onSuccessCreate) {
          onSuccessCreate();
        }
      } catch (error) {
        showMessage(`Failed to start auction. ${formatError(error)}`, 'error');
      }
    },
    [collectionAddress, nftId, showMessage, onSuccessCreate],
  );

  const dateInOneWeek = useMemo(() => new Date(Date.now() + 7 * 24 * 3600 * 1000), []);

  return (
    <PageLayout title="Start auction">
      <Container sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mx: 2 }}>
          <Button variant="outlined" startIcon={<Icon icon={arrowBackOutline} />} onClick={onBack}>
            Back
          </Button>
        </Box>

        <Grid container columns={12}>
          <Grid item lg={6} md={8} sx={{ mx: 'auto' }}>
            <Typography sx={{ m: 2 }}>
              Offer to sell one NFT to the highest bidder. A potential buyer accepts the minimum price. Other buyers
              make bids with a price at least 10% higher than that of the previous bidder.
            </Typography>{' '}
            <Typography sx={{ m: 2 }}>
              A deposit is taken from the new bidder. The deposit of the previous bidder is returned, if any. The
              closing time is extended after a new bid. Bidding is no longer possible after the closing time.
            </Typography>
            <Typography sx={{ m: 2, mb: 5 }}>
              The sale is completed between the seller and the highest bidder, or cancelled if there was no bidder. The
              settlement is only possible after the closing time.
            </Typography>
            <Form initialValues={{ minPrice: 0, closeTimeSec: dateInOneWeek }} onSubmit={submit}>
              {({ handleSubmit, valid, submitting, pristine }) => (
                <SimpleForm
                  submitDisabled={pristine}
                  onSubmit={handleSubmit}
                  submitButtonTitle="Start auction"
                  isValid={() => valid}
                  actionInProgress={submitting}
                >
                  <FlexBox sx={{ mb: 3 }}>
                    <NumberInput
                      label={`Starting price (in ${TOKEN_TITLE})`}
                      name="minPrice"
                      min={1}
                      inputMode="numeric"
                      required
                    />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 320 }}>
                        The starting price is the minimum amount to be paid by buyer in {TOKEN_TITLE}
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                  <FlexBox sx={{ mb: 3 }}>
                    <DateTimePicker name="closeTimeSec" label="Close time" required />
                    <HelpPopover>
                      <Typography sx={{ p: 2, maxWidth: 320 }}>
                        End date and time of the auction after which bidding is no longer possible
                      </Typography>
                    </HelpPopover>
                  </FlexBox>
                </SimpleForm>
              )}
            </Form>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  );
};
