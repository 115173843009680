import { BigNumberish } from 'ethers';

import { getHumanTokenValue } from '~/lib/get-human-token-value';

type Props = {
  value: BigNumberish | undefined | null;
  decimals: BigNumberish;
};

export function TokenValue({ value, decimals }: Props) {
  if (value == null) {
    return null;
  }
  return <span title={getHumanTokenValue(value, decimals)}>{getHumanTokenValue(value, decimals)}</span>;
}
