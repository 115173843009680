import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import classnames from 'classnames';
import { ReactNode, useCallback } from 'react';

import styles from '../select.module.css';

type Props = {
  children: ReactNode;
  label?: string;
  placeholder?: string;
  name: string;
  onChange: (val: string) => unknown;
  value: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  renderValue?: (selected: string) => ReactNode;
};

export function FormSelect({
  label,
  name,
  value,
  onChange,
  children,
  required = false,
  multiple = false,
  disabled = false,
  renderValue,
  placeholder,
}: Props) {
  const change = useCallback(
    (e: SelectChangeEvent<string>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={classnames({ 'flex flex-col gap-y-2': label != null })}>
      {label && (
        <label id={name}>
          {label}
          {required ? ' *' : null}
        </label>
      )}
      <FormControl classes={{ root: 'h-[44px]' }} size="small">
        <Select
          sx={{ height: 44 }}
          placeholder={placeholder}
          disabled={disabled}
          multiple={multiple}
          labelId={name}
          renderValue={multiple ? renderValue : undefined}
          variant="outlined"
          onChange={change}
          classes={{ outlined: styles.outlined, select: styles['select-small'] }}
          value={value}
        >
          {children}
        </Select>
      </FormControl>
    </div>
  );
}
