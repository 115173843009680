import { ItemLayout } from '~/components/layout/item-layout';

import { AccountNavigation } from './account-navigation';
import { Collections } from './components/collections/collections';

export const AccountCollections = () => (
  <ItemLayout navigation={AccountNavigation}>
    <Collections />
  </ItemLayout>
);
