import { BigNumberish } from 'ethers';

import { isRecord } from '~/types/is-record';

import { isBoolean } from './is-boolean';
import { isBigNumberish } from './numbers';

export type Order = {
  orderId: BigNumberish;
  amount: BigNumberish;
  price: BigNumberish;
  cancelled: boolean;
  processed: boolean;
};

export const isOrder = (val: unknown): val is Order =>
  isRecord(val) &&
  isBigNumberish(val.orderId) &&
  isBigNumberish(val.amount) &&
  isBigNumberish(val.price) &&
  isBoolean(val.cancelled) &&
  isBoolean(val.processed);
