import { forwardRef } from 'react';

import { MaskedInput } from '~/components/common/form/masked-input';
import { MaskedInputFieldPropsType } from '~/components/common/form/masked-input-field/masked-input-field.types';
import { field } from '~/components/hoc';

export const MaskedInputField = forwardRef<HTMLInputElement, MaskedInputFieldPropsType>((props, ref) =>
  field<MaskedInputFieldPropsType>({
    ...props,
    ref,
  })(MaskedInput),
);
