import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';

import ERC20Diagram from '~/assets/erc20-diagram.jpg';
import { Erc20Transfer } from '~/components/form/erc-transfer';
import { TokenDevMint } from '~/components/form/token-dev-mint';
import { TokenWithdraw } from '~/components/form/token-withdraw';
import { PageLayout } from '~/components/layout/page-layout';
import { TOKEN_TITLE } from '~/lib/utils';
import { isTestERC20 } from '~/services/get-erc20';

export const TransferCere = () => (
  <PageLayout title={`${TOKEN_TITLE} Deposit`}>
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 1, mb: 5 }}>
        <Grid container columns={8}>
          <Grid item lg={6} md={8} sx={{ mx: 'auto' }}>
            <Box component="img" sx={{ maxHeight: 160 }} alt="ERC-20 Diagram" src={ERC20Diagram} />
          </Grid>
        </Grid>
      </Box>
    </Container>
    <Container maxWidth="sm" sx={{ mb: 5 }}>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <Card>
          <CardContent>
            <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
              Withdraw Pending {TOKEN_TITLE}
            </Typography>
            <TokenWithdraw />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
              Transfer {TOKEN_TITLE}
            </Typography>
            <Erc20Transfer />
          </CardContent>
        </Card>

        {isTestERC20 && (
          <Card>
            <CardContent>
              <Typography paragraph sx={{ px: 1, mt: 2, mb: 5 }}>
                Mint TEST-{TOKEN_TITLE} tokens in a development environment.
              </Typography>
              <TokenDevMint />
            </CardContent>
          </Card>
        )}
      </Stack>
    </Container>
  </PageLayout>
);
