/* eslint-disable no-void */
import arrowBackOutline from '@iconify/icons-eva/arrow-back-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isPast } from 'date-fns';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { HelpPopover } from '~/components/icons/help-popover';
import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { Empty } from '~/components/layout/empty';
import { Identicon } from '~/components/layout/identicon';
import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { timestampToDate } from '~/lib/date';
import { formatError } from '~/lib/formatters';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import { shortenHexAddress, TOKEN_TITLE, tokensToUnits, unitsToTokens } from '~/lib/utils';
import { getAuction, getAuctionBids } from '~/services/auctions.service';
import { createAuctionBid } from '~/services/create-auction-bid';
import { getSigner } from '~/services/get-signer';
import { resolveAuction } from '~/services/resolve-auction';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Auction, AuctionBid } from '~/types/auction';

export const AuctionItem = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isBidsLoading, setBidsLoading] = useState<boolean>(false);
  const [makingBid, setMakingBid] = useState<boolean>(false);
  const [settlingAuction, setSettlingAuction] = useState<boolean>(false);
  const [newBidPrice, setNewBidPrice] = useState<string>('');
  const [auction, setAuction] = useState<Auction | null>(null);
  const [auctionBids, setAuctionBids] = useState<AuctionBid[]>([]);
  const { nft } = useContext(ItemLayoutContext);
  const {
    nftId,
    collection: { address: collectionAddress },
    minter: { wallet: minterWallet },
  } = nft;
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();
  const { auctionId } = useParams<{ auctionId: string }>();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const fetchAuction = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getAuction({ auctionId });
      setAuction(result);
    } catch (error) {
      showMessage(`Failed to fetch auction. ${formatError(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [auctionId, showMessage]);

  const fetchAuctionBids = useCallback(async () => {
    try {
      setBidsLoading(true);
      const result = await getAuctionBids({ auctionId });
      setAuctionBids(result);
    } catch (error) {
      showMessage(`Failed to fetch auction bids. ${formatError(error)}`, 'error');
    } finally {
      setBidsLoading(false);
    }
  }, [auctionId, showMessage]);

  const makeBid = async () => {
    try {
      setMakingBid(true);
      const signer = await getSigner();
      const newBidPriceUnits = tokensToUnits(Number(newBidPrice)) ?? 0;
      const hash = await createAuctionBid(signer, auctionId, newBidPriceUnits);
      showMessage(`Transaction submitted successfully with hash: ${hash}!`, 'success');
    } catch (error) {
      showMessage(`Bid on auction failed. ${formatError(error)}`, 'error');
    } finally {
      setMakingBid(false);
    }
  };

  const settleAuction = async () => {
    try {
      setSettlingAuction(true);
      const hash = await resolveAuction(nftId);
      showMessage(`Transaction submitted successfully with hash: ${hash}!`, 'success');
    } catch (error) {
      showMessage(`Failed to settle auction. ${formatError(error)}`, 'error');
    } finally {
      setSettlingAuction(false);
    }
  };

  useEffect(() => {
    void fetchAuction();
    void fetchAuctionBids();
  }, [fetchAuction, fetchAuctionBids]);

  const isCreator = useMemo(() => minterWallet.toLowerCase() === userPubKey.toLowerCase(), [minterWallet, userPubKey]);

  const auctionStatus: 'OPEN' | 'CLOSED' | 'SETTLED' | undefined = useMemo(() => {
    if (!auction) {
      return undefined;
    }
    if (!auction.resolved && isPast(auction.endDate)) {
      return 'CLOSED';
    }
    if (auction.resolved && isPast(auction.endDate)) {
      return 'SETTLED';
    }
    return 'OPEN';
  }, [auction]);

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mx: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4">Auction #{auctionId}</Typography>
          </Box>

          <Box sx={{ flexShrink: 0 }}>
            <NavLink
              to={putUrlParam(
                routes[RoutesEnum.NFT_AUCTIONS].url,
                {
                  collection: collectionAddress,
                  nftId: nftId.toString(),
                },
                activeTenant,
              )}
            >
              <Button variant="outlined" startIcon={<Icon icon={arrowBackOutline} />}>
                Back to list
              </Button>
            </NavLink>
          </Box>
        </Box>
        {auction ? (
          <Container>
            <List>
              <ListItem>Status: {auctionStatus}</ListItem>
              <ListItem>Amount: {auction.amount}</ListItem>
              <ListItem>Highest Bid: {auction.highestBid}</ListItem>
              <ListItem>Resolved: {auction.resolved ? 'yes' : 'no'}</ListItem>
              <ListItem>Ends at: {timestampToDate(auction.endDate)}</ListItem>
            </List>

            <Divider />
            <Stack direction="row" alignItems="center" sx={{ m: 2 }}>
              <TextField
                label={`Bid price (${TOKEN_TITLE})`}
                variant="filled"
                size="small"
                value={Number(newBidPrice)}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  setNewBidPrice(event.target.value);
                }}
              />
              <LoadingButton
                loading={makingBid}
                variant="contained"
                disabled={auction.resolved || isCreator}
                onClick={makeBid}
                sx={{ mx: 1, minWidth: 100 }}
              >
                Bid
              </LoadingButton>
              <HelpPopover>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  As a potential buyer, accept the minimum price or a price at least 10% higher than that of the
                  previous bidder. The closing time may be extended. A deposit is taken from the new bidder. The deposit
                  of the previous bidder is returned, if any. Bidding is no longer possible after the closing time.
                </Typography>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  Users will probably prefer to bid on this auction from other user-friendly apps powered by Freeport.
                  This will have the same effect as this button.
                </Typography>
              </HelpPopover>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" sx={{ m: 2 }}>
              <LoadingButton
                loading={settlingAuction}
                variant="contained"
                size="large"
                disabled={!isCreator && auctionStatus !== 'CLOSED'}
                onClick={settleAuction}
              >
                Settle auction
              </LoadingButton>
              <HelpPopover sx={{ ml: 1 }}>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  Complete the sale between the seller and the highest bidder, or cancel it if there was no bidder. Some
                  royalties may be taken from the sale price, as configured by the NFT creator (see Royalties page). The
                  settlement is only possible after the closing time.
                </Typography>
              </HelpPopover>
            </Stack>
            {isBidsLoading ? (
              <LinearProgress />
            ) : (
              <>
                <Divider />
                <Typography variant="h5" mx={2} mt={2}>
                  Bids
                </Typography>
                <List>
                  {auctionBids.length > 0 ? (
                    auctionBids.map(({ buyer: maker, priceInCereUnits: price, timestamp }, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ListItem key={`${maker}-${index}`}>
                        <Card>
                          <List sx={{ p: 0 }}>
                            <ListItem>
                              <Avatar
                                alt={maker}
                                sx={{
                                  bgcolor: 'white',
                                  width: 48,
                                  height: 48,
                                  mr: 1,
                                }}
                              >
                                <Identicon value={maker} />
                              </Avatar>
                              <Typography variant="subtitle2" noWrap>
                                {shortenHexAddress(maker)} <CopyToClipboard value={maker} />
                              </Typography>
                            </ListItem>
                            <ListItem>
                              Bid price:{' '}
                              <Chip
                                label={<Typography sx={{ fontWeight: 500 }}>{String(unitsToTokens(price))}</Typography>}
                                sx={{ mx: 1 }}
                              />
                              {TOKEN_TITLE}
                            </ListItem>
                            <ListItem>Made at: {timestamp}</ListItem>
                          </List>
                        </Card>
                      </ListItem>
                    ))
                  ) : (
                    <List>
                      <ListItem>This auction has no bids yet</ListItem>
                    </List>
                  )}
                </List>
              </>
            )}
          </Container>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
