import { Field, useField } from 'react-final-form';

import { FileInputField } from '~/components/common/form/file-input-field/file-input-field';
import { FileInputFormFieldPropsType } from '~/components/common/form/file-input-form-field/file-input-form-field.types';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { ImageType } from '~/routes/gaming/gaming.types';
import { getFieldError } from '~/utils/form';

export function FileInputFormField({ name, required, onChangeField, ...props }: FileInputFormFieldPropsType) {
  const fieldValidator = useTextInputValidator<File | undefined>({
    required,
  });
  const {
    input,
    meta: { error, touched },
  } = useField<File | undefined>(name, { validate: fieldValidator });
  const { value, onChange, onBlur } = input;

  const handleOnChange = (val: any) => {
    onChange(val);
    if (onChangeField) {
      onChangeField?.({ guid: (value as unknown as ImageType).guid, file: val });
    }
  };

  return (
    <Field name={name}>
      {(iProps) => (
        <FileInputField
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iProps}
          error={getFieldError({ error, touched: touched as boolean })}
        />
      )}
    </Field>
  );
}
