import { get } from '~/lib/request';
import { getFreeportChainHeaders } from '~/services/get-freeport-chain-headers.service';
import { Auction, AuctionBid } from '~/types/auction';

type ListOptions = {
  nftId: string;
  collectionAddress: string;
  onlyActive?: boolean;
};

const getJson = async (response: Response): Promise<unknown> => response.json();

export const getAuctions = async (options: ListOptions): Promise<Auction[]> => {
  const { collectionAddress, nftId } = options;
  const auctions = await get(`/api/auctions/${collectionAddress}/${nftId}`, getFreeportChainHeaders()).then(getJson);

  // TODO: Check backend schema
  return auctions as Auction[];
};

type AuctionOptions = { auctionId: string };

export const getAuction = async ({ auctionId }: AuctionOptions): Promise<Auction> => {
  const auction = await get(`/api/auctions/${auctionId}`).then(getJson);

  // TODO: Check backend schema
  return auction as Auction;
};

type AuctionBidsOptions = {
  auctionId: string;
  pageIndex?: number;
  pageSize?: number;
};

export const getAuctionBids = async ({ auctionId }: AuctionBidsOptions): Promise<AuctionBid[]> => {
  const bids = await get(`/api/auctions/auction/${String(auctionId)}/bids`).then(getJson);

  // TODO: Check backend schema
  return bids as AuctionBid[];
};
