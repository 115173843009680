import { IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { bytesToSize } from '~/components/form/fields/file-uploader/bytes-to-size';
import { CloseIcon } from '~/components/form/fields/file-uploader/close-icon';
import { LoadingProgress } from '~/components/loader/loading-progress';
import { MediaFileThumbnail } from '~/components/media-file-thumbnail';

interface FilePreviewTileProps {
  file: File;
  uploadProgress?: number;
  onRemove?: (file: File) => unknown;
}

const useStyles = makeStyles(() => ({
  primary: {
    '& a': {
      color: 'currentColor',
      textDecoration: 'underline',
    },
  },
}));

export const FilePreviewTile = ({ file, uploadProgress = 0, onRemove }: FilePreviewTileProps) => {
  const styles = useStyles();

  return (
    <ListItem
      key={file.name}
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        '& + &': {
          mt: 1,
        },
      }}
    >
      <ListItemAvatar sx={{ mr: 1 }}>
        <MediaFileThumbnail file={file} />
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: styles.primary }}
        primary={file.name}
        primaryTypographyProps={{
          color: 'textPrimary',
          variant: 'subtitle2',
        }}
        secondary={bytesToSize(file.size)}
      />
      {uploadProgress > 0 && <LoadingProgress value={uploadProgress} />}
      <Tooltip title="Remove">
        <IconButton
          edge="end"
          onClick={() => {
            onRemove?.(file);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};
