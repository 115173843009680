import {
  ApplicationEnum,
  createERC20MockToken,
  createMarketplace,
  getERC20Address,
  getMarketplaceAddress,
} from '@cere/freeport-sc-sdk';
import { BigNumberish, Signer } from 'ethers';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { calculateBidInfo, getAuctionInfo } from '~/services/get-auction';
import { getSigner } from '~/services/get-signer';

export const createAuctionBid = async (bidder: Signer, auctionId: BigNumberish, bid: BigNumberish) => {
  const auctionInfo = await getAuctionInfo(auctionId);
  const { provider } = await getSigner();

  const erc20Address = await getERC20Address(provider, CONTRACTS_DEPLOYMENT, APPLICATION() as ApplicationEnum);
  const erc20 = createERC20MockToken({ signer: bidder, contractAddress: erc20Address });

  const marketplaceAddress = await getMarketplaceAddress(
    provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );
  const marketplace = createMarketplace({ signer: bidder, contractAddress: marketplaceAddress });

  const bidInfo = await calculateBidInfo(auctionId, bid, auctionInfo.collectionAddress);

  let tx = await erc20.increaseAllowance(marketplaceAddress, bidInfo.price);
  await tx.wait();

  tx = await erc20.increaseAllowance(auctionInfo.collectionAddress, bidInfo.royaltyFee);
  await tx.wait();

  tx = await marketplace.bid(auctionId, bid);
  await tx.wait();
  return tx.hash;
};
