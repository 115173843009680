import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import classnames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { FileInputFormField } from '~/components/common/form/file-input-form-field/file-input-form-field';
import { InputFormField } from '~/components/common/form/input-form-field';
import { FormGrid } from '~/components/common/form-grid';
import { FormSection } from '~/components/common/form-section';
import { WizardContext } from '~/components/common/wizard/wizard.context';
import { Condition, ConditionsList } from '~/components/conditions';
import { GameSkeleton } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-skeleton';
import { FolderStructure } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-first-step/folder-structure';
import { GameUploadFormFirsStepPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-first-step/game-upload-form-first-step.types';

export function GameUploadFormFirstStep({
  initialValues,
  onChange,
  onSubmit,
  isGameUploading,
  isGameUploaded,
  isEdit = false,
  isLoading,
}: GameUploadFormFirsStepPropsType) {
  const { goToNextStep } = useContext(WizardContext);

  const accordionClassname = classnames(
    'mb-3',
    'py-2',
    'rounded-lg',
    'border-1',
    'border-white/10',
    'bg-[rgba(236, 240, 255, 0.2)]',
    'w-full',
  );

  const buttonText = useMemo(() => {
    if (!isEdit) {
      return isGameUploaded ? 'Game uploaded' : 'Upload game';
    }
    return isGameUploaded ? 'Game saved' : 'Save';
  }, [isEdit, isGameUploaded]);

  return (
    <ConditionsList>
      <Condition condition={isEdit && isLoading}>
        <GameSkeleton />
      </Condition>
      <Condition condition={!isLoading}>
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, invalid, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FormSpy onChange={onChange} />
              <FormGrid>
                <FormSection>
                  <InputFormField required name="gameTitle" placeholder="Game Title" label="Game Title" />
                  <InputFormField required name="gameCode" placeholder="Game Code" label="Game Code" />
                  <Box sx={{ alignItems: 'center' }}>
                    <Box sx={{ display: 'inline-block', mb: 1 }}>Game files *</Box>
                    <Accordion
                      className={accordionClassname}
                      disableGutters
                      sx={{
                        '.MuiAccordionDetails-root': {
                          padding: '5px 16px 16px',
                        },
                        '.MuiCollapse-root': {
                          minHeight: '80px !important',
                          visibility: 'visible',
                        },
                        '&::before': {
                          backgroundColor: 'transparent',
                        },
                        '.Mui-expanded': {
                          margin: 0,
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="grid grid-cols-[auto_auto] gap-x-3">
                          <InfoOutlinedIcon />
                          <Typography className="font-medium">
                            FAQ: How to prepare your game files to upload?
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="grid grid-cols-[1fr_2fr] gap-x-[75px]">
                          <div>
                            <div className="text-white text-sm font-bold ml-[35px]">
                              Required folder structure for zip archive will be as follows:
                            </div>
                            <FolderStructure />
                          </div>
                          <div>
                            <div className="text-white text-sm font-bold mb-[16px]">Required parameters are:</div>
                            <ol className="list-disc">
                              <li className="ml-[15px]">
                                <div className="text-white text-sm font-bold">GAME_NAME - Must be unique</div>
                              </li>
                              <li className="ml-[15px]">
                                <div className="text-white text-sm font-bold">
                                  index.html - root file for all dependencies.
                                </div>
                              </li>
                              <li className="ml-[15px]">
                                <div className="text-white text-sm font-bold">
                                  config/metadata.json - will be optional for now, but in future configs will be stored
                                  here.
                                </div>
                              </li>
                            </ol>
                            <div className="text-white text-sm font-bold">
                              All the rest is up to game developer. Resources location should be defined relatively to
                              index.html not absolutely defined.
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <FileInputFormField name="gameFiles" type="zipFile" required={!isEdit} />
                  </Box>
                  <Box sx={{ alignItems: 'center' }}>
                    <Button
                      skin="primary"
                      type="submit"
                      icon={isGameUploaded ? <CheckCircleIcon /> : null}
                      loading={isGameUploading}
                      fluid
                      disabled={invalid || submitting || isGameUploaded}
                      className="!rounded-xl !shadow-none"
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </FormSection>
                {(isGameUploaded || isEdit) && (
                  <div className="flex justify-end">
                    <Button
                      className="min-w-[266px]"
                      skin="primary"
                      postIcon={<ArrowRightAltIcon />}
                      onClick={goToNextStep}
                    >
                      Add rewards
                    </Button>
                  </div>
                )}
              </FormGrid>
            </form>
          )}
        </Form>
      </Condition>
    </ConditionsList>
  );
}
