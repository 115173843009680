import EditSharp from '@mui/icons-material/EditSharp';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Popover } from '@mui/material';
import classnames from 'classnames';
import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '~/components/common/buttons/button';
import { Status } from '~/components/common/status';
import { useOpen } from '~/components/layout/use-open';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useGamesListQuery } from '~/hooks';
import { useDeleteGameMutation } from '~/hooks/games/useDeleteGameMutation';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { GameItemPropsType } from '~/routes/gaming/gaming-home-route/components/game-item/game-item.types';
import { getSigner } from '~/services/get-signer';
import { StatusTheme } from '~/types/status';

import styles from './game-item.module.css';

const STATUS_TYPE: Record<keyof typeof StatusTheme, string> = {
  PENDING: 'Pending',
  PUBLISHED: 'Published',
  REJECTED: 'Rejected',
};

const popoverItems = [
  {
    id: 0,
    item: 'Set up game',
    state: {
      step: 0,
    },
  },
  {
    id: 1,
    item: 'First time rewarding',
    state: {
      step: 1,
    },
  },
  {
    id: 2,
    item: 'Setup tournament leaderboards',
    state: {
      step: 2,
    },
  },
  {
    id: 3,
    item: 'Welcome banner',
    state: {
      step: 3,
    },
  },
  { id: 4, item: 'Delete game', deleteEnable: true },
];

export function GameItem({ game }: GameItemPropsType) {
  const { isOpen, open, close } = useOpen(false);
  const { id, title, status, path } = game;
  const { putActiveTenantUrlParam } = useUrlParams();
  const address = useUserPublicKey();
  const { mutateGamesList } = useGamesListQuery();
  const { deleteGame } = useDeleteGameMutation(mutateGamesList);

  const anchorRef = useRef(null);

  const editGameUrl = putActiveTenantUrlParam(routes[RoutesEnum.GAME_EDIT].url, { gameId: id });

  const handleOnClick = useCallback(async () => {
    if (!path) {
      return;
    }
    window.open(path, '_blank')?.focus();
  }, [path]);

  return (
    <div className={classnames(styles.item, 'flex', 'items-center', 'justify-between', 'min-h-[88px]')}>
      <div className={classnames('grid', 'grid-cols-[auto_auto]', 'gap-x-5', 'items-center')}>
        <h3 className="text-xl text-white/80' font-semibold">{title}</h3>
      </div>
      <div className={classnames('flex', 'gap-8', 'items-center')}>
        <p>{status && <Status theme={StatusTheme[status]} label={STATUS_TYPE[status]} />}</p>
        <div className={classnames('grid', 'grid-cols-[auto_auto_auto]', 'gap-4')}>
          <Button
            skin="secondary"
            icon={<InsertLinkIcon />}
            onClick={async () => {
              if (!path) {
                return;
              }
              if (!navigator.clipboard) {
                document.execCommand('copy');
              }
              await navigator.clipboard.writeText(path);
            }}
          />
          <Button
            ref={anchorRef}
            skin="secondary"
            icon={<EditSharp />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              open();
            }}
          />
          <Button skin="outlined" onClick={handleOnClick} type="button">
            Launch game
          </Button>
        </div>
      </div>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: '8px',
            borderRadius: '12px',
            overflow: 'inherit',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            background: '#191E31',
            padding: '10px',
            width: 300,
          },
        }}
      >
        {popoverItems.map(({ id: itemId, item, state, deleteEnable }) => (
          <Link
            key={itemId}
            to={{ pathname: editGameUrl, state }}
            className={classnames(styles['popover-item'])}
            onClick={async (event) => {
              if (deleteEnable) {
                event.stopPropagation();
                event.preventDefault();
                const signer = await getSigner();
                const signature = await signer.signMessage(game.title);
                await deleteGame({
                  gameId: game.id,
                  signature,
                  address,
                  title: game.title,
                });
              }
            }}
          >
            {item}
          </Link>
        ))}
      </Popover>
    </div>
  );
}
