import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnyObject } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { TOURNAMENT_DATE_FORMAT } from '~/constants/date';
import { useUserPublicKey } from '~/context/app-context';
import { useTournamentsListQuery } from '~/hooks/tournaments';
import { useTournamentCreateMutation } from '~/hooks/tournaments/useTournamentCreateMutation';
import { useTournamentEditMutation } from '~/hooks/tournaments/useTournamentEditMutation';
import { useTournamentQuery } from '~/hooks/tournaments/useTournamentQuery';
import { TournamentsList } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components';
import { TournamentCreateUpdateForm } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournament-create-update-form';
import { FormValuesType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournament-create-update-form/tournament-create-update-form.types';
import { GameUploadThirdStepPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/game-upload-third-step.types';
import { ImageType, Tournament } from '~/routes/gaming/gaming.types';
import { getSigner } from '~/services/get-signer';

export function GameUploadThirdStep({ tournaments, game }: GameUploadThirdStepPropsType) {
  const [tournamentId, setTournamentId] = useState<number | undefined>();
  const [leaderboardView, setLeaderboardView] = useState<'single_image' | 'multiple_images'>('single_image');
  const { data, isLoading } = useTournamentQuery({ id: tournamentId });
  const address = useUserPublicKey();

  const preparedImages = useMemo(
    () =>
      data?.images
        ?.filter((i) => i.mainImage !== true)
        ?.reduce(
          (prev, next, idx) => ({ ...prev, ...{ [`image${idx + 1}`]: next } }),
          {} as {
            [key: string]: ImageType | File;
          }[],
        ),
    [data?.images],
  );

  const mainImage = useMemo(() => data?.images?.find((s) => s.mainImage === true), [data?.images]);

  const initialData: FormValuesType = {
    tournamentTitle: data?.title || '',
    tournamentSubtitle: data?.subtitle || '',
    tournamentStartDate: data?.startDate ? format(new Date(data.startDate), TOURNAMENT_DATE_FORMAT) : undefined,
    tournamentEndDate: data?.endDate ? format(new Date(data.endDate), TOURNAMENT_DATE_FORMAT) : undefined,
    mainImage,
    images: preparedImages ?? [],
    twitterHashTag: data?.twitterHashTag || '',
  };

  const { mutate: mutateListQuery } = useTournamentsListQuery(game?.id);

  const { isTournamentUploading, createTournament } = useTournamentCreateMutation(mutateListQuery);
  const { isTournamentEditUploading, editTournament } = useTournamentEditMutation({
    mutateListQuery,
  });
  const [editedImages, setEdited] = useState<{ image: File; guid: string }[]>([]);
  const isEdit = Boolean(tournamentId);

  const [isFormOpen, setFormOpen] = useState(false);

  useEffect(() => {
    if (game?.tournaments?.length === 0) {
      setFormOpen(true);
    }
  }, [game?.tournaments?.length]);

  const handleOnSubmit = useCallback(
    async (formData: FormValuesType) => {
      if (!game?.id) {
        return;
      }

      const signer = await getSigner();
      const signature = await signer.signMessage(formData.tournamentTitle);

      if (!isEdit) {
        let images: File[] | undefined = [];
        let mainImageFile: File | undefined;
        if (formData.image1 && formData.image2 && formData.image3) {
          images = [formData.image1, formData.image2, formData.image3];
        }
        if (formData.mainImage) {
          mainImageFile = formData.mainImage as File;
        }
        await createTournament({
          gameId: parseInt(game?.id, 10),
          title: formData.tournamentTitle,
          subtitle: formData?.tournamentSubtitle,
          startDate: formData?.tournamentStartDate,
          endDate: formData?.tournamentEndDate,
          twitterHashTag: formData.twitterHashTag,
          mainImage: mainImageFile,
          images,
          address,
          signature,
        });
        return;
      }
      await editTournament({
        gameId: parseInt(game?.id, 10),
        title: formData.tournamentTitle,
        subtitle: formData.tournamentSubtitle,
        startDate: formData.tournamentStartDate,
        endDate: formData.tournamentEndDate,
        twitterHashTag: formData.twitterHashTag,
        tournamentId: tournamentId as number,
        ...(editedImages.length > 0 && {
          guidsToUpdate: editedImages.map(({ guid }) => guid),
          imagesToUpdate: editedImages.map(({ image }) => image),
        }),
        address,
        signature,
      });
      setEdited([]);
    },
    [address, createTournament, editTournament, editedImages, game?.id, isEdit, tournamentId],
  );

  const handleOnEditTournament = useCallback(async (tournament: Tournament) => {
    setTournamentId(tournament.id);
    setFormOpen(true);
  }, []);

  useEffect(() => {
    if (!mainImage && preparedImages?.length === 0) {
      setLeaderboardView('single_image');
    } else {
      setLeaderboardView(mainImage ? 'single_image' : 'multiple_images');
    }
  }, [data, mainImage, preparedImages?.length]);

  const handleOnSubmitForm = useCallback(
    async (
      handleSubmit: (
        event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
      ) => Promise<AnyObject | undefined> | undefined,
    ) => {
      await handleSubmit();
      setFormOpen(false);
    },
    [],
  );

  const handleOnViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value as 'single_image' | 'multiple_images';
    setLeaderboardView(targetValue);
  };

  const handleOnFileFieldChange = (value: { guid: string; file: File }) => {
    if (!value.guid) {
      return;
    }
    const isExist = editedImages.map(({ guid }) => guid).includes(value.guid);
    if (isExist) {
      return;
    }
    setEdited((prevState) => [...prevState, { guid: value.guid, image: value?.file || undefined }]);
  };

  const disableRadio = useMemo(() => {
    if (!mainImage && preparedImages?.length === 0) {
      return false;
    }
    if (mainImage || (preparedImages && preparedImages.length > 0)) {
      return true;
    }
    return true;
  }, [mainImage, preparedImages]);

  return (
    <>
      <Button
        disabled={tournaments?.length === 0 && isFormOpen}
        onClick={() => {
          if (!tournamentId) {
            setFormOpen(true);
          }
          setTournamentId(undefined);
        }}
        className={`${tournaments?.length !== 0 ? 'mb-0' : 'mb-7'} !font-medium`}
        skin="secondary"
        icon="+"
      >
        Add new tournament
      </Button>
      <TournamentsList onEditTournament={handleOnEditTournament} game={game} />
      <TournamentCreateUpdateForm
        isEdit={isEdit}
        isFormOpen={isFormOpen}
        isLoading={isLoading}
        isUploading={isTournamentUploading || isTournamentEditUploading}
        initialValues={initialData}
        handleOnSubmitForm={handleOnSubmitForm}
        onSubmit={handleOnSubmit}
        onViewChange={handleOnViewChange}
        leaderBoardView={leaderboardView}
        handleOnFileFieldChange={isEdit ? handleOnFileFieldChange : () => {}}
        disableRadio={disableRadio}
      />
    </>
  );
}
