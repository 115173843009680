import ManageAccountIcon from '@mui/icons-material/ManageAccounts';
import SendIcon from '@mui/icons-material/SendOutlined';
import { Avatar, Box, Button, Divider, IconButton, MenuItem, Popover, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { UserBalance } from '~/components/layout/user-balance';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useWalletDisconnect } from '~/context/auth';
import { formatError } from '~/lib/formatters';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import { shortenHexAddress, TOKEN_TITLE } from '~/lib/utils';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import { Identicon } from './identicon';
import { useOpen } from './use-open';

export const AccountMenu = () => {
  const anchorRef = useRef(null);
  const { isOpen, open, close } = useOpen(false);
  const userPubKey = useUserPublicKey();
  const disconnectWallet = useWalletDisconnect();
  const { showMessage } = useMessages();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const onDisconnect = async () => {
    try {
      await disconnectWallet();
    } catch (error) {
      showMessage(`Failed to disconnect. ${formatError(error)}`, 'error');
    }
  };

  return (
    <Box>
      <IconButton ref={anchorRef} onClick={open} className="p-0 w-8 h-8">
        <Avatar className="bg-white">
          <Identicon value={userPubKey} />
        </Avatar>
      </IconButton>

      <Popover
        open={isOpen}
        onClose={close}
        sx={{ width: 240 }}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.5,
            overflow: 'inherit',
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.3)}`,
            width: 240,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            Metamask
          </Typography>
          {shortenHexAddress(userPubKey, 6)} <CopyToClipboard value={userPubKey} />
        </Box>

        <Divider className="my-1" />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <UserBalance />
        </Box>

        <Divider className="my-1" />

        <MenuItem
          to={putUrlParam(
            routes[RoutesEnum.ACCOUNT_MINTED].url,
            {
              accountId: userPubKey,
            },
            activeTenant,
          )}
          component={NavLink}
          onClick={close}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={ManageAccountIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          My account
        </MenuItem>

        <MenuItem
          to={putUrlParam(routes[RoutesEnum.CONTENT_LIST].url, {}, activeTenant)}
          component={NavLink}
          onClick={close}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={ManageAccountIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          My content
        </MenuItem>

        <MenuItem
          to={putUrlParam(routes[RoutesEnum.TRANSFER_CERE_TOKENS].url, {}, activeTenant)}
          component={NavLink}
          onClick={close}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={SendIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Transfer {TOKEN_TITLE}
        </MenuItem>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={onDisconnect}>
            Disconnect
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
