import useSWR from 'swr';

import { getDdcParams, getDdcParamsUrl } from '~/routes/video/services/ddc.service';
import { buildIFileUrl } from '~/routes/video/services/shared';

export function useDdcIfileUrl(cid?: string): undefined | string {
  const { data: ddcParams } = useSWR(cid ? getDdcParamsUrl() : null, getDdcParams, {
    revalidateOnFocus: false,
  });
  return ddcParams && cid && buildIFileUrl(ddcParams?.cdnUrl, ddcParams?.publicBucketId, cid);
}
