import { Card, CardContent, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { shortenNftId } from '~/lib/utils';
import { useAssetMetadata } from '~/routes/wallet/hooks/use-asset-metadata';
import { WalletType } from '~/routes/wallet/types/wallet-type';
import { getMintedTokens } from '~/services/assets.service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Nft } from '~/types/nft';

const fetcher = async (address: string, walletType: WalletType): Promise<Nft[]> => {
  if (walletType === WalletType.MINTED) {
    return getMintedTokens(address);
  }

  throw new Error(`WalletType "${walletType}" is not supported`);
};

export function NftPreview({ nftId }: { nftId?: string }) {
  const address = useUserPublicKey();
  const { data: mintedNfts } = useSWR<Nft[], Error>({ walletType: WalletType.MINTED }, ({ walletType }) =>
    fetcher(address, walletType),
  );
  const { metadata } = useAssetMetadata(nftId as string);
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const nftToPreview = useMemo(() => {
    if (!mintedNfts?.length) {
      return null;
    }
    return mintedNfts.find((s) => s.nftId === nftId);
  }, [mintedNfts, nftId]);

  return (
    <NavLink
      to={putUrlParam(
        routes[RoutesEnum.NFT_ASSETS].url,
        {
          nftId: nftId as string,
        },
        activeTenant,
      )}
      className="mt-[35px]"
    >
      <Card className="bg-[#191E31]">
        <CardContent className="bg-[#191E31] min-h-[150px] mt-[26px] px-6 pt-6 pb-8">
          <Typography className="text-sm mb-2" gutterBottom variant="h5" component="div">
            Title: <b>{metadata.contentMetadata?.title}</b>
          </Typography>
          {nftToPreview && (
            <Typography className="text-sm mb-2" variant="h5" sx={{ flexGrow: 1 }}>
              Supply: <b>{nftToPreview?.supply}</b>
            </Typography>
          )}
          <Typography className="text-sm" gutterBottom variant="h5" component="div">
            NFT ID: <b>{shortenNftId(nftId as string)}</b>
          </Typography>
        </CardContent>
      </Card>
    </NavLink>
  );
}
