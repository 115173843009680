import { Button, CircularProgress } from '@mui/material';
import classnames from 'classnames';
import { FormEvent, ReactNode } from 'react';
import { Prompt } from 'react-router-dom';

type Props = {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  formId?: string;
  submitDisabled?: boolean;
  submitButtonTitle?: string;
  children: ReactNode;
  isValid?: () => boolean;
  actionInProgress?: boolean;
  hideSubmit?: boolean;
  alignSubmitButton?: 'left' | 'center';
};

export const SimpleForm = ({
  onSubmit,
  formId,
  submitDisabled = false,
  submitButtonTitle = 'Submit',
  children,
  isValid = () => true,
  actionInProgress = false,
  hideSubmit = false,
  alignSubmitButton = 'center',
}: Props) => (
  <form id={formId} onSubmit={onSubmit}>
    <Prompt
      when={actionInProgress}
      message={(location) => `Operation is in progress. Are you sure you want to switch to ${location.pathname}`}
    />
    {children}
    <div
      className={classnames(
        'flex',
        alignSubmitButton === 'center' && 'justify-center',
        alignSubmitButton === 'left' && 'justify-start',
      )}
    >
      {!hideSubmit && (
        <Button
          title={submitButtonTitle}
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          disabled={submitDisabled || actionInProgress || !isValid()}
        >
          <span>{submitButtonTitle}</span>&nbsp;
          {actionInProgress && <CircularProgress />}
        </Button>
      )}
    </div>
  </form>
);
