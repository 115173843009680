/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox, MenuItem } from '@mui/material';
import classnames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useSWR from 'swr';

import { SectionHeader } from '~/components/common/section-header';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useRouteParam } from '~/lib/hooks/use-route-param';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { getSigner } from '~/services/get-signer';

import ExpandIcon from '../assets/expand-icon.svg?jsx';
import UploadImageIcon from '../assets/upload-image-icon.svg?jsx';
import { Button } from '../components/button';
import { FormField } from '../components/form-field';
import { ReturnBackLink } from '../components/return-back-link';
import { FormSelect } from '../components/select/form-select';
import { UploadField } from '../components/upload-field';
import formGroupStyles from '../form-group.module.css';
import { useDdcIfileUrl } from '../hooks/use-ddc-ifile-url';
import { getVideoSeasons, getVideoSeasonsUrl } from '../services/collections.service';
import { getSubscription, getSubscriptionUrl, saveSubscription } from '../services/subscription.service';
import transitions from '../styles/transitions.module.css';
import { SubscriptionDataAccessStrategy, SubscriptionPlanType, SubscriptionUnit } from '../types/subscriptions';
import subscriptionStyles from './subscriptions.module.css';

const intervals = [
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
] as const;

const planOptions: SubscriptionPlanType[] = ['base', 'premium', 'platinum'];

type FormValues = {
  id?: number;
  status: boolean;
  title: string;
  description: string;
  cover?: File;
  coverCid?: string;
  plan: SubscriptionPlanType;
  dataAccessStrategy: SubscriptionDataAccessStrategy;
  price: number;
  duration?: {
    amount: number;
    units: SubscriptionUnit;
  };
  relatedVideoCollections: number[];
  seo: {
    title?: string;
    canonical?: string;
    description: string;
    image?: File;
    mail?: File;
  };
};

export function EditSubscription() {
  const { putActiveTenantUrlParam } = useUrlParams();
  const [showSeo, setShowSeo] = useState(false);
  const seoFieldsRef = useRef<HTMLDivElement | null>(null);
  const subscriptionId = useRouteParam('subscriptionId');
  const address = useUserPublicKey();
  const history = useHistory();

  const isCreatePage = subscriptionId === 'add';
  const saveLabel = isCreatePage ? 'Create subscription' : 'Edit subscription';
  const subscriptionHomeUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_SUBSCRIPTIONS].url, {});

  const { data: seasons } = useSWR(getVideoSeasonsUrl(), getVideoSeasons);
  const { data: subscriptionData } = useSWR(
    isCreatePage ? null : [getSubscriptionUrl(String(subscriptionId)), address],
    getSubscription,
  );

  const submit = useCallback(
    async (values: FormValues) => {
      const {
        id,
        relatedVideoCollections,
        duration,
        dataAccessStrategy,
        cover,
        coverCid,
        title,
        description,
        price,
        status,
        plan,
      } = values;

      const signer = await getSigner();
      const signature = await signer.signMessage(title);
      return saveSubscription({
        address,
        signature,
        message: title,
        id,
        relatedVideoCollections,
        durationUnits: duration?.units,
        durationAmount: duration?.amount,
        dataAccessStrategy,
        cover,
        coverCid,
        description,
        title,
        status,
        plan,
        priceCents: price * 100,
      })
        .then(() => history.push(subscriptionHomeUrl))
        .catch(() => ({ [FORM_ERROR]: 'Creating or saving have failed' }));
    },
    [address, history, subscriptionHomeUrl],
  );

  const seasonsMap = useMemo(() => {
    const initial: Record<number, string> = {};
    return (
      seasons?.reduce((result, season) => {
        // eslint-disable-next-line no-param-reassign
        result[season.id] = [season.parent?.name, `Season ${season.name}`].filter(Boolean).join(' | ');
        return result;
      }, initial) ?? initial
    );
  }, [seasons]);

  const coverUrl = useDdcIfileUrl(subscriptionData?.metadata.coverCid);

  const initialValues: FormValues = useMemo(() => {
    const data: FormValues = {
      id: subscriptionData?.id,
      status: subscriptionData?.status ?? true,
      title: subscriptionData?.title ?? '',
      description: subscriptionData?.description ?? '',
      cover: undefined,
      plan: subscriptionData?.metadata.plan || 'base',
      coverCid: subscriptionData?.metadata.coverCid,
      price: subscriptionData?.priceCents ? Number(subscriptionData?.priceCents) / 100 : 0,
      dataAccessStrategy: 'FULL_VIDEO_SERIES' as const,
      relatedVideoCollections: subscriptionData?.collections?.map((c) => c.id) ?? [],
      seo: {
        title: '',
        canonical: '',
        description: '',
        image: undefined,
        mail: undefined,
      },
    };
    if (subscriptionData?.metadata.duration) {
      data.duration = {
        amount: subscriptionData.metadata.duration.amount,
        units: subscriptionData.metadata.duration.units,
      };
    }
    return data;
  }, [
    subscriptionData?.id,
    subscriptionData?.status,
    subscriptionData?.title,
    subscriptionData?.description,
    subscriptionData?.metadata.coverCid,
    subscriptionData?.metadata.duration,
    subscriptionData?.metadata.plan,
    subscriptionData?.priceCents,
    subscriptionData?.collections,
  ]);

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, submitting, submitError, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className={formGroupStyles.block}>
            <ReturnBackLink url={subscriptionHomeUrl}>Back Subscriptions</ReturnBackLink>
          </div>
          <SectionHeader
            title={isCreatePage ? 'Create subscription' : 'Edit subscription'}
            className={formGroupStyles.block}
          />
          {submitError && !submitting && <div className="text-center text-pink-400">{submitError}</div>}
          <div className={classnames(formGroupStyles.group, formGroupStyles.block)}>
            <div className="grid gap-x-6 items-end grid-cols-[300px_1fr] items-stretch">
              <Field name="cover">
                {({ input }) => (
                  <UploadField preview={coverUrl} classname="aspect-auto" onChange={input.onChange} type="image">
                    <div className="flex flex-col items-center select-none">
                      <UploadImageIcon />
                      <h4 className="text-lg mt-2 text-white">
                        Drag and drop an image, or <span className="text-pink-500">Browse</span>
                      </h4>
                      <p className="text-sm text-white/50">1024x768 or higher (png, jpg, gif) Max 10mb</p>
                    </div>
                  </UploadField>
                )}
              </Field>
              <div className="flex flex-col gap-y-6">
                <Field type="checkbox" name="status">
                  {({ input: { checked, type, name, onChange, onFocus, onBlur, value } }) => (
                    <label className="flex items-center gap-x-2">
                      Activated
                      <input
                        className="text-xl"
                        type={type}
                        name={name}
                        checked={checked}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                      />
                    </label>
                  )}
                </Field>
                <FormField name="title" label="Title" required />
                <FormField name="description" rows={3} type="textarea" label="Description" required />
              </div>
            </div>
          </div>
          <div className={classnames(formGroupStyles.group, formGroupStyles.block, 'flex', 'flex-col', 'gap-y-6')}>
            <FormField name="price" type="number" label="Price" required placeholder="Price in USD" />
            <div className="flex items-center gap-x-6">
              <div>Subscription duration:</div>
              <div className="flex items-center gap-x-6">
                <div className="w-[140px]">
                  <label className="flex items-center gap-x-4">
                    <span className="text-white/40 text-sm whitespace-nowrap">Amount *:</span>
                    <FormField required name="duration.amount" type="number" />
                  </label>
                </div>
                <label className="flex items-center gap-x-4">
                  <span className="text-white/40 text-sm">Units *:</span>
                  <Field name="duration.units">
                    {({ input }) => (
                      <FormSelect
                        required
                        placeholder="interval"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value}
                      >
                        {intervals.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    )}
                  </Field>
                </label>
              </div>
            </div>
            <Field name="plan">
              {({ input }) => (
                <FormSelect label="Subscription plan" name={input.name} onChange={input.onChange} value={input.value}>
                  {planOptions.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value.toUpperCase()}
                    </MenuItem>
                  ))}
                </FormSelect>
              )}
            </Field>
            <Field name="relatedVideoCollections">
              {({ input }) => (
                <FormSelect
                  renderValue={(ids) => (Array.isArray(ids) ? ids.map((id) => seasonsMap[Number(id)]).join(', ') : ids)}
                  multiple
                  disabled={seasons == null}
                  label="Assigned collections"
                  name={input.name}
                  onChange={input.onChange}
                  value={input.value}
                >
                  {seasons?.map((season) => (
                    <MenuItem key={season.id} value={season.id}>
                      <Checkbox checked={Array.from(input.value).includes(season.id)} />
                      <div>{seasonsMap[season.id]}</div>
                    </MenuItem>
                  ))}
                </FormSelect>
              )}
            </Field>
          </div>
          <div className={classnames(formGroupStyles.group, formGroupStyles.block)}>
            <button
              onClick={() => setShowSeo((s) => !s)}
              className={classnames(
                'flex',
                'border-0',
                'bg-[#191e31]',
                'items-center',
                'justify-between',
                'hover:opacity-80',
                'w-full',
                'px-3',
                'py-2',
                'rounded-md',
                '-ml-1',
              )}
              type="button"
            >
              SEO settings (Optional)
              <div
                className={classnames(subscriptionStyles['expand-seo-icon'], 'transform-gpu', {
                  'rotate-180': !showSeo,
                })}
              >
                <ExpandIcon />
              </div>
            </button>
            <CSSTransition
              in={showSeo}
              timeout={500}
              mountOnEnter
              unmountOnExit
              classNames={{
                enter: transitions.enter,
                enterActive: transitions['enter-active'],
                exit: transitions.exit,
                exitActive: transitions['exit-active'],
              }}
              nodeRef={seoFieldsRef}
            >
              <div ref={seoFieldsRef} className="flex flex-col gap-y-6 mt-8">
                <FormField label="SEO title" placeholder="Title" name="seo.title" />
                <FormField label="SEO canonical" placeholder="Canonical" name="seo.canonical" />
                <FormField
                  label="SEO description"
                  placeholder="Description"
                  name="seo.description"
                  type="textarea"
                  rows={3}
                />
                <div className="grid grid-cols-2 gap-x-6">
                  <Field name="seo.image">
                    {({ input }) => (
                      <div className="flex flex-col gap-y-2">
                        <span className="relative left-1 select-none">SEO image</span>
                        <UploadField onChange={input.onChange} type="image">
                          <div className="flex flex-col items-center select-none">
                            <UploadImageIcon />
                            <h4 className="text-lg mt-2 text-white">
                              Drag and drop an image, or <span className="text-pink-500">Browse</span>
                            </h4>
                            <p className="text-sm text-white/50">1024x768 or higher (png, jpg, gif) Max 10mb</p>
                          </div>
                        </UploadField>
                      </div>
                    )}
                  </Field>
                  <Field name="seo.mail">
                    {({ input }) => (
                      <div className="flex flex-col gap-y-2">
                        <span className="relative left-1 select-none">Image for mail</span>
                        <UploadField onChange={input.onChange} type="image">
                          <div className="flex flex-col items-center select-none">
                            <UploadImageIcon />
                            <h4 className="text-lg mt-2 text-white">
                              Drag and drop an image, or <span className="text-pink-500">Browse</span>
                            </h4>
                            <p className="text-sm text-white/50">1024x768 or higher (png, jpg, gif) Max 10mb</p>
                          </div>
                        </UploadField>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </CSSTransition>
          </div>
          <div className={classnames(formGroupStyles.block, 'my-8')}>
            <Button submit disabled={submitting || pristine}>
              {submitting ? 'Saving...' : saveLabel}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
}
