import { useEffect } from 'react';
import useSWR from 'swr/immutable';

import { fetcher } from '~/constants/fetcher';
import { Game } from '~/routes/gaming/gaming.types';
import { GamesApiEndpoints } from '~/services/games';

export function useGameQuery({ id, enable = false }: { id: string; enable: boolean }) {
  const { data, isLoading, error, mutate } = useSWR<Game, Error>(
    enable ? { url: GamesApiEndpoints.gameUrl(id) } : null,
    fetcher,
  );
  useEffect(() => {
    void mutate().then((r) => r);
  }, [mutate]);

  return { data, isLoading, error, mutate };
}
