import { API_URL } from '~/constants/env';
import { GuardType } from '~/types/guard-type';

export type AuthParams = {
  address: string;
  signature: string;
  message: string;
};

export const getUrl = (pathname = ''): string => {
  try {
    const url = new URL(API_URL());
    url.pathname = pathname;
    return url.href;
  } catch (e) {
    console.error(e);
    return '';
  }
};

type AuthHeaders = { 'x-message': string; 'x-signature': string; 'x-public-key': string };

export const getAuthHeaders = ({ address, signature, message }: AuthParams): AuthHeaders => ({
  'x-public-key': address,
  'x-signature': signature,
  'x-message': message,
});

export function xhrMiddleware(response: Response, json = true): Promise<unknown> {
  if (response.ok) {
    return json ? response.json() : response.text();
  }
  return new Promise((_, reject) => {
    response.text().then(reject, reject);
  });
}

export function typeGuardMiddleWare<G extends (x: unknown) => boolean>(guard: G) {
  return (val: unknown): GuardType<G> => {
    if (guard(val)) {
      return val as GuardType<G>;
    }
    throw Error(`Bad response ${JSON.stringify(val, null, 2)}`);
  };
}

export function buildIFileUrl(
  cdnUrl: string | undefined,
  bucket: number | undefined,
  cid: string | undefined,
): string | undefined {
  if (cdnUrl == null || bucket == null || cid == null) {
    return undefined;
  }
  const url = new URL(cdnUrl);
  url.pathname = `ddc/buc/${bucket}/ifile/${cid}`;
  return url.href;
}
