import { createFreeportCollection } from '@cere/freeport-sc-sdk';
import { ethers } from 'ethers';

import { getSigner } from '~/services/get-signer';

type FreeportCollection = ReturnType<typeof createFreeportCollection>;

export const getFreeportCollectionForSigner = async (
  signer: ethers.Signer,
  collectionAddress: string,
): Promise<FreeportCollection> => createFreeportCollection({ signer, contractAddress: collectionAddress });

export const getFreeportCollection = async (collectionAddress: string): Promise<FreeportCollection> => {
  const signer = await getSigner();
  return getFreeportCollectionForSigner(signer, collectionAddress);
};
