import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { format, isEqual, isValid, startOfDay } from 'date-fns';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { FileInputFormField } from '~/components/common/form/file-input-form-field/file-input-form-field';
import { InputFormField } from '~/components/common/form/input-form-field';
import { MaskedInputFormField } from '~/components/common/form/masked-input-form-field';
import { FormGrid } from '~/components/common/form-grid';
import { FormSection } from '~/components/common/form-section';
import { WizardContext } from '~/components/common/wizard/wizard.context';
import { Condition, ConditionsList } from '~/components/conditions';
import { TournamentCreateUpdateFormPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournament-create-update-form/tournament-create-update-form.types';
import { GameSkeleton } from '~/routes/gaming/gaming-home-route/components/game-skeleton';

const DATE_MIN = new Date();

export const TournamentCreateUpdateForm = ({
  isFormOpen,
  isLoading,
  initialValues,
  onSubmit,
  handleOnSubmitForm,
  isEdit,
  leaderBoardView,
  onViewChange,
  handleOnFileFieldChange,
  isUploading,
  disableRadio,
}: TournamentCreateUpdateFormPropsType) => {
  const { goToPrevStep, goToNextStep } = useContext(WizardContext);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      validate={(values) => {
        let errors = {};
        const startDate = values.tournamentStartDate
          ? new Date(values.tournamentStartDate.split('/').reverse().join('/'))
          : null;
        const endDate = values.tournamentEndDate
          ? new Date(values.tournamentEndDate.split('/').reverse().join('/'))
          : null;
        if (startDate && isValid(startDate) && startOfDay(DATE_MIN) > startDate) {
          errors = {
            ...errors,
            tournamentStartDate: `Start date should be greater or equal than ${format(DATE_MIN, 'yyyy-MM-dd')}`,
          };
        }
        if (
          endDate &&
          startDate &&
          isValid(endDate) &&
          isValid(startDate) &&
          isEqual(startOfDay(endDate), startOfDay(startDate))
        ) {
          errors = {
            ...errors,
            tournamentEndDate: `End date should be greater than Start date`,
          };
        }
        return errors;
      }}
    >
      {({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <FormGrid>
            <ConditionsList>
              <Condition condition={isLoading}>
                <GameSkeleton />
              </Condition>
              <Condition condition={!isLoading}>
                {isFormOpen && (
                  <FormSection>
                    <InputFormField
                      name="tournamentTitle"
                      placeholder="Example: Unlock NFT and token rewards, work your way to the top of the leaderboard and claim a bonus prize!"
                      required
                      label="Announcement Title*"
                      showMaxSymbols
                      maxLength={36}
                    />
                    <InputFormField
                      required
                      name="tournamentSubtitle"
                      placeholder="Example: Weekly challenge"
                      label="Subtitle *"
                      showMaxSymbols
                      maxLength={24}
                    />
                    <InputFormField
                      required
                      name="twitterHashTag"
                      placeholder="Add your unique hashtag for a twitter."
                      label="Hashtag *"
                      showMaxSymbols
                      maxLength={24}
                    />
                    <FormControl>
                      <FormLabel
                        sx={{
                          '&.Mui-focused': {
                            color: '#919eab',
                          },
                        }}
                        id="demo-row-radio-buttons-group-label"
                      >
                        <Typography>Choose your leaderboard</Typography>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={leaderBoardView}
                        onChange={onViewChange}
                      >
                        <FormControlLabel
                          value="single_image"
                          disabled={disableRadio && isEdit && leaderBoardView === 'multiple_images'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#e851c3',
                                },
                              }}
                            />
                          }
                          label="1 big on the top"
                        />
                        <FormControlLabel
                          value="multiple_images"
                          disabled={disableRadio && isEdit && leaderBoardView === 'single_image'}
                          control={
                            <Radio
                              sx={{
                                '&.Mui-checked': {
                                  color: '#e851c3',
                                },
                              }}
                            />
                          }
                          label="3 small picture for a prizes"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div style={{ display: leaderBoardView === 'single_image' ? 'block' : 'none' }}>
                      <FileInputFormField
                        onChangeField={handleOnFileFieldChange}
                        type="image"
                        name="mainImage"
                        deletable={false}
                      />
                    </div>
                    <div
                      style={{
                        display: leaderBoardView === 'multiple_images' ? 'flex' : 'none',
                        justifyContent: 'space-between',
                        maxWidth: '900px',
                      }}
                    >
                      <div style={{ maxWidth: '250px' }}>
                        <FileInputFormField
                          onChangeField={handleOnFileFieldChange}
                          type="image"
                          name={isEdit ? 'images.image1' : 'image1'}
                          deletable={false}
                        />
                      </div>
                      <div style={{ maxWidth: '250px' }}>
                        <FileInputFormField
                          onChangeField={handleOnFileFieldChange}
                          type="image"
                          name={isEdit ? 'images.image2' : 'image2'}
                          deletable={false}
                        />
                      </div>
                      <div style={{ maxWidth: '250px' }}>
                        <FileInputFormField
                          onChangeField={handleOnFileFieldChange}
                          type="image"
                          name={isEdit ? 'images.image3' : 'image3'}
                          deletable={false}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-[266px_266px] gap-[12px]">
                      <MaskedInputFormField
                        isDate
                        mask="99/99/9999"
                        name="tournamentStartDate"
                        label="Start Date"
                        placeholder="DD/MM/YYYY"
                        required
                        showMaskOnFocus={false}
                        showMaskOnHover={false}
                      />
                      <MaskedInputFormField
                        isDate
                        mask="##/##/####"
                        name="tournamentEndDate"
                        label="End Date"
                        placeholder="DD/MM/YYYY"
                        required
                        showMaskOnFocus={false}
                        showMaskOnHover={false}
                      />
                    </div>
                    <Box sx={{ alignItems: 'center' }}>
                      <Button
                        onClick={() => handleOnSubmitForm(handleSubmit)}
                        type="submit"
                        loading={isUploading}
                        fluid
                        disabled={invalid || submitting}
                        className="!rounded-xl !shadow-none"
                      >
                        {isEdit ? 'Save changes' : 'Create tournament'}
                      </Button>
                    </Box>
                  </FormSection>
                )}
              </Condition>
            </ConditionsList>
            <div className={`flex justify-between ${!isFormOpen ? 'mt-8' : 'mt-0'}`}>
              <Button
                className="min-w-[200px] back-button"
                skin="outlined"
                icon={<KeyboardBackspaceIcon />}
                onClick={goToPrevStep}
              >
                Back
              </Button>
              <Button className="min-w-[266px]" skin="primary" postIcon={<ArrowRightAltIcon />} onClick={goToNextStep}>
                Add Preloader
              </Button>
            </div>
          </FormGrid>
        </form>
      )}
    </Form>
  );
};
