import { AnalyticServiceInterface } from '~/services/analytics/types';
import { NetworkServiceInterface } from '~/services/v2/interface/network.service.interface';
import { FullNft } from '~/types/nft';

export enum WalletNetworkType {
  EIP155 = 'EIP155',
  SOLANA = 'SOLANA',
}

export type AppContextType = {
  userPubKey: string;
  changeNetworkDriver: (network: WalletNetworkType) => void;
  analyticsService: AnalyticServiceInterface;
  isLoading: boolean;
  network: WalletNetworkType | null;
  driver: NetworkServiceInterface | null;
  disconnectWallet: () => void;
  connectWallet: (wallet: string) => void;
};

export type ItemLayoutType = {
  nft: FullNft;
  mutate: () => Promise<unknown>;
};
