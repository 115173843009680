import { StatusTheme } from '~/types/status';

export type Game = {
  id: string;
  title: string;
  code: string;
  path: string;
  nftId?: string;
  preloaderTitle?: string;
  preloaderDescription?: string[];
  preloaderPath?: string;
  status?: keyof typeof StatusTheme;
  tournaments: Tournament[];
};

export type UploadId = string | 'upload';

export enum StatusEnum {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export type ImageType = {
  id: string;
  guid: string;
  path: string;
  mainImage: boolean;
};

export type Tournament = {
  id: number;
  title: string;
  subtitle: string;
  startDate: Date;
  endDate: Date;
  status: StatusEnum;
  game: Game;
  mainImage?: ImageType;
  images?: ImageType[];
  twitterHashTag: string;
};

export type Reward = {
  id: number;
  publicKey: string;
  amount: number;
  txHash?: string;
  type: RewardType;
  gameCode: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum RewardType {
  TWITTER = 'twitter',
  TOURNAMENT = 'tournament',
}
