import classnames from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { SectionHeader } from '~/components/common/section-header';
import { Condition, ConditionsList } from '~/components/conditions';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { getDdcParams, getDdcParamsUrl } from '~/routes/video/services/ddc.service';

import { getVideoMetadataList, getVideoMetadataUrl } from './services/video.services';
import styles from './video.module.css';
import { VideoItem } from './video-item';

type Response = Awaited<ReturnType<typeof getVideoMetadataList>>;

export function VideoHome(): ReactElement {
  useSWR(getDdcParamsUrl(), getDdcParams, {
    revalidateOnFocus: false,
  });
  const { data, error, mutate } = useSWR<Response, Error>(getVideoMetadataUrl(), getVideoMetadataList);
  const { putActiveTenantUrlParam } = useUrlParams();
  const uploadUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_EDIT].url, { videoId: 'add' });

  return (
    <div className="w-[900px] mx-auto">
      <div className="flex gap-x-8 items-center">
        <SectionHeader title="My Video Library" />
        <Link to={uploadUrl} className={classnames(styles['link-button'])}>
          Upload video
        </Link>
      </div>
      <ConditionsList>
        <Condition condition={data != null && data.length > 0 && error == null}>
          <div className={classnames('flex', 'flex-col', 'gap-y-6', 'mt-8')}>
            {data?.map((item) => (
              <VideoItem
                id={item.id}
                key={item.id}
                title={item.videoTitle}
                bucketId={item.bucketId}
                videoCid={item.videoCid}
                coverCid={item.coverCid}
                refreshList={mutate}
                collections={item.collections}
                encrypted={item.encrypted}
              />
            ))}
          </div>
        </Condition>
        <Condition condition={(data != null && data.length === 0) || error != null}>
          <div
            className={classnames(
              styles.preview,
              'w-[300px]',
              'h-[300px]',
              'relative',
              'rounded-lg',
              'box-border',
              'p-6',
              'text-white',
              'relative',
              'mt-8',
            )}
          >
            <h3 className="text-white text-[24px] font-bold">Upload your video</h3>
            <p>Tap to get started</p>
            <Link
              to={uploadUrl}
              className={classnames(
                'bg-white/60',
                'absolute',
                'right-[20px]',
                'bottom-[20px]',
                'text-[64px]',
                'text-black',
                'select-none',
                'transition-colors',
                'hover:bg-white/90',
                'rounded-full',
                'w-16',
                'h-16',
                'flex',
                'items-center',
                'justify-center',
              )}
            >
              +
            </Link>
          </div>
        </Condition>
      </ConditionsList>
    </div>
  );
}
