import { BigNumber } from 'ethers';

import { TOKEN_TITLE } from '~/lib/utils';
import { getERC20 } from '~/services/get-erc20';

import { getFToken } from './get-ftoken';

export const getFtokenBalance = async (userPublicKey: string): Promise<BigNumber> => {
  const fToken = await getFToken();
  return fToken.balanceOf(userPublicKey);
};

export const getErc20Balance = async (userPublicKey: string): Promise<BigNumber> => {
  const erc20 = await getERC20();
  return erc20.balanceOf(userPublicKey);
};

export const formatBalance = (balance?: BigNumber): string => {
  if (!balance) return `0.00 ${TOKEN_TITLE}`;

  const balanceString = balance.toString();
  const balanceLength = balanceString.length;
  const balanceInteger = balanceString.slice(0, balanceLength - 18) || '0';
  const balanceDecimal = balanceString.slice(balanceLength - 18, balanceLength - 16) || '00';
  return `${balanceInteger}.${balanceDecimal} ${TOKEN_TITLE}`;
};
