import settingsOutline from '@iconify/icons-eva/settings-outline';
import { Icon } from '@iconify/react';
import { Container, FormLabel, Typography } from '@mui/material';
import { BigNumber, BigNumberish } from 'ethers';
import { FORM_ERROR, FormApi } from 'final-form';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { Input, NumberInput } from '~/components/form/fields';
import { addressValidator, royaltyValidator } from '~/components/form/fields/validators';
import { SimpleForm } from '~/components/form/simple-form';
import { HelpPopover } from '~/components/icons/help-popover';
import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { Empty } from '~/components/layout/empty';
import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError } from '~/lib/formatters';
import { putUrlParam } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import {
  getCollectionRoyalties,
  getTokenRoyalties,
  setTokenPrimaryRoyalty,
  setTokenRoyalty,
} from '~/services/royalties-service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

const fetchCollectionRoyalties = ([, collectionAddress]: [string, string]) => getCollectionRoyalties(collectionAddress);
const fetchTokenRoyalties = ([, collectionAddress, nftId]: [string, string, BigNumberish]) =>
  getTokenRoyalties(collectionAddress, nftId);

type CollectionRoyalties = Awaited<ReturnType<typeof fetchCollectionRoyalties>>;
type TokenRoyalties = Awaited<ReturnType<typeof fetchTokenRoyalties>>;

type TokenRoyaltyForm = {
  default: {
    receiver: string;
    fraction: BigNumber;
  };
  primary: {
    receiver: string;
    fraction: BigNumber;
  };
};

const format = (val: BigNumberish): number =>
  BigNumber.from(val || 0)
    .div(100)
    .toNumber();
const parse = (val: BigNumberish): BigNumber => BigNumber.from(val || 0).mul(100);

const fractionValidator = (val: BigNumberish) => royaltyValidator(BigNumber.from(val).div(100).toNumber());

export const NftRoyalties = () => {
  const { showMessage } = useMessages();
  const isPaused = useRef(false);
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);
  const activeTenant = useActiveTenantId();

  const configureCollectionUrl = putUrlParam(
    routes[RoutesEnum.ACCOUNT_COLLECTIONS].url,
    { accountId: userPubKey },
    activeTenant,
  );

  const {
    data: collectionRoyalty,
    isLoading: collectionIsLoading,
    error: collectionRoyaltyError,
  } = useSWR<CollectionRoyalties, Error>(
    ['src/services/collections-service.ts/getCollectionRoyalties', nft.collection.address],
    fetchCollectionRoyalties,
    {
      isPaused: () => isPaused.current,
    },
  );
  const {
    data: tokenRoyalties,
    isLoading: tokenIsLoading,
    error: tokenRoyaltyError,
    mutate: tokenRoyaltyMutate,
  } = useSWR<TokenRoyalties, Error>(
    ['src/services/collections-service.ts/getTokenRoyalties', nft.collection.address, nft.nftId],
    fetchTokenRoyalties,
    {
      isPaused: () => isPaused.current,
    },
  );

  const tokenRoyaltyInitialValues: TokenRoyaltyForm = {
    default: {
      receiver: tokenRoyalties?.tokenRoyalty[0] ?? '',
      fraction: BigNumber.from(tokenRoyalties?.tokenRoyalty[1] ?? 0),
    },
    primary: {
      receiver: tokenRoyalties?.tokenPrimaryRoyalty[0] ?? '',
      fraction: BigNumber.from(tokenRoyalties?.tokenPrimaryRoyalty[1] ?? 0),
    },
  };

  const submit = useCallback(
    async (values: TokenRoyaltyForm, api: FormApi<TokenRoyaltyForm, TokenRoyaltyForm>) => {
      const state = api.getState();
      isPaused.current = true;
      if (state.dirtyFields['default.receiver'] || state.dirtyFields['default.fraction']) {
        await setTokenRoyalty({
          receiver: values.default.receiver,
          nftId: nft.nftId,
          collectionAddress: nft.collection.address,
          fraction: Number(values.default.fraction),
        }).catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          isPaused.current = false;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          return { [FORM_ERROR]: e?.reason || e?.message || 'Error while updating royalties' };
        });
      }
      if (state.dirtyFields['primary.receiver'] || state.dirtyFields['primary.fraction']) {
        await setTokenPrimaryRoyalty({
          receiver: values.primary.receiver,
          nftId: nft.nftId,
          collectionAddress: nft.collection.address,
          fraction: Number(values.primary.fraction),
        }).catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          isPaused.current = false;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          return { [FORM_ERROR]: e?.reason || e?.message || 'Error while updating royalties' };
        });
      }
      isPaused.current = false;
      await tokenRoyaltyMutate();
      return null;
    },
    [nft.collection.address, nft.nftId, tokenRoyaltyMutate],
  );

  const pending = collectionIsLoading || tokenIsLoading;

  useEffect(() => {
    if (collectionRoyaltyError) {
      showMessage(`Failed to fetch collection royalty. ${formatError(collectionRoyaltyError)}`, 'error');
    }
  }, [collectionRoyaltyError, showMessage]);

  useEffect(() => {
    if (tokenRoyaltyError) {
      showMessage(`Failed to fetch token royalty. ${formatError(tokenRoyaltyError)}`, 'error');
    }
  }, [tokenRoyaltyError, showMessage]);

  if (collectionRoyalty == null || tokenRoyalties == null) {
    return (
      <PageLayout isLoading>
        <Container sx={{ mb: 5 }}>
          <Empty />
        </Container>
      </PageLayout>
    );
  }

  return (
    <PageLayout isLoading={pending}>
      <Container sx={{ mb: 5 }}>
        <div className="mx-2">
          <div className="flex items-center mb-3">
            <div className="flex grow items-center gap-x-2">
              <Typography variant="h4">Royalties</Typography>
              <HelpPopover>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  Overview of royalties earned from sales price as configured by the creator of this NFT
                </Typography>
              </HelpPopover>
            </div>
          </div>
          <div className="text-gray-400 flex flex-col gap-y-4 mb-4">
            <p>
              Royalties can be set up in two optional parts: a percentage of the sale price for an NFT (orders), and a
              royalty per transfer. For straightforward transfers that don&#39;t involve a high sale, a minimum royalty
              is applied. The percentages define the shares, while the minimums are set in USDC.
            </p>
            <p>
              Primary royalties will be applied to transactions if the order is created by the token issuer (minter).
            </p>
            <p>
              Primary royalties will also be applied to transactions if the order is created by anyone other than the
              token issuer.
            </p>
            <p>
              There can be one beneficiary account for each primary and default royalty division. Create a joint account
              first if you want to distribute revenues between multiple parties.
            </p>
          </div>
          <div>
            <div className="border-b-[1px] my-2 border-gray-700" />
            <h3 className="text-xl flex mb-4 items-center gap-x-2 text-gray-300">
              Collection royalties
              <HelpPopover>
                <Typography sx={{ p: 2, maxWidth: 360 }}>Click to configure collection royalties</Typography>
              </HelpPopover>
              <Link to={configureCollectionUrl}>
                <Icon icon={settingsOutline} />
              </Link>
            </h3>
            <div className="grid grid-cols-[max-content_1fr] gap-y-2 gap-x-6 text-gray-300">
              <span>Royalty receiver:</span>
              <span className="flex items-center gap-x-1">
                <span>{collectionRoyalty.defaultRoyalty[0]}</span>
                <CopyToClipboard value={collectionRoyalty.defaultRoyalty[0]} />
              </span>
              <span>Royalty fraction:</span>
              <span>{BigNumber.from(tokenRoyalties.tokenRoyalty[1]).div(100).toString()}%</span>
            </div>
            <div className="border-b-[1px] my-2 border-gray-700" />
          </div>
          <div className="mt-8">
            <h3 className="text-xl flex mb-4 items-center gap-x-2 text-gray-300">Token royalties</h3>
            <Form onSubmit={submit} initialValues={tokenRoyaltyInitialValues}>
              {({ handleSubmit, submitting, pristine, valid }) => (
                <SimpleForm
                  actionInProgress={submitting}
                  submitDisabled={pristine}
                  submitButtonTitle="Update token royalties"
                  formId="token-royalty-form"
                  isValid={() => valid}
                  onSubmit={handleSubmit}
                  alignSubmitButton="left"
                >
                  <div className="grid grid-cols-2 gap-x-4 mb-6 mt-2">
                    <div>
                      <FormLabel>
                        <div>Token primary royalty receiver *</div>
                        <Input validate={addressValidator} name="primary.receiver" required />
                      </FormLabel>
                    </div>
                    <div>
                      <FormLabel>
                        <div>Token primary royalty fraction *</div>
                        <NumberInput
                          parse={parse}
                          format={format}
                          validate={fractionValidator}
                          name="primary.fraction"
                          inputMode="numeric"
                        />
                      </FormLabel>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4 mb-6 mt-2">
                    <div>
                      <FormLabel>
                        <div>Token royalty receiver *</div>
                        <Input validate={addressValidator} name="default.receiver" required />
                      </FormLabel>
                    </div>
                    <div>
                      <FormLabel>
                        <div>Token royalty fraction *</div>
                        <NumberInput
                          parse={parse}
                          format={format}
                          name="default.fraction"
                          validate={fractionValidator}
                          inputMode="numeric"
                        />
                      </FormLabel>
                    </div>
                  </div>
                </SimpleForm>
              )}
            </Form>
          </div>
        </div>
      </Container>
    </PageLayout>
  );
};
