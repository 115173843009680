/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

type Props = {
  title: string;
  children: ReactNode;
};

const useStyles = makeStyles(() => ({
  pageHeader: {
    fontSize: '2.25rem',
    fontWeight: 600,
  },
  container: {
    marginTop: '2rem',
  },
}));

export const PageLayoutV2 = ({ title, children }: Props): ReactElement => {
  const styles = useStyles();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  return (
    <Box sx={{ padding: '0 24px', marginTop: 3 }}>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        <RouterLink to={putUrlParam(routes[RoutesEnum.LANDING].url, {}, activeTenant)}>
          <span className="text-blue-500 underline-offset-4 hover:underline">Freeport</span>
        </RouterLink>
        ,<span>{title}</span>
      </Breadcrumbs>
      <Container classes={{ root: styles.container }} maxWidth="md">
        <Typography classes={{ root: styles.pageHeader }} variant="h1">
          {title}
        </Typography>
        {children}
      </Container>
    </Box>
  );
};
