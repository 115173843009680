import { update } from 'jdenticon';
import { useEffect, useRef } from 'react';

const isNode = Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';

type Props = {
  value: string;
  size?: string | number;
};

export const Identicon = ({ value, size = '100%' }: Props) => {
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    // Skip node.js
    if (!isNode) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      update(ref.current!, value);
    }
  }, [value]);

  return <svg height={size} ref={ref} width={size} />;
};
