import { Box, FormLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { useField } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { MintedNftSelectFormFieldTypes } from '~/components/common/form/select/minted-nft-select-form-field/minted-nft-select-form-field.types';
import { Condition, ConditionsList } from '~/components/conditions';
import { CustomSelectFormFiled } from '~/components/form/fields/select/custom-select-form-filed';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { shortenNftId } from '~/lib/utils';
import { NftPreview } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-second-step/components';
import { WalletType } from '~/routes/wallet/types/wallet-type';
import { getMintedTokens } from '~/services/assets.service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Nft } from '~/types/nft';

const fetcher = async (address: string, walletType: WalletType): Promise<Nft[]> => {
  if (walletType === WalletType.MINTED) {
    return getMintedTokens(address);
  }

  throw new Error(`WalletType "${walletType}" is not supported`);
};

export function MintedNftSelectFormField({ name = 'nftId', label, ...props }: MintedNftSelectFormFieldTypes) {
  const history = useHistory();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();
  const address = useUserPublicKey();
  const { data: mintedNfts, isLoading } = useSWR<Nft[], Error>({ walletType: WalletType.MINTED }, ({ walletType }) =>
    fetcher(address, walletType),
  );

  const options = useMemo(
    () =>
      mintedNfts
        ? mintedNfts.map(({ nftId }) => ({
            value: nftId,
            label: shortenNftId(nftId),
          }))
        : [],
    [mintedNfts],
  );

  const {
    input: { value },
  } = useField<string | undefined>(name);

  return (
    <div className="grid grid-cols-[auto_300px] gap-x-6">
      <div>
        <CustomSelectFormFiled
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          name={name}
          options={options}
          label={label || 'Choose your minted NFT to reward gamers'}
          isLoading={isLoading}
        />
        <Box className="mt-11">
          <FormLabel>
            <Box className="text-white mb-3">Dont’ have minted NFT yet?</Box>
            <button
              className="py-2.5 rounded-full font-bold text-white/80 bg-[#ECF0FF33] w-60 justify-center disabled:opacity-70 disabled:pointer-events-none"
              type="button"
              onClick={() => history.push(putUrlParam(routes[RoutesEnum.MINT].url, {}, activeTenant))}
            >
              Mint NFT
            </button>
          </FormLabel>
        </Box>
      </div>
      <ConditionsList>
        <Condition condition={Boolean(value) && value !== ''}>
          <NftPreview nftId={value as string} />
        </Condition>
        <Condition condition={value !== '' || !value}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '35px',
              backgroundColor: '#191E31',
              border: '2px dashed rgba(255, 255, 255, 0.1)',
              borderRadius: '12px',
              color: 'rgba(255, 255, 255, 0.5)',
              fontWeight: 600,
              maxWidth: 300,
              height: 160,
            }}
          >
            Reward preview
          </Box>
        </Condition>
      </ConditionsList>
    </div>
  );
}
