import { Box, Skeleton } from '@mui/material';
import React from 'react';

import { FormSection } from '~/components/common/form-section';

export function GameSkeleton() {
  return (
    <>
      <Skeleton animation="wave" className="rounded-xl mb-6" variant="rectangular" width={224} height={40} />
      <FormSection>
        <Box>
          <Box className="text-white">Game Title *</Box>
          <Skeleton animation="wave" className="rounded-xl mb-3" variant="rectangular" height={40} />
        </Box>
        <Box sx={{ mt: 4, alignItems: 'center' }}>
          <Box sx={{ display: 'inline-block', mb: 1 }}>Game files *</Box>
          <Skeleton animation="wave" className="rounded-xl mb-3" variant="rectangular" height={127} />
          <Skeleton animation="wave" className="rounded-xl mb-8" variant="rectangular" height={93} />
          <Skeleton animation="wave" className="rounded-xl" variant="rectangular" height={56} />
        </Box>
      </FormSection>
    </>
  );
}
