import { ApplicationEnum, createFreeportCollection, getMarketplaceAddress } from '@cere/freeport-sc-sdk';
import { providers, utils } from 'ethers';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { assertOk } from '~/lib/assert-ok';

type Params = {
  signer: providers.JsonRpcSigner;
  collectionAddress: string;
  logger?: (message: string) => unknown;
};

export async function marketplaceGrantApproval({
  signer,
  collectionAddress,
  logger = () => null,
}: Params): Promise<string> {
  assertOk(utils.isAddress(collectionAddress), 'Invalid collection address');
  const collection = createFreeportCollection({ signer, contractAddress: collectionAddress });

  const marketplaceAddress = await getMarketplaceAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );
  // grant approval for NFT tokens for marketplace
  const tx = await collection.setApprovalForAll(marketplaceAddress, true);
  await tx.wait();
  logger('setApprovalForAll');
  return tx.hash;
}
