import { createContext } from 'react';

import { WizardContextType } from '~/components/common/wizard/wizard.types';

export const WizardContext = createContext<WizardContextType>({
  step: 0,
  isFirstStep: false,
  isLastStep: false,
  completed: false,
  loading: false,
  complete: () => undefined,
  goToNextStep: () => undefined,
  goToPrevStep: () => undefined,
});
