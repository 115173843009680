import { Button } from '@mui/material';
import classnames from 'classnames';

import { DdcContentEntity, retryAssetProcessing } from '~/services/assets.service';

interface AssetProcessingItemProps {
  asset: DdcContentEntity;
  refetch: () => Promise<void>;
}

const ErrorTag = ({ error }: { error?: string }) => (
  <span
    className={classnames('bg-red-500', 'inline-block', 'px-2', 'py-[1px]', 'text-white', 'rounded', 'text-[10px]')}
  >
    {error || 'Error'}
  </span>
);

const ProcessingTag = ({ progress }: { progress?: string }) => (
  <span className={classnames('bg-black', 'inline-block', 'px-2', 'py-[1px]', 'text-white', 'rounded', 'text-[10px]')}>
    {progress || 'Processing...'}
  </span>
);

export const AssetProcessingItem = ({ asset, refetch }: AssetProcessingItemProps) => {
  const isError = !!asset.error;
  const isAwaitingCns = !!asset.assetCid;

  const onRetry = async () => {
    await retryAssetProcessing(asset.id);
    await refetch();
  };

  return (
    <div className="flex flex-row items-center w-full p-2 text-white bg-red-400 rounded-lg gap-2">
      <img className="w-10" src={asset.previewUrl} alt={asset.previewCid} />
      {asset.name}
      {isError ? (
        <ErrorTag error={asset.error} />
      ) : (
        <ProcessingTag progress={isAwaitingCns ? 'updating metadata' : asset.progress} />
      )}
      <div className="flex-grow" />
      {isError && (
        <Button
          onClick={onRetry}
          size="small"
          variant="contained"
          sx={{ color: 'black', _hover: { backgroundColor: 'red' } }}
        >
          Retry
        </Button>
      )}
    </div>
  );
};
