import { createFreeportCollection } from '@cere/freeport-sc-sdk';
import { BigNumberish } from 'ethers';

import { verifyGuard } from '~/lib/verify-guard';
import { getSigner } from '~/services/get-signer';
import { isBigNumberish } from '~/types/numbers';

type Signer = Awaited<ReturnType<typeof getSigner>>;

type Params = {
  collectionAddress: string;
  supply: number;
  cid: string;
};

export async function mintNft(
  minter: Signer,
  { collectionAddress, supply, cid }: Params,
): Promise<[string, BigNumberish]> {
  const minterAddress = await minter.getAddress();
  const collection = createFreeportCollection({ signer: minter, contractAddress: collectionAddress });
  const minterRole = await collection.minterRole();
  let tx = await collection.grantRole(minterRole, minterAddress);
  await tx.wait();
  tx = await collection['mint(address,address,uint256,bytes,bytes)'](
    minterAddress,
    minterAddress,
    supply,
    new Uint8Array(Buffer.from(cid)),
    new Uint8Array(),
  );
  const txReceipt = await tx.wait();
  const nftEventArgs = txReceipt.events?.find(({ event }) => event === 'TransferSingle')?.args;
  return [tx.hash, verifyGuard(nftEventArgs?.[3], isBigNumberish)];
}
