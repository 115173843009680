import { Card, List, ListItem } from '@mui/material';
import { isPast } from 'date-fns';
import { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { timestampToDate } from '~/lib/date';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Auction } from '~/types/auction';

type Props = { auction: Auction; raised?: boolean };

export const AuctionCard = ({ auction, raised }: Props) => {
  const { auctionId, amount, highestBid, endDate, resolved } = auction;
  const { nft } = useContext(ItemLayoutContext);
  const {
    nftId,
    collection: { address: collectionAddress },
  } = nft;
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const auctionStatus: 'OPEN' | 'CLOSED' | 'SETTLED' | undefined = useMemo(() => {
    if (!auction) {
      return undefined;
    }
    if (!auction.resolved && isPast(auction.endDate)) {
      return 'CLOSED';
    }
    if (auction.resolved && isPast(auction.endDate)) {
      return 'SETTLED';
    }
    return 'OPEN';
  }, [auction]);

  return (
    <NavLink
      to={putUrlParam(
        routes[RoutesEnum.NFT_AUCTION].url,
        {
          collection: collectionAddress,
          nftId: nftId.toString(),
          auctionId,
        },
        activeTenant,
      )}
    >
      <Card raised={raised}>
        <List>
          <ListItem>Status: {auctionStatus}</ListItem>
          <ListItem>Amount: {amount}</ListItem>
          <ListItem>Highest Bid: {highestBid}</ListItem>
          <ListItem>Resolved: {resolved ? 'yes' : 'no'}</ListItem>
          <ListItem>Ends at: {timestampToDate(endDate)}</ListItem>
        </List>
      </Card>
    </NavLink>
  );
};
