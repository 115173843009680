import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { StepsProgress } from '~/components/common/wizard/components/steps-progress';
import { WizardStep } from '~/components/common/wizard/components/wizard-step';
import { WizardPropsType } from '~/components/common/wizard/wizard.types';

import { WizardContext } from './wizard.context';

export function Wizard({ children, onComplete, titleArr, isLoading, currentStep }: WizardPropsType) {
  const [step, setStep] = useState(currentStep || 0);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const stepsChildren = useMemo(
    () => React.Children.toArray(children).filter((child) => (child as ReactElement)?.type === WizardStep),
    [children],
  );

  const steps = useMemo(
    () =>
      stepsChildren.map((_, stepNumber) => ({
        step: stepNumber,
      })),
    [stepsChildren],
  );

  const goToNextStep = useCallback(async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else if (onComplete) {
      setLoading(true);
      await onComplete();
      setLoading(false);
    }
  }, [onComplete, step, steps]);

  const goToPrevStep = useCallback(() => {
    if (step > 0) {
      setStep(step - 1);
    }
  }, [step]);

  const complete = useCallback(() => setCompleted(true), []);

  return (
    <WizardContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        step,
        completed,
        goToNextStep,
        goToPrevStep,
        complete,
        loading,
        isFirstStep: step === 0,
        isLastStep: step === steps.length - 1,
      }}
    >
      <StepsProgress steps={steps} titleArr={!isLoading ? titleArr : undefined} />
      {stepsChildren[step]}
    </WizardContext.Provider>
  );
}
