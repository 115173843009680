import { ItemLayout } from '~/components/layout/item-layout';
import { AuctionList } from '~/routes/auctions/auction-list';

import { NftNavigation } from './nft-navigation';

export const NftAuctions = () => (
  <ItemLayout navigation={NftNavigation}>
    <AuctionList />
  </ItemLayout>
);
