import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';

import { Condition, ConditionsList } from '~/components/conditions';
import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { TOURNAMENT_DATE_FORMAT_WITH_DOTS } from '~/constants/date';
import { useTournamentRewardQuery } from '~/hooks/tournaments/useTournamentRewardQuery';
import { shortenHexAddress } from '~/lib/utils';
import { RewardType } from '~/routes/gaming/gaming.types';
import { GameSkeleton } from '~/routes/gaming/gaming-home-route/components/game-skeleton';

type Order = 'asc' | 'desc';

export function TournamentRewardsTable({ id }: { id: number }) {
  const [order, setOrder] = useState<Order>('asc');
  const { data, isLoading } = useTournamentRewardQuery(id);

  const createSortHandler = () => {
    const isAsc = order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const rewards = useMemo(() => {
    const sorted = data?.sort(({ type: typeA }, { type: typeB }) => {
      if (typeA < typeB) return -1;
      if (typeA > typeB) return 1;
      return 0;
    });
    return order === 'asc' ? sorted : sorted?.reverse();
  }, [data, order]);

  return (
    <ConditionsList>
      <Condition condition={!rewards || rewards.length === 0}>There are no awards for this tournament yet</Condition>
      <Condition condition={isLoading}>
        <GameSkeleton />
      </Condition>
      <Condition condition={!!rewards && rewards.length > 0}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Public Key</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Token</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">TxHash</TableCell>
                <TableCell align="right">
                  <TableSortLabel direction={order} onClick={createSortHandler}>
                    Type{' '}
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewards?.map((reward) => (
                <TableRow key={reward.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {shortenHexAddress(reward.publicKey)} <CopyToClipboard value={reward.publicKey} />
                  </TableCell>
                  <TableCell align="right">{reward.amount}</TableCell>
                  <TableCell align="right">{reward.type === RewardType.TOURNAMENT ? 'USDT' : 'CERE'}</TableCell>
                  <TableCell align="right">
                    {format(new Date(reward.updatedAt), TOURNAMENT_DATE_FORMAT_WITH_DOTS)}
                  </TableCell>
                  <TableCell align="right">
                    {reward?.txHash && (
                      <>
                        {shortenHexAddress(reward.txHash)}
                        <CopyToClipboard value={reward.txHash} />
                      </>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {reward.type === RewardType.TOURNAMENT ? 'Tournament' : 'Twitter'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Condition>
    </ConditionsList>
  );
}
