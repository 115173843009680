import { AnalyticEventName, AnalyticServiceInterface, ProviderInterface } from './types';

export class AnalyticService implements AnalyticServiceInterface {
  constructor(private readonly providers: ProviderInterface[]) {
    for (const provider of providers) {
      provider.init();
    }
  }

  track(event: AnalyticEventName, payload: Record<string, string | number | boolean> = {}): void {
    for (const provider of this.providers) {
      provider.track(event, payload);
    }
  }
}
