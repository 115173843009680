import { CircularProgress } from '@mui/material';
import { BigNumber, constants } from 'ethers';
import { FORM_ERROR, FormApi } from 'final-form';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import useSWR from 'swr/immutable';

import { addressValidator, royaltyValidator } from '~/components/form/fields/validators';
import { DEFAULT_ROYALTY_RECEIVER } from '~/constants/env';
import {
  getCollectionRoyalties,
  setCollectionPrimaryRoyalty,
  setCollectionRoyalty,
} from '~/services/royalties-service';
import { Collection } from '~/types/collection';

type Props = {
  collection: Collection;
};

const fetcher = ([, collectionAddress]: [string, string]) => getCollectionRoyalties(collectionAddress);

type RoyaltyForm = {
  defaultPrimaryRoyalty: {
    address: string;
    fraction: number;
  };
  defaultRoyalty: {
    address: string;
    fraction: number;
  };
};

export function CollectionRoyaltyForm({ collection }: Props) {
  const { data, isLoading, mutate } = useSWR(['src/services/collections-service.ts', collection.address], fetcher);

  const initialValues: RoyaltyForm = useMemo(
    () => ({
      defaultPrimaryRoyalty: {
        address: data?.defaultPrimaryRoyalty?.[0] ?? '',
        fraction:
          data?.defaultPrimaryRoyalty?.[1] != null ? BigNumber.from(data.defaultPrimaryRoyalty[1]).toNumber() / 100 : 0,
      },
      defaultRoyalty: {
        address: data?.defaultRoyalty?.[0] ?? '',
        fraction: data?.defaultRoyalty?.[1] != null ? BigNumber.from(data.defaultRoyalty[1]).toNumber() / 100 : 0,
      },
    }),
    [data],
  );

  const submit = async (values: RoyaltyForm, api: FormApi<RoyaltyForm, any>) => {
    const state = api.getState();
    try {
      if (state.dirtyFields['defaultPrimaryRoyalty.address'] || state.dirtyFields['defaultPrimaryRoyalty.fraction']) {
        await setCollectionPrimaryRoyalty({
          collectionAddress: collection.address,
          receiver: values.defaultPrimaryRoyalty.address,
          fraction: values.defaultPrimaryRoyalty.fraction * 100,
        });
      }
      if (state.dirtyFields['defaultRoyalty.address'] || state.dirtyFields['defaultRoyalty.fraction']) {
        await setCollectionRoyalty({
          collectionAddress: collection.address,
          receiver: values.defaultRoyalty.address,
          fraction: values.defaultRoyalty.fraction * 100,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return { [FORM_ERROR]: 'Error while updating royalties' };
    }
    await mutate();
    return null;
  };

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, form }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-8 px-6">
            <label className="grid grid-cols-[180px_1fr] gap-x-2 items-baseline">
              Primary Royalty receiver
              <Field validate={addressValidator} name="defaultPrimaryRoyalty.address">
                {({ input, meta }) => (
                  <>
                    <input
                      className="bg-transparent px-2 py-1 rounded-md border-[1px] text-gray-200 border-gray-400"
                      type="text"
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={input.onChange}
                      name={input.name}
                      value={String(input.value)}
                    />
                    {meta.error && meta.touched && (
                      <div className="pl-[190px] pt-1 col-span-2 text-red-400">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
              <OnChange name="defaultPrimaryRoyalty.address">
                {(value) => {
                  if (value === constants.AddressZero) {
                    form.change('defaultPrimaryRoyalty.address' as any, DEFAULT_ROYALTY_RECEIVER);
                  }
                }}
              </OnChange>
            </label>
            <label className="mt-4 mb-8 grid grid-cols-[180px_1fr] gap-x-2 items-baseline">
              Primary Royalty value
              <Field
                validate={royaltyValidator}
                parse={(val) => Number(val) || 0}
                type="number"
                name="defaultPrimaryRoyalty.fraction"
              >
                {({ input, meta }) => (
                  <>
                    <input
                      type="number"
                      className="bg-transparent w-[70px] px-2 py-1 rounded-md border-[1px] text-gray-200 border-gray-400"
                      min={0}
                      max={99}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={input.onChange}
                      name={input.name}
                      value={String(input.value)}
                    />
                    {meta.error && meta.touched && (
                      <div className="pl-[190px] pt-1 col-span-2 text-red-400">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </label>
            <label className="grid grid-cols-[180px_1fr] gap-x-2 items-baseline">
              Royalty receiver
              <Field validate={addressValidator} name="defaultRoyalty.address">
                {({ input, meta }) => (
                  <>
                    <input
                      className="bg-transparent px-2 py-1 rounded-md border-[1px] text-gray-200 border-gray-400"
                      type="text"
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={input.onChange}
                      name={input.name}
                      value={String(input.value)}
                    />
                    {meta.error && meta.touched && (
                      <div className="pl-[190px] pt-1 col-span-2 text-red-400">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
              <OnChange name="defaultRoyalty.address">
                {(value) => {
                  if (value === constants.AddressZero) {
                    form.change('defaultRoyalty.address' as any, DEFAULT_ROYALTY_RECEIVER);
                  }
                }}
              </OnChange>
            </label>
            <label className="mt-4 grid grid-cols-[180px_1fr] gap-x-2 items-baseline">
              Royalty value
              <Field
                validate={royaltyValidator}
                parse={(val) => Number(val) || 0}
                type="number"
                name="defaultRoyalty.fraction"
              >
                {({ input, meta }) => (
                  <>
                    <input
                      type="number"
                      className="bg-transparent w-[70px] px-2 py-1 rounded-md border-[1px] text-gray-200 border-gray-400"
                      min={0}
                      max={99}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={input.onChange}
                      name={input.name}
                      value={String(input.value)}
                    />
                    {meta.error && meta.touched && (
                      <div className="pl-[190px] pt-1 col-span-2 text-red-400">{meta.error}</div>
                    )}
                  </>
                )}
              </Field>
            </label>
            <div className="grid mt-8 grid-cols-[180px_400px] gap-x-2 items-baseline">
              <div />
              <button
                disabled={isLoading || pristine || submitting}
                type="submit"
                className="disabled:bg-gray-600 bg-blue-800 px-8 flex items-center justify-center gap-x-2 py-1 rounded-lg text-white"
              >
                {submitting && <CircularProgress size={16} thickness={3} color="inherit" />}
                {submitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}
