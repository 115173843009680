import { Container, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { HelpPopover } from '~/components/icons/help-popover';
import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { putUrlParam } from '~/lib/url';
import { shortenHexAddress } from '~/lib/utils';
import { getAccountBenefits } from '~/services/royalties-service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

type BenefitsProps = {
  account: string;
};

const fetcher = ([, address]: [string, string]) => getAccountBenefits(address);

export const Benefits = ({ account }: BenefitsProps) => {
  const { data: benefits, isLoading } = useSWR(['src/services/royalties-service/getAccountBenefits', account], fetcher);
  const tenant = useActiveTenantId();

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, mx: 2 }}>
          <Typography variant="h4">Benefits</Typography>
          <HelpPopover>
            <Typography sx={{ p: 2, maxWidth: 360 }}>
              Overview of royalties paid to your account from NFT sales
            </Typography>
          </HelpPopover>
        </FlexBox>

        {benefits != null && benefits.length > 0 ? (
          <Container>
            <div className="grid gap-y-4 grid-cols-[1fr_1fr_1fr_160px_160px_160px]">
              <div className="pb-2 border-b-2 border-gray-500">NFT</div>
              <div className="pb-2 border-b-2 border-gray-500">Seller</div>
              <div className="pb-2 border-b-2 border-gray-500">Buyer</div>
              <div className="pb-2 border-b-2 border-gray-500 text-right pr-6">Order price</div>
              <div className="pb-2 border-b-2 border-gray-500 text-right pr-6">Your share</div>
              <div className="pb-2 border-b-2 border-gray-500 text-right pr-6">Your income</div>
              {benefits.map((transfer) => (
                <Fragment key={transfer.id}>
                  <div>
                    <Link
                      to={putUrlParam(routes[RoutesEnum.NFT_ASSETS].url, {
                        collection: transfer.collection,
                        tenantId: tenant,
                        nftId: transfer.nftid.toString(),
                      })}
                    >
                      {shortenHexAddress(transfer.collection, 6)}::{transfer.nftid}
                    </Link>
                  </div>
                  <div>
                    {shortenHexAddress(transfer.seller, 6)} <CopyToClipboard value={transfer.seller} />
                  </div>
                  <div>
                    {shortenHexAddress(transfer.buyer, 6)} <CopyToClipboard value={transfer.buyer} />
                  </div>
                  <div className="text-right pr-6">
                    {transfer.price.toFixed(2)} <span className="text-gray-400">USDC</span>
                  </div>
                  <div className="text-right pr-6">
                    {transfer.share.toFixed(2)} <span className="text-gray-400">%</span>
                  </div>
                  <div className="text-right pr-6">
                    {transfer.royalty.toFixed(2)} <span className="text-gray-400">USDC</span>
                  </div>
                </Fragment>
              ))}
            </div>
          </Container>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
