import { ApplicationEnum, createERC20MockToken, ERC20MockToken as ERC20, getERC20Address } from '@cere/freeport-sc-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let erc20: ERC20 | null = null;

export const getERC20 = async (): Promise<ERC20> => {
  if (erc20) return erc20;

  const signer = await getSigner();
  const contractAddress = await getERC20Address(
    signer.provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );

  erc20 = createERC20MockToken({ signer, contractAddress });

  return erc20;
};

export const isTestERC20 = CONTRACTS_DEPLOYMENT === 'dev' || CONTRACTS_DEPLOYMENT === 'stage';
