import { SxProps } from '@mui/system';
import { Field } from 'react-final-form';

import { TextInput } from '~/components/form/fields/text-input';
import { TextFieldType } from '~/components/form/fields/types';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { SyncFieldValidator } from '~/components/form/fields/validators';

export type Props = {
  readOnly?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  validate?: SyncFieldValidator;
  helperText?: string;
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  required?: boolean;
  type?: TextFieldType;
  className?: string;
  systemProp?: SxProps;
  placeholder?: string;
  format?: (value: any) => any;
  parse?: (value: any) => any;
};

export const Input = ({
  name,
  helperText,
  disabled = false,
  readOnly = false,
  minLength,
  maxLength,
  required = false,
  validate,
  label,
  size = 'medium',
  type = 'text',
  className,
  systemProp,
  placeholder,
  format,
  parse,
}: Props) => {
  const fieldValidator = useTextInputValidator({
    minLength,
    maxLength,
    required,
    validate,
  });

  return (
    <Field format={format} parse={parse} validate={fieldValidator} type={type} name={name}>
      {({ input, meta }) => (
        <TextInput
          helperText={helperText}
          readOnly={readOnly}
          disabled={disabled}
          input={input}
          meta={meta}
          type={type}
          label={label}
          size={size}
          multiline={false}
          className={className}
          systemProp={systemProp}
          placeholder={placeholder}
        />
      )}
    </Field>
  );
};
