import { Box, CircularProgress } from '@mui/material';

export const AppLoader = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress size={40} thickness={3} color="inherit" />
  </Box>
);
