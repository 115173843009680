import { Box } from '@mui/material';

import { Button } from '~/components/common/buttons/button';
import { ErrorBlockPropsType } from '~/components/common/error-block/error-block.types';

export function ErrorBlock({ title = 'Whoops!', content, retry }: ErrorBlockPropsType) {
  return (
    <Box className="flex flex-col items-center justify-center h-[50vh] max-w-[370px] m-auto">
      <div className="font-bold	text-4xl mb-[20px]">{title}</div>
      <div className="text-base	font-normal	mb-[40px] text-center">{content}</div>
      <Button className="min-w-[200px] shadow-3xl" skin="primary" onClick={retry}>
        Try again
      </Button>
    </Box>
  );
}
