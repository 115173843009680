import { Button, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { useContext } from 'react';

import { FlexBox } from '~/components/layout/flex-box';
import { AppContext } from '~/context/app-context';
import { WalletNetworkType } from '~/context/types';
import { useWalletCredentials } from '~/lib/hooks';
import { shortenHexAddress } from '~/lib/utils';

export const WalletCredentialsModal = () => {
  const { network } = useContext(AppContext);
  const { isAuthenticated, walletAddress, isLoading, error, authenticate, cancel } = useWalletCredentials();

  const isOpen = !!walletAddress && !isAuthenticated && network === WalletNetworkType.EIP155;

  return (
    <Dialog maxWidth="lg" open={isOpen}>
      <DialogContent sx={{ maxWidth: '400px' }}>
        <FlexBox sx={{ flexDirection: 'column' }}>
          <Typography variant="h5">Sign Message to Continue</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{shortenHexAddress(walletAddress || '')}</Typography>
          <Typography>
            To finish connecting, you must sign a message with your wallet to verify that you are the owner of this
            account. Click cancel to disconnect and change wallets.
          </Typography>
          {isLoading ? (
            <>
              <CircularProgress />
              <Typography>Check your wallet</Typography>
            </>
          ) : (
            <>
              <Button onClick={authenticate} variant="outlined">
                Send Message
              </Button>
              <Typography sx={{ color: 'red' }}>{error}</Typography>
              <Button onClick={cancel}>Cancel</Button>
            </>
          )}
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
};
