import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContextType, WalletNetworkType } from '~/context/types';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { AnalyticService } from '~/services/analytics/analitycs-service';
import { SegmentProvider } from '~/services/analytics/segment-provider';
import { USER_NETWORK, USER_PUB_KEY } from '~/services/constants';
import { getTenantServicePropertyByKey, saveTenantParams, setActiveTenant } from '~/services/tenant.service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { EthNetworkService } from '~/services/v2/eth-network.service';
import { NetworkServiceInterface } from '~/services/v2/interface/network.service.interface';

import { AppContext } from './app-context';

const emptyUserPubKey = '';

type Props = {
  children: ReactNode;
};

export const AppProvider = ({ children }: Props) => {
  // wallets

  const [userPubKey, setUserPubKey] = useState<string>(
    (window.localStorage.getItem(USER_PUB_KEY) ?? emptyUserPubKey)?.toLowerCase(),
  );
  const [network, setNetwork] = useState<string>(WalletNetworkType.EIP155);
  const [networkDriver, setNetworkDriver] = useState<NetworkServiceInterface | null>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const activeTenant = useActiveTenantId();
  const history = useHistory();
  const { putDefaultTenantUrlParam } = useUrlParams();
  const defaultTenantHome = putDefaultTenantUrlParam(routes[RoutesEnum.HOME].url);

  const loadTenantConfig = useCallback(async () => {
    try {
      const [freeportApiUrl, ddcApiUrl, appServiceFee, serviceAccount, smartContractApp] = await Promise.all(
        ['freeportApiUrl', 'ddcApiUrl', 'appServiceFee', 'serviceAccount', 'smartContractApp'].map(async (item) =>
          getTenantServicePropertyByKey<string>(activeTenant, item),
        ),
      );

      saveTenantParams({
        freeportApiUrl,
        ddcApiUrl,
        appServiceFee: Number(appServiceFee) || 0,
        serviceAccount,
        smartContractApp,
      });
      setActiveTenant(activeTenant);
    } catch (error) {
      console.error(error);
      history.push(defaultTenantHome);
    } finally {
      setIsLoading(false);
    }
  }, [activeTenant, defaultTenantHome, history]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void loadTenantConfig();
  }, [loadTenantConfig]);

  const disconnectWallet = useCallback(() => {
    setUserPubKey('');
  }, []);

  const connectWallet = useCallback(
    (newUserPubKey: string) => {
      if (!newUserPubKey) {
        window.localStorage.removeItem(USER_PUB_KEY);
        setUserPubKey('');
      } else {
        const pubKey = newUserPubKey?.toLowerCase();
        window.localStorage.setItem(USER_PUB_KEY, pubKey);
        setUserPubKey(pubKey);
      }
    },
    [setUserPubKey],
  );

  const saveNetworkDriver = useCallback(
    (net: WalletNetworkType) => {
      if (!net) {
        window.localStorage.removeItem(USER_NETWORK);
        setNetwork('');
      } else {
        window.localStorage.setItem(USER_NETWORK, net);
        setNetwork(net);
      }
    },
    [setNetwork],
  );

  useEffect(() => {
    if (network === WalletNetworkType.EIP155) {
      setNetworkDriver(new EthNetworkService());
    }
  }, [network]);

  const context: AppContextType = useMemo(
    () => ({
      userPubKey,
      network,
      changeNetworkDriver: saveNetworkDriver,
      isLoading,
      driver: networkDriver,
      analyticsService: new AnalyticService([new SegmentProvider()]),
      disconnectWallet,
      connectWallet,
    }),
    [userPubKey, saveNetworkDriver, isLoading, networkDriver, network, disconnectWallet, connectWallet],
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
