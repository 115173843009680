import { Error } from '~/components/common/error';
import { SubmissionErrorType } from '~/types/utils';

export type SubmissionErrorPropsType = {
  error?: SubmissionErrorType;
  className?: string;
};

export function SubmissionError({ className, error }: SubmissionErrorPropsType) {
  if (!error) {
    return null;
  }

  let errorMessage;

  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error && typeof error === 'object' && 'response' in error) {
    const responseData = error.response?.data;
    if (responseData && typeof responseData === 'object' && 'errors' in responseData) {
      const { errors } = responseData;

      errorMessage = errors?.map(({ path, message }) => `${path || ''}: ${message || ''}`).join(', ');
    } else {
      errorMessage = responseData?.message;
    }
  } else if ('message' in error) {
    errorMessage = error.message;
  } else {
    errorMessage = error;
  }

  return <Error error={errorMessage} className={className} theme="BLOCK" />;
}
