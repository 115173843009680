import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useSettings } from './useSettings';
import { createCustomTheme } from '~/theme';
import { ReactNode } from 'react';
import { SettingsProvider } from './SettingsContext';
import { StyledEngineProvider } from '@mui/material';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { settings } = useSettings();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <SettingsProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </SettingsProvider>
    </StyledEngineProvider>
  );
};
