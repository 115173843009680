import { useEffect } from 'react';
import useSWR from 'swr/immutable';

import { fetcher } from '~/constants/fetcher';
import { Tournament } from '~/routes/gaming/gaming.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

export function useTournamentsListQuery(gameId?: string) {
  const { data, isLoading, error, mutate } = useSWR<Tournament[], Error>(
    gameId ? { url: TournamentsApiEndpoints.allTournamentsUrl(gameId) } : null,
    fetcher,
  );

  useEffect(() => {
    void mutate();
  }, [mutate]);

  return { data: data as Tournament[], isLoading, error, mutate };
}
