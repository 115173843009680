import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';

import { GamesApiEndpoints } from '~/services/games';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}

export function useGameEditMutation({ id, enable = false }: { id: string; enable: boolean }) {
  const { mutate } = useSWRConfig();
  const [isGameEditing, setEditing] = useState(false);
  const [isGameEdited, setEdited] = useState(false);

  const editGameMutation = useCallback(
    async ({
      file,
      title,
      signature,
      address,
      code,
    }: {
      title: string;
      file?: File;
      signature: string;
      address: string;
      code: string;
    }) => {
      if (!enable) {
        return;
      }
      setEditing(true);
      const form = new FormData();
      if (file) {
        form.append('file', file);
      }
      form.append('title', title);
      form.append('code', code);
      try {
        const response = await mutate(
          GamesApiEndpoints.gameUrl(id),
          fetcher(GamesApiEndpoints.gameUrl(id), {
            method: 'PUT',
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': title,
            },
            data: form,
          }),
        ).then((res) => res);
        if (response) {
          setEditing(false);
          setEdited(true);
        }
      } catch (e) {
        setEditing(false);
      } finally {
        setEditing(false);
      }
    },
    [enable, id, mutate],
  );

  return { editGameMutation, isGameEditing, isGameEdited };
}
