import { ApplicationEnum, createFToken, FToken, getFTokenAddress } from '@cere/freeport-sc-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

let fToken: FToken | null = null;

export const getFToken = async (): Promise<FToken> => {
  if (fToken) return fToken;

  const signer = await getSigner();
  const contractAddress = await getFTokenAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );

  fToken = createFToken({ signer, contractAddress });

  return fToken;
};
