import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { KeyedMutator, useSWRConfig } from 'swr';

import { Tournament } from '~/routes/gaming/gaming.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}

export const useTournamentCreateMutation = (mutateListQuery: KeyedMutator<Tournament[]>) => {
  const { mutate } = useSWRConfig();
  const [isTournamentUploading, setUploading] = useState(false);
  const [isTournamentUploaded, setTournamentUploaded] = useState(false);
  const createTournament = useCallback(
    async ({
      gameId,
      title,
      subtitle,
      startDate,
      endDate,
      mainImage,
      images,
      address,
      twitterHashTag,
      signature,
    }: {
      gameId: number;
      title: string;
      subtitle?: string;
      startDate?: string;
      endDate?: string;
      mainImage?: File | undefined;
      images?: File[] | undefined;
      address: string;
      twitterHashTag: string;
      signature: string;
    }) => {
      setUploading(true);
      const form = new FormData();
      form.append('gameId', String(gameId));
      form.append('title', title);

      if (subtitle) {
        form.append('subtitle', subtitle);
      }

      if (startDate) {
        const date = new Date(startDate.split('/').reverse().join('/'));

        form.append('startDate', String(new Date(date.toUTCString())));
      }

      if (endDate) {
        const date = new Date(endDate.split('/').reverse().join('/'));

        form.append('endDate', String(new Date(date.toUTCString())));
      }

      if (twitterHashTag) {
        form.append('twitterHashTag', twitterHashTag);
      }

      if (mainImage && !images?.length) {
        form.append('mainImage', mainImage);
      }

      if (!mainImage && images) {
        images.forEach((item) => {
          form.append(`images`, item);
        });
      }

      try {
        const response = await mutate<AxiosResponse<Tournament>>(
          TournamentsApiEndpoints.tournamentsUrl(),
          fetcher(TournamentsApiEndpoints.tournamentsUrl(), {
            method: 'POST',
            data: form,
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': title,
            },
          }),
        );

        if (response?.status === 201) {
          await mutateListQuery();
          setUploading(false);
          setTournamentUploaded(true);
        }
      } catch (e) {
        setUploading(false);
      } finally {
        setUploading(false);
      }
    },
    [mutate, mutateListQuery],
  );

  return { createTournament, isTournamentUploading, isTournamentUploaded };
};
