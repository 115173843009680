import { forwardRef } from 'react';

import { CustomInput, CustomInputPropsType } from '~/components/form/fields';
import { field } from '~/components/hoc';

import { InputFieldPropsType } from './input-field.types';

export const InputField = forwardRef<HTMLInputElement, InputFieldPropsType>((props, ref) =>
  field<CustomInputPropsType>({
    ...props,
    ref,
  })(CustomInput),
);
