import { REACT_APP_GAMING_API_URL } from '~/constants/env';

const getUrl = (pathname = ''): string => {
  const url = new URL(REACT_APP_GAMING_API_URL);
  url.pathname = pathname;
  return url.href;
};

const ApiEndpoints = {
  /** List of games: Get */
  gamesUrl() {
    return getUrl('/games');
  },
  /** Upload game: Post */
  uploadGameUrl() {
    return `${this.gamesUrl()}/upload-game`;
  },
  /** Game: Get, Put */
  gameUrl(gameId: string) {
    return `${this.gamesUrl()}/${gameId}`;
  },
  /** Update Game srttings: Put */
  gameSettingsUrl(gameId: string) {
    return `${this.gamesUrl()}/${gameId}/game-settings`;
  },
};

export { ApiEndpoints as GamesApiEndpoints };
