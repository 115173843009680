import { forwardRef } from 'react';

import { field } from '~/components/hoc';

import { TextArea, TextAreaPropsType } from '../text-area';
import { TextAreaFieldPropsType } from './text-area-field.types';

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldPropsType>((props, ref) =>
  field<TextAreaPropsType>({
    ...props,
    ref,
  })(TextArea),
);
