import { BigNumberish, utils } from 'ethers';

import { assertOk } from '~/lib/assert-ok';
import { randomSigner } from '~/lib/random-signer';
import { get } from '~/lib/request';
import { createArrayGuard } from '~/lib/type-guards';
import { verifyGuard } from '~/lib/verify-guard';
import { typeGuardMiddleWare } from '~/routes/video/services/shared';
import { getFreeportCollection, getFreeportCollectionForSigner } from '~/services/get-freeport-collection';
import { isRoyalty, Royalty } from '~/types/royalties';
import { isTransferLog, TransferLog } from '~/types/transfer-log';

export async function getCollectionRoyalties(
  collectionAddress: string,
): Promise<{ defaultPrimaryRoyalty: Royalty; defaultRoyalty: Royalty }> {
  const { signer: random } = await randomSigner();
  const collection = await getFreeportCollection(collectionAddress);
  const collectionForRandom = await getFreeportCollectionForSigner(random, collectionAddress);

  return Promise.all([
    collection.getDefaultPrimaryRoyalty().then((response) => verifyGuard(response, isRoyalty)),
    collectionForRandom.getDefaultRoyalty().then((response) => verifyGuard(response, isRoyalty)),
  ]).then(([defaultPrimaryRoyalty, defaultRoyalty]) => ({ defaultPrimaryRoyalty, defaultRoyalty }));
}

export async function getTokenRoyalties(
  collectionAddress: string,
  nftId: BigNumberish,
): Promise<{ tokenPrimaryRoyalty: Royalty; tokenRoyalty: Royalty }> {
  const { signer: random } = await randomSigner();
  const collection = await getFreeportCollection(collectionAddress);
  const collectionForRandom = await getFreeportCollectionForSigner(random, collectionAddress);

  return Promise.all([
    collection.getTokenPrimaryRoyalty(nftId).then((response) => verifyGuard(response, isRoyalty)),
    collectionForRandom.getTokenRoyalty(nftId).then((response) => verifyGuard(response, isRoyalty)),
  ]).then(([tokenPrimaryRoyalty, tokenRoyalty]) => ({ tokenPrimaryRoyalty, tokenRoyalty }));
}

type RoyaltyUpdateParams = {
  collectionAddress: string;
  receiver: string;
  fraction: number;
};

type RoyaltyTokenUpdateParams = {
  nftId: BigNumberish;
} & RoyaltyUpdateParams;

export async function setCollectionPrimaryRoyalty({
  receiver,
  fraction,
  collectionAddress,
}: RoyaltyUpdateParams): Promise<string> {
  const collection = await getFreeportCollection(collectionAddress);
  const tx = await collection.setDefaultPrimaryRoyalty(receiver, fraction);
  await tx.wait();
  return tx.hash;
}

export async function setCollectionRoyalty({
  receiver,
  fraction,
  collectionAddress,
}: RoyaltyUpdateParams): Promise<string> {
  const collection = await getFreeportCollection(collectionAddress);
  const tx = await collection.setDefaultRoyalty(receiver, fraction);
  await tx.wait();
  return tx.hash;
}

export async function setTokenPrimaryRoyalty({
  receiver,
  fraction,
  collectionAddress,
  nftId,
}: RoyaltyTokenUpdateParams): Promise<string> {
  const collection = await getFreeportCollection(collectionAddress);
  const tx = await collection.setTokenPrimaryRoyalty(nftId, receiver, fraction);
  await tx.wait();
  return tx.hash;
}

export async function setTokenRoyalty({
  receiver,
  fraction,
  collectionAddress,
  nftId,
}: RoyaltyTokenUpdateParams): Promise<string> {
  const collection = await getFreeportCollection(collectionAddress);
  const tx = await collection.setTokenRoyalty(nftId, receiver, fraction);
  await tx.wait();
  return tx.hash;
}

type TokenRoyalty = {
  address: string;
  fraction: number;
};

type SetupTokenRoyaltiesParams = {
  tokenPrimaryRoyalty: TokenRoyalty;
  tokenRoyalty: TokenRoyalty;
  collectionAddress: string;
  nftId: BigNumberish;
  logger?: (message: string) => unknown;
};

export async function setupTokenRoyalties({
  tokenPrimaryRoyalty,
  tokenRoyalty,
  nftId,
  collectionAddress,
  logger = () => null,
}: SetupTokenRoyaltiesParams): Promise<void> {
  assertOk(utils.isAddress(collectionAddress), 'Invalid collection address');
  assertOk(utils.isAddress(tokenPrimaryRoyalty.address), 'Invalid token primary royalty receiver address');
  assertOk(utils.isAddress(tokenRoyalty.address), 'Invalid token primary royalty receiver address');
  const percentFactor = 100;

  await setTokenPrimaryRoyalty({
    receiver: tokenPrimaryRoyalty.address,
    fraction: tokenPrimaryRoyalty.fraction * percentFactor,
    nftId,
    collectionAddress,
  });
  logger(`setTokenPrimaryRoyalty value: [${tokenPrimaryRoyalty.fraction}], address: [${tokenPrimaryRoyalty.address}]`);

  await setTokenRoyalty({
    receiver: tokenRoyalty.address,
    fraction: tokenPrimaryRoyalty.fraction * percentFactor,
    nftId,
    collectionAddress,
  });
  logger(`setTokenRoyalty value: [${tokenRoyalty.fraction}], address: [${tokenRoyalty.address}]`);
}

export const getAccountBenefits = async (account: string): Promise<TransferLog[]> =>
  get(`api/transfers/royalties/${account}`)
    .then((r) => r.json())
    .then(typeGuardMiddleWare(createArrayGuard(isTransferLog)));
