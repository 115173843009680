import { ItemLayout } from '~/components/layout/item-layout';
import { Transfer } from '~/routes/wallet/transfer';

import { NftNavigation } from './nft-navigation';

export const NftTransfer = () => (
  <ItemLayout navigation={NftNavigation}>
    <Transfer />
  </ItemLayout>
);
