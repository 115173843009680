import classnames from 'classnames';

import { TournamentStatusPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournaments-list/components/tournament-item/components/tournament-status/tournament-status.types';

import styles from './tournament-status.module.css';

export function TournamentStatus({ isPassed }: TournamentStatusPropsType) {
  return (
    <div className={classnames('flex', 'items-center', 'justify-between', 'gap-x-1.5', 'h-fit', styles.status)}>
      <span
        className={classnames(styles.marker, {
          [styles.success]: !isPassed,
          [styles.default]: isPassed,
        })}
      />
      <span className="font-semibold text-sm">{!isPassed ? 'Active' : 'Past'}</span>
    </div>
  );
}
