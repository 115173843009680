import { useCallback, useEffect, useState } from 'react';

import { Condition } from '~/components/conditions';
import { SubmissionError } from '~/components/core';
import { useUserPublicKey } from '~/context/app-context';
import { useTournamentCompetitorsQuery } from '~/hooks/tournaments/useTournamentCompetitorsQuery';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { GameSkeleton } from '~/routes/gaming/gaming-home-route/components/game-skeleton';
import { getSigner } from '~/services/get-signer';
import { submitTournamentCompetitors } from '~/services/tournaments/tournaments';
import { SubmissionErrorType } from '~/types/utils';

import { CompetitorForm } from './components/competitorForm';
import { CompetitorsType, SubmitFormTypes, TournamentTestType } from './tournament-competitors.types';

const limit = 10;
const offset = 5;

export const TournamentCompetitors = ({ tournamentId, gameCode, accordionStatus }: TournamentTestType) => {
  const address = useUserPublicKey();

  const [page, setPage] = useState(0);
  const [allData, setAllData] = useState<CompetitorsType[]>([]);
  const { data, isLoading, error, count } = useTournamentCompetitorsQuery({
    id: tournamentId,
    accordionStatus,
    offset: page,
    limit,
  });
  const [submissionError, setSubmissionError] = useState<SubmissionErrorType>(null);
  const { showMessage } = useMessages();

  const resetFormError = useCallback(() => {
    setSubmissionError(null);
  }, []);

  const onSubmit = async (values: Record<string, unknown>): Promise<void> => {
    let submitFormArray: SubmitFormTypes[] = [];
    if (!gameCode) {
      return;
    }
    resetFormError();
    for (const [publicKey, value] of Object.entries(values)) {
      submitFormArray = [
        ...submitFormArray,
        {
          publicKey: publicKey.replace(/^./, ''),
          gameCode,
          tournamentId,
          amount: Number(value),
        },
      ];
    }
    try {
      const signer = await getSigner();
      const signature = await signer.signMessage(`${gameCode}_${tournamentId}`);
      await submitTournamentCompetitors(submitFormArray, `${gameCode}_${tournamentId}`, signature, address);
      showMessage(
        `Players with public keys ${submitFormArray
          .map(({ publicKey }) => publicKey)
          .join(' ,')} were successfully rewarded`,
      );
    } catch (e) {
      setSubmissionError(e as SubmissionErrorType);
    }
  };

  useEffect(() => {
    resetFormError();
  }, [data?.length, resetFormError]);

  const setAllDataWithoutDuplicates = (newData: CompetitorsType[]) => {
    const mergedData = [...(data || []), ...newData];
    const uniqueData = mergedData.reduce((accumulator, currentValue) => {
      const existingItem = accumulator.find((item) => item.walletId === currentValue.walletId);
      if (!existingItem) {
        return [...accumulator, currentValue];
      }
      return accumulator;
    }, [] as CompetitorsType[]);
    setAllData(uniqueData);
  };

  useEffect(() => {
    if (data) {
      setAllDataWithoutDuplicates(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOnShowMoreClick = useCallback(() => {
    setPage((prevState) => prevState + offset);
  }, []);

  if (isLoading) {
    return (
      <Condition condition={isLoading}>
        <GameSkeleton />
      </Condition>
    );
  }

  if (error) {
    showMessage(`Error occurred. ${formatError(error)}`, 'error');
  }

  return (
    <>
      <CompetitorForm
        list={allData}
        showButton={Array.isArray(allData) && allData.length < count}
        showMore={handleOnShowMoreClick}
        onSubmit={onSubmit}
        resetFormError={resetFormError}
        loading={isLoading}
      />
      <SubmissionError error={submissionError} />
    </>
  );
};
