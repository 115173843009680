import { utils } from 'ethers';

import { isRecord } from '~/types/is-record';
import { isPositiveNumber } from '~/types/numbers';
import { isNotEmptyString } from '~/types/string';

export type CollectionMain = {
  address: string;
  name: string;
};

export const defaultCollection: CollectionMain = {
  address: '',
  name: '',
};

export type Collection = {
  id: number;
  address: string;
  uri: string;
  name: string;
};

export const isCollection = (val: unknown): val is Collection =>
  isRecord(val) &&
  isPositiveNumber(val.id) &&
  utils.isAddress(String(val.address)) &&
  isNotEmptyString(String(val.uri)) &&
  isNotEmptyString(String(val.name));
