import { ApplicationEnum, createMarketplace, getERC20Address, getMarketplaceAddress } from '@cere/freeport-sc-sdk';
import { BigNumberish } from 'ethers';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { verifyGuard } from '~/lib/verify-guard';
import { getSigner } from '~/services/get-signer';
import { isBigNumberish } from '~/types/numbers';

type Params = {
  collectionAddress: string;
  nftId: BigNumberish;
  minBid: BigNumberish;
  amount: BigNumberish;
  duration: number;
};

export const createAuction = async ({
  collectionAddress,
  nftId,
  minBid,
  duration,
  amount,
}: Params): Promise<[string, BigNumberish]> => {
  const signer = await getSigner();
  const marketplaceAddress = await getMarketplaceAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );

  const erc20Address = await getERC20Address(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION() as ApplicationEnum);
  const marketPlace = createMarketplace({ signer, contractAddress: marketplaceAddress });

  const tx = await marketPlace.startAuction(collectionAddress, nftId, amount, minBid, duration, erc20Address);
  const txReceipt = await tx.wait();
  const startAuctionEvent = txReceipt.events?.find(({ event }) => event === 'StartAuction');
  return [tx.hash, verifyGuard(startAuctionEvent?.args?.[0], isBigNumberish)];
};
