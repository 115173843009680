import { useCallback, useEffect, useState } from 'react';

import { getTenants } from '~/services/tenant.service';

export const useSelectTenant = (): { isLoading: undefined | boolean; tenants: string[] } => {
  const [tenants, setTenants] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const loadTenants = useCallback(async () => {
    try {
      setIsLoading(true);
      setTenants(await getTenants());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void loadTenants();
  }, [loadTenants]);

  return { tenants, isLoading };
};
