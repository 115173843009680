import { useEffect } from 'react';
import useSWR from 'swr';

import { fetcher } from '~/constants/fetcher';
import {
  CompetitorsQuery,
  CompetitorsResponseType,
} from '~/routes/gaming/game-upload-route/components/game-upload-form/components/tournament-competitors/tournament-competitors.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

export function useTournamentCompetitorsQuery({ id, accordionStatus, offset, limit }: CompetitorsQuery) {
  const { data, error, mutate, isLoading } = useSWR<CompetitorsResponseType>(
    id && accordionStatus ? { url: TournamentsApiEndpoints.tournamentId(id, offset, limit) } : null,
    fetcher,
  );

  useEffect(() => {
    void mutate().then((r) => r);
  }, [mutate]);

  return {
    data: data?.result,
    count: data ? data.count : 0,
    isLoading,
    error,
    mutate,
  };
}
