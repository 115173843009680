import { Box } from '@mui/material';
import classnames from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import iconAdd from '~/assets/icons/icon-add.svg';
import { Button } from '~/components/common/buttons/button';
import { ErrorBlock } from '~/components/common/error-block';
import { SectionHeader } from '~/components/common/section-header';
import { Condition, ConditionsList } from '~/components/conditions';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useGamesListQuery } from '~/hooks';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { GameItem } from '~/routes/gaming/gaming-home-route/components/game-item';
import { GameSkeleton } from '~/routes/gaming/gaming-home-route/components/game-skeleton';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import styles from './gaming-home-route.module.css';

export function GamingHomeRoute(): ReactElement {
  const { games, isLoading, error, mutate } = useGamesListQuery();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const uploadUrl = putUrlParam(routes[RoutesEnum.GAME_UPLOAD].url, {}, activeTenant);
  return (
    <>
      <div className="flex gap-x-8 items-center">
        <SectionHeader title="My Games" />
        <Button skin="primary" link={uploadUrl}>
          Add game +
        </Button>
      </div>
      {isLoading ? (
        <GameSkeleton />
      ) : (
        <ConditionsList>
          <Condition condition={Boolean(error)}>
            <ErrorBlock
              content="We are currently experiencing a high load on our demo platform, and we apologize for any inconvenience this may cause."
              retry={mutate}
            />
          </Condition>
          <Condition condition={games ? games?.length > 0 : false}>
            <div className={classnames(styles.list, 'grid', 'gap-3', 'mt-8')}>
              {games?.map((game) => (
                <GameItem key={game.id} game={game} />
              ))}
            </div>
          </Condition>
          <Condition condition={games ? games?.length === 0 : false}>
            <div
              className={classnames(
                styles.preview,
                'w-[320px]',
                'h-[320px]',
                'rounded-lg',
                'box-border',
                'p-6',
                'text-white',
                'relative',
                'mt-8',
              )}
            >
              <h3 className="text-white text-[24px] font-bold mb-3">Upload your game</h3>
              <p>Tap to get started</p>
              <Link
                to={uploadUrl}
                className={classnames('absolute', 'right-8', 'bottom-8', 'custom-select-none', 'transition-colors')}
              >
                <Box component="img" src={iconAdd} sx={{ maxWidth: 64 }} />
              </Link>
            </div>
          </Condition>
        </ConditionsList>
      )}
    </>
  );
}
