import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';

import DDCLogo from '~/assets/ddc-logo.svg?jsx';
import { ConnectWalletButton } from '~/components/auth/connect-wallet-button';
import { HelpPopover } from '~/components/icons/help-popover';
import { ChangeTenantButton } from '~/components/tenant/change-tenant-button';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext, useUserPublicKey } from '~/context/app-context';
import { WalletNetworkType } from '~/context/types';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import { AccountMenu } from './account-menu';
import Bell from './assets/bell-icon.svg?jsx';
import Help from './assets/help-icon.svg?jsx';
import { SearchBox } from './search-box';

export const Header = () => {
  const { network } = useContext(AppContext);
  const userPubKey = useUserPublicKey();
  const { putUrlParam } = useUrlParams();
  const activeTenantId = useActiveTenantId();
  const homeUrl = putUrlParam(routes[RoutesEnum.HOME].url, {}, activeTenantId);

  return (
    <AppBar sx={{ backgroundColor: 'background.paper', zIndex: 1 }}>
      <Box component={Toolbar} sx={{ flexGrow: 1 }}>
        <RouterLink to={homeUrl}>
          <Box className="inline-flex align-middle">
            <DDCLogo width={40} />
          </Box>
        </RouterLink>
        <Typography component="div" variant="body2" className="flex flex-col ml-2">
          <span>Cere Freeport</span>
          <span>NFT Creator Suite</span>
        </Typography>
        <Switch>
          <Route path="/creator">
            <SearchBox />
          </Route>
        </Switch>
        <div className="flex items-center gap-x-6 ml-auto mr-6">
          <Switch>
            <Route path="/" exact>
              <RouterLink
                className="text-white/80 hover:text-white transition-colors"
                to={putUrlParam(routes[RoutesEnum.LANDING].url, {}, activeTenantId)}
              >
                NFT creator suite
              </RouterLink>
              <RouterLink
                className="text-white/80 hover:text-white transition-colors"
                to={putUrlParam(routes[RoutesEnum.GAMES].url, {}, activeTenantId)}
              >
                Gaming portal
              </RouterLink>
            </Route>
          </Switch>
          <a
            target="_blank"
            title="Help"
            rel="noopener noreferrer"
            href="https://github.com/Cerebellum-Network/Cere-Freeport"
            className="no-underline inline-block w-[36px] h-[36px] rounded-full bg-white/10 text-white/80 hover:text-white"
          >
            <Help />
          </a>
          <button
            type="button"
            className="cursor-pointer border-0 w-[36px] h-[36px] rounded-full bg-white/10 text-white/80 hover:text-white"
          >
            <Bell />
          </button>
        </div>
        <div className="mr-3">
          <ChangeTenantButton />
        </div>

        {userPubKey ? (
          <AccountMenu />
        ) : (
          <>
            {network === WalletNetworkType.EIP155 && <ConnectWalletButton variant="outlined" />}
            <HelpPopover sx={{ ml: 1 }}>
              <Typography className="p-4 max-w-[360px]">
                Once you click this button a prompt from your MetaMask wallet should appear.
              </Typography>
            </HelpPopover>
          </>
        )}
      </Box>
    </AppBar>
  );
};
