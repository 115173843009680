import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import { connectionManager } from '~/model/wallet';
import { WalletConnector } from '~/model/wallet/types';
import { clearCachedCredentials } from '~/services/wallet-auth/wallet-auth.service';

export const useWalletConnect = (): ((connector: WalletConnector) => Promise<void>) => {
  const { connectWallet } = useContext(AppContext);
  const { showMessage } = useMessages();
  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const { putActiveTenantUrlParam } = useUrlParams();

  return async (connector: WalletConnector) => {
    try {
      const account = await connectionManager.connect(connector);
      connectWallet(account);
      const mintedUrl = putActiveTenantUrlParam(routes[RoutesEnum.ACCOUNT_MINTED].url, {
        accountId: account,
      });
      history.push(location.state?.from || mintedUrl);
    } catch (error) {
      showMessage(`Failed to connect. ${formatError(error)}`, 'error');
    }
  };
};

export const useWalletDisconnect = (): (() => Promise<void>) => {
  const { disconnectWallet } = useContext(AppContext);
  return async () => {
    await connectionManager.disconnect();
    disconnectWallet();

    clearCachedCredentials();
  };
};
