import { ApplicationEnum, createMarketplace, getMarketplaceAddress } from '@cere/freeport-sc-sdk';
import { BigNumberish } from 'ethers';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { getSigner } from '~/services/get-signer';

export async function resolveAuction(auctionId: BigNumberish) {
  const signer = await getSigner();
  const marketplaceAddress = await getMarketplaceAddress(
    signer.provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );

  const marketplace = createMarketplace({ signer, contractAddress: marketplaceAddress });
  const tx = await marketplace.resolveAuction(auctionId);
  await tx.wait();

  return tx.hash;
}
