import { useParams } from 'react-router-dom';

import { ItemLayout } from '~/components/layout/item-layout';
import { ListJointAccounts } from '~/routes/joint-accounts/list-joint-accounts';

import { AccountNavigation } from './account-navigation';

export const AccountJointList = () => {
  const { accountId } = useParams<{ accountId: string }>();

  return (
    <ItemLayout navigation={AccountNavigation}>
      <ListJointAccounts owner={accountId} />
    </ItemLayout>
  );
};
