import { isRecord } from '~/types/is-record';

import { getUrl, typeGuardMiddleWare, xhrMiddleware } from './shared';

type DdcParams = {
  cdnUrl: string;
  publicBucketId: number;
};

const isDdcParams = (val: unknown): val is DdcParams =>
  isRecord(val) &&
  typeof val.cdnUrl === 'string' &&
  !Number.isNaN(Number(val.publicBucketId)) &&
  Number(val.publicBucketId) > 0;

export const getDdcParamsUrl = (): string => getUrl('api/video/ddc/params');

export const getDdcParams = (url: string): Promise<DdcParams> =>
  fetch(url).then(xhrMiddleware).then(typeGuardMiddleWare(isDdcParams));
