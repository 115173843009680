import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Dialog } from './dialog';

type Props = {
  open: boolean;
  confirm: () => unknown;
  reject: () => unknown;
  title: string;
  confirmButton?: string;
  rejectButton?: string;
  confirmText?: string;
  rejectText?: string;
};

export function Confirmation({
  confirmText = 'to proceed',
  rejectText = 'to cancel',
  open,
  confirm,
  reject,
  title,
  confirmButton = 'Ok',
  rejectButton = 'Cancel',
}: Props) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <span className="text-2xl">{title}</span>
      </DialogTitle>
      <DialogContent>
        <span className="text-lg text-white/70">
          <span>
            Press <b className="text-white">{confirmButton}</b> {confirmText}.
          </span>{' '}
          <span>
            Press <b className="text-white">{rejectButton}</b> {rejectText}.
          </span>
        </span>
      </DialogContent>
      <DialogActions>
        <div className="text-lg flex justify-end items-center gap-x-6 px-6 py-2">
          <button onClick={reject} type="button">
            {rejectButton}
          </button>
          <button onClick={confirm} type="button">
            {confirmButton}
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
