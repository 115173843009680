import { Field } from 'react-final-form';

import { TextInput } from '~/components/form/fields/text-input';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { SyncFieldValidator } from '~/components/form/fields/validators';

type Props = {
  readOnly?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  helperText?: string;
  validate?: SyncFieldValidator;
  label?: string;
  name: string;
  size?: 'small' | 'medium';
  required?: boolean;
  rows?: number;
  placeholder?: string;
};

export const Textarea = ({
  name,
  helperText,
  disabled = false,
  readOnly = false,
  minLength,
  maxLength,
  required = false,
  validate,
  label,
  size = 'medium',
  rows = 3,
  placeholder,
}: Props) => {
  const fieldValidator = useTextInputValidator({
    minLength,
    maxLength,
    required,
    validate,
  });

  return (
    <Field validate={fieldValidator} name={name}>
      {({ input, meta }) => (
        <TextInput
          helperText={helperText}
          input={input}
          meta={meta}
          label={label}
          size={size}
          multiline
          readOnly={readOnly}
          disabled={disabled}
          type="text"
          rows={rows}
          placeholder={placeholder}
        />
      )}
    </Field>
  );
};
