import { Box, Button, Card, CardContent, CircularProgress, Container, Divider, Stack, Typography } from '@mui/material';
import { BigNumber } from 'ethers';
import { FormApi } from 'final-form';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';

import { NumberInput } from '~/components/form/fields';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { formatError, formatNumber } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { isStringEqual } from '~/lib/utils';
import { createOrder } from '~/services/create-order';
import { getFreeportCollection } from '~/services/get-freeport-collection';
import { getSigner } from '~/services/get-signer';

type CreateOrderForm = {
  price: number;
  amount: number;
};

interface OffersProps {
  tokenSymbol: string;
}

export const Offers = ({ tokenSymbol }: OffersProps) => {
  const { userPubKey } = useContext(AppContext);
  const { nft, mutate: updateNft } = useContext(ItemLayoutContext);
  const [nftBalance, setNftBalance] = useState(0);
  const {
    nftId,
    minter: { wallet: minter },
    collection: { address: collectionAddress },
  } = nft;
  const { showMessage } = useMessages();

  const updateNftBalance = useCallback(
    async (address: string, wallet: string) => {
      const collectionContract = await getFreeportCollection(address);
      const balance = await collectionContract.balanceOf(wallet, nftId);
      setNftBalance(balance.toNumber());
    },
    [nftId],
  );

  const submitCreateOrder = useCallback(
    async ({ amount, price }: CreateOrderForm, api: FormApi<CreateOrderForm, CreateOrderForm>) => {
      try {
        const owner = await getSigner();
        await createOrder({
          owner,
          nftId,
          collectionAddress,
          price,
          saleAmount: amount,
        });
        api.reset();
        await updateNft();
      } catch (error) {
        showMessage(`Failed to make offer. ${formatError(error)}`, 'error');
      }
    },
    [collectionAddress, nftId, showMessage, updateNft],
  );

  const processOrder = useCallback(
    async (values: CreateOrderForm, api: FormApi<CreateOrderForm, CreateOrderForm>) => {
      try {
        api.restart();
      } catch (error) {
        showMessage(`Failed to take offer. ${formatError(error)}`, 'error');
      }
    },
    [showMessage],
  );

  useEffect(() => {
    updateNftBalance(collectionAddress, minter).catch((err) => console.error(err));
  }, [collectionAddress, updateNftBalance, minter]);

  return (
    <PageLayout>
      <Container maxWidth="sm" sx={{ mb: 5 }}>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <FlexBox>
            <Typography>Minter: {minter}</Typography>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>The account that issued this NFT</Typography>
            </HelpPopover>
          </FlexBox>
          <Divider />
        </Stack>
        <Card>
          <CardContent>
            <Stack spacing={3}>
              <div className="text-xl">
                <b>Available for sale</b>: {nftBalance}
              </div>
              {nft.orders.length === 0 && <Typography sx={{ opacity: 0.5 }}>No offer yet</Typography>}
              {nft.orders
                .filter((order) => !order.cancelled)
                .map((order) => (
                  <div className="flex items-center gap-x-4" key={BigNumber.from(order.orderId).toString()}>
                    <span>
                      <b>Token price</b>: {formatNumber(BigNumber.from(order.price).div(100).toNumber())} USDC
                    </span>
                    <span>
                      <b>Amount on sale</b>: {BigNumber.from(order.amount).toString()}
                    </span>
                  </div>
                ))}
              {isStringEqual(minter, userPubKey) && nftBalance > 0 && (
                <Form onSubmit={submitCreateOrder} initialValues={{ amount: 0, price: 0 }}>
                  {({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column" spacing={2} alignItems="baseline">
                        <NumberInput required name="amount" label="Amount of tokens for sale" size="small" />
                        <NumberInput required name="price" label={`Price in ${tokenSymbol}`} size="small" />
                        <div className="flex items-center gap-x-6">
                          <Button
                            sx={{
                              minWidth: '180px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            variant="contained"
                            disabled={invalid || submitting}
                            type="submit"
                          >
                            Put for sale
                            {submitting && (
                              <CircularProgress sx={{ marginLeft: 2 }} color="secondary" size={20} thickness={2} />
                            )}
                          </Button>
                          <Box sx={{ position: 'relative', top: '6px' }}>
                            <HelpPopover>
                              <Typography sx={{ m: 2, maxWidth: 400 }}>
                                Make NFTs available for sale for a certain price in USDC per copy. Whenever you choose
                                to make an offer to sell it will apply to all copies. NFTs are available for sale until
                                cancelled. To cancel the sale, make an offer with a price of 0
                              </Typography>
                              <Divider />
                              <Typography sx={{ p: 2, maxWidth: 400 }}>
                                Type a number of {tokenSymbol} without trailing zeros, for one unit of NFT.
                              </Typography>
                            </HelpPopover>
                          </Box>
                        </div>
                      </Stack>
                    </form>
                  )}
                </Form>
              )}
              {userPubKey && !isStringEqual(minter, userPubKey) && nftBalance > 0 && (
                <Form onSubmit={processOrder}>
                  {({ handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="row" spacing={1} alignItems="baseline">
                        <NumberInput required min={1} max={nftBalance} name="amount" label="NFT amount" size="small" />
                        <Button
                          sx={{
                            minWidth: '180px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          variant="contained"
                          disabled={invalid || submitting}
                          color="secondary"
                          type="submit"
                        >
                          Buy
                          {submitting && (
                            <CircularProgress sx={{ marginLeft: 2 }} color="secondary" size={20} thickness={2} />
                          )}
                        </Button>
                        <Box sx={{ position: 'relative', top: '6px' }}>
                          <HelpPopover>
                            <Typography sx={{ p: 2, maxWidth: 360 }}>
                              Accept an offer, paying the price per unit for an amount of NFTs.
                            </Typography>
                            <Typography sx={{ p: 2, maxWidth: 360 }}>
                              Users will probably prefer to buy this NFT from other user-friendly apps powered by
                              Freeport. This will have the same effect as this button.
                            </Typography>
                            <Divider />
                            <Typography sx={{ p: 2, maxWidth: 400 }}>Type: an amount of NFTs to buy</Typography>
                          </HelpPopover>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Form>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </PageLayout>
  );
};
