import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import useSWR from 'swr';

import { TokenValue } from '~/components/layout/token-value';
import { AppContext } from '~/context/app-context';
import { unwrap } from '~/lib/unwrap';
import { getFtokenBalance } from '~/services/user.service';

export const UserBalance = () => {
  const { userPubKey, driver, network } = useContext(AppContext);
  const { data: fTokenBalance, isLoading: fTokenBalanceLoading } = useSWR('get-ftoken-balance', () =>
    getFtokenBalance(userPubKey),
  );

  const {
    data: nativeBalance,
    error,
    isLoading: nativeBalancelanceLoading,
  } = useSWR('get-native-tocken-balance', () => driver?.getBalance());

  return (
    <>
      <div className="grid grid-cols-[max-content_1fr] items-center gap-x-2">
        <div className="text-gray-400">{network}:</div>
        <div className="overflow-hidden overflow-ellipsis">
          {error}
          {nativeBalancelanceLoading && <CircularProgress size={16} />}
          <TokenValue value={nativeBalance?.balance} decimals={unwrap(process.env.REACT_APP_ERC20_DECIMALS)} />
        </div>
      </div>
      <div className="grid grid-cols-[max-content_1fr] items-center gap-x-2">
        <div className="text-gray-400">Pending:</div>
        <div className="overflow-hidden overflow-ellipsis">
          {fTokenBalanceLoading && <CircularProgress size={16} />}
          <TokenValue value={fTokenBalance} decimals={unwrap(process.env.REACT_APP_FTOKEN_DECIMALS)} />
        </div>
      </div>
    </>
  );
};
