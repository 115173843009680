import { forwardRef } from 'react';
import { DropzoneRef } from 'react-dropzone';

import { FileInputFieldPropsType } from '~/components/common/form/file-input-field/file-input-field.types';
import { FileInput } from '~/components/form/fields/file-input/file-input';
import { FileInputPropsType } from '~/components/form/fields/file-input/file-input.types';
import { field } from '~/components/hoc';

export const FileInputField = forwardRef<DropzoneRef, FileInputFieldPropsType>((props, ref) =>
  field<FileInputPropsType>({
    ...props,
    ref,
  })(FileInput),
);
