import { Card, CardContent, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { shortenNftId } from '~/lib/utils';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Nft } from '~/types/nft';

import { useNftMetadata } from './hooks/use-nft-metadata';

type Props = {
  nft: Nft;
};

export const NftCard = ({ nft }: Props) => {
  const {
    nftId,
    supply,
    collection: { address: collectionAddress },
  } = nft;
  const { metadata } = useNftMetadata(collectionAddress, nftId);
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  return (
    <NavLink
      to={putUrlParam(
        routes[RoutesEnum.NFT_ASSETS].url,
        {
          collection: collectionAddress,
          nftId: String(nftId),
        },
        activeTenant,
      )}
    >
      <Card>
        <div className="aspect-square bg-gradient-to-tr from-gray-600 to-gray-800">
          {metadata?.preview && (
            <img className="w-full h-full object-cover" alt={metadata?.name ?? ''} src={metadata?.preview} />
          )}
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {metadata?.name}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {shortenNftId(nftId)}
          </Typography>
          <Stack direction="row">
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              {supply}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </NavLink>
  );
};
