import { hoursToMilliseconds } from 'date-fns';
import { Signer } from 'ethers';
import { getAddress } from 'ethers/lib/utils';

import { getUrl } from '~/routes/video/services/shared';

const WALLET_CREDENTIAL_CACHE_KEY = 'video-service-credentials';

export interface WalletCredentials {
  message: string;
  signature: string;
  address: string;
}
type GetAuthMessageResponse = string;

export const getCachedCredentials = (): WalletCredentials | undefined => {
  const cached = localStorage.getItem(WALLET_CREDENTIAL_CACHE_KEY);
  if (!cached) {
    return undefined;
  }
  return JSON.parse(cached) as WalletCredentials;
};

const setCachedCredentials = (credentials: WalletCredentials): void => {
  localStorage.setItem(WALLET_CREDENTIAL_CACHE_KEY, JSON.stringify(credentials));
};

export const clearCachedCredentials = (): void => {
  localStorage.removeItem(WALLET_CREDENTIAL_CACHE_KEY);
};

/**
 * Generate a set of credentials that can be used to interact with services guarded with a Wallet Auth Guard
 * @param signer The signer to generate credentials for
 * @returns The auth headers required to interact with a service guarded with a Wallet Auth Guard
 */
export const generateWalletCredentials = async (signer: Signer): Promise<WalletCredentials> => {
  const credentialCache = getCachedCredentials();

  const address = await signer.getAddress();
  const url = `${getUrl(`/api/wallet-auth/auth-message`)}?walletPublicKey=${address}`;
  const message: GetAuthMessageResponse = await (await fetch(url)).text();

  if (credentialCache) {
    if (credentialCache.address !== getAddress(address)) {
      // Accounts have changed since the last time credentials were generated
      clearCachedCredentials();
    } else {
      // 1 hour expiry on credentials has passed
      const newTime = message.split(' ').pop();
      const cachedTime = credentialCache.message.split(' ').pop();
      if (Number(newTime) - Number(cachedTime) < hoursToMilliseconds(1)) {
        return credentialCache;
      }
    }
  }

  const signature = await signer.signMessage(message);

  const credentials: WalletCredentials = {
    message,
    signature,
    address: getAddress(address),
  };

  setCachedCredentials(credentials);

  return {
    message,
    signature,
    address: getAddress(address),
  };
};
