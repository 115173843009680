import { Button, Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { putUrlParam } from '~/lib/hooks/use-url-param';
import { NftCard } from '~/routes/wallet/nft-card';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { Nft } from '~/types/nft';

export const ContentList = () => {
  const history = useHistory();
  const activeTenant = useActiveTenantId();
  const [contentList] = useState([]);

  return (
    <>
      <Container>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <Typography variant="h4">Content list</Typography>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>List of content items you have uploaded</Typography>
            </HelpPopover>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(putUrlParam(routes[RoutesEnum.CONTENT_UPLOAD].url, {}, activeTenant))}
            >
              Upload Content
            </Button>
            <HelpPopover>
              <Typography sx={{ p: 2, maxWidth: 360 }}>Upload Nft Content</Typography>
            </HelpPopover>
          </div>
        </div>

        {contentList.length > 0 ? (
          <Grid container spacing={3}>
            {contentList.map((item: Nft) => (
              <Grid key={item.id} item xs={12} sm={6} md={3}>
                <NftCard nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty>Upload your NFT content</Empty>
        )}
      </Container>
    </>
  );
};
