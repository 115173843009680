export enum RoutesEnum {
  CREATE_JOINT_ACCOUNT = 'CREATE_JOINT_ACCOUNT',
  TRANSFER_CERE_TOKENS = 'TRANSFER_CERE_TOKENS',
  MINT = 'MINT',
  LANDING = 'LANDING',
  HOME = 'HOME',
  GAMES = 'GAMES',
  GAME_EDIT = 'GAME_EDIT',
  GAME_UPLOAD = 'GAME_UPLOAD',
  VIDEO = 'VIDEO',
  VIDEO_LIBRARY = 'VIDEO_LIBRARY',
  VIDEO_EDIT = 'VIDEO_EDIT',
  VIDEO_SUBSCRIPTIONS = 'VIDEO_SUBSCRIPTIONS',
  EDIT_VIDEO_SUBSCRIPTIONS = 'EDIT_VIDEO_SUBSCRIPTIONS',
  VIDEO_COLLECTIONS = 'VIDEO_COLLECTIONS',
  VIDEO_EDIT_COLLECTION = 'VIDEO_EDIT_COLLECTION',

  ACCOUNT_COLLECTIONS = 'ACCOUNT_COLLECTIONS',
  ACCOUNT_MINTED = 'ACCOUNT_MINTED',
  ACCOUNT_OWNED = 'ACCOUNT_OWNED',
  ACCOUNT_BENEFITS = 'ACCOUNT_BENEFITS',
  ACCOUNT_JOINT_LIST = 'ACCOUNT_JOINT_LIST',

  NFT_ASSETS = 'NFT_ASSETS',
  NFT_ROYALTIES = 'NFT_ROYALTIES',
  NFT_OFFERS = 'NFT_OFFERS',
  NFT_AUCTIONS = 'NFT_AUCTIONS',
  NFT_AUCTION = 'NFT_AUCTION',
  NFT_TRANSFER = 'NFT_TRANSFER',

  CONTENT_LIST = 'CONTENT_LIST',
  CONTENT_UPLOAD = 'CONTENT_UPLOAD',
}
