import Download from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { useCallback } from 'react';

import { Button } from '~/components/common/buttons/button';
import { TOURNAMENT_DATE_FORMAT_WITH_DOTS } from '~/constants/date';
import { ExportTournamentButtonPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/export-tournament-button/export-tournament-button.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

export function ExportTournamentButton({ tournament }: ExportTournamentButtonPropsType) {
  const handleOnDownloadResults = useCallback(async () => {
    const link = document.createElement('a');
    link.href = TournamentsApiEndpoints.exportTournamentToCsvUrl(tournament.id);
    link.setAttribute(
      'download',
      `tournament-${tournament.title}-${`${format(
        new Date(tournament.startDate),
        TOURNAMENT_DATE_FORMAT_WITH_DOTS,
      )} - ${format(new Date(tournament.endDate), TOURNAMENT_DATE_FORMAT_WITH_DOTS)}`}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [tournament.endDate, tournament.id, tournament.startDate, tournament.title]);

  return (
    <Button
      className="h-fit text-sm font-normal"
      skin="secondary"
      icon={<Download />}
      onClick={handleOnDownloadResults}
    >
      Download results
    </Button>
  );
}
