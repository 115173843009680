import useSWR from 'swr';

import { getDdcParams, getDdcParamsUrl } from '../services/ddc.service';

type DdcParams = Awaited<ReturnType<typeof getDdcParams>>;

export function useDdcParams(): DdcParams | undefined {
  const { data: ddcParams } = useSWR(getDdcParamsUrl(), getDdcParams, {
    revalidateOnFocus: false,
  });
  return ddcParams;
}
