import classnames from 'classnames';

import { FormSectionPropsType } from '~/components/common/form-section/form-section.types';

import styles from './form-section.module.css';

export function FormSection({ title, children, className }: FormSectionPropsType) {
  return (
    <div className={classnames('flex flex-col p-10 gap-8', styles.container, className)}>
      {title && <h3 className="text-2xl font-semibold">{title}</h3>}
      {children}
    </div>
  );
}
