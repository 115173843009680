import { Deployment } from '@cere/freeport-sc-sdk/types';

import { verifyGuard } from '~/lib/verify-guard';
import { getTenantParams } from '~/services/get-tenant-params.service';
import { isAddressString } from '~/types/string';

export const NETWORK_ID = process.env.REACT_APP_NETWORK_ID;
export const CONTRACTS_DEPLOYMENT: Deployment = process.env.REACT_APP_CONTRACTS_DEPLOYMENT as Deployment;
export const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
export const REACT_APP_GAMING_API_URL = String(process.env.REACT_APP_GAMING_API_URL);
export const DEFAULT_ROYALTY_RECEIVER = verifyGuard(process.env.REACT_APP_DEFAULT_ROYALTY_RECEIVER, isAddressString);

export const SERVICE_FEE = (): number => getTenantParams().appServiceFee;
export const SERVICE_ACCOUNT = (): string => getTenantParams().serviceAccount;
export const API_URL = (): string => getTenantParams().freeportApiUrl;
export const MEDIA_API_URL = (): string => getTenantParams().ddcApiUrl;
export const APPLICATION = (): string => getTenantParams().smartContractApp;
