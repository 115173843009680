import { Box, LinearProgress, Paper } from '@mui/material';
import classnames from 'classnames';
import { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DropzoneRef, useDropzone } from 'react-dropzone';

import DeleteIcon from '~/assets/icons/icon-delete.svg?jsx';
import UploadVideoIcon from '~/assets/icons/icon-upload-file.svg?jsx';
import UploadImageIcon from '~/assets/icons/icon-upload-image.svg?jsx';
import { Button } from '~/components/common/buttons/button';
import { Condition, ConditionsList } from '~/components/conditions';
import { FileInputPropsType } from '~/components/form/fields/file-input/file-input.types';
import { ControlContainer } from '~/components/ui';
import { ALLOWED_ATTACH_MIME_TYPES, MAX_ATTACH_FILE_SIZE_MB } from '~/constants/file';
import { usePureCallback } from '~/lib/hooks/use-pure-callback';
import { ImageType } from '~/routes/gaming/gaming.types';

import styles from './file-input.module.css';

function isImageType(val?: File | ImageType): val is ImageType {
  return (val as ImageType).guid !== undefined;
}

function getPreview(preview: File | ImageType | string | undefined): string | undefined {
  if (!preview) {
    return undefined;
  }
  if (typeof preview === 'string') {
    return preview;
  }
  return isImageType(preview) ? preview.path : URL.createObjectURL(preview);
}

export const FileInput = forwardRef<DropzoneRef, FileInputPropsType>(
  (
    {
      accept = ALLOWED_ATTACH_MIME_TYPES,
      maxFileSizeMb = MAX_ATTACH_FILE_SIZE_MB,
      maxFiles = 1,
      type,
      onChange,
      value,
      deletable = true,
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState<string | undefined>(undefined);
    const [file, setFile] = useState<File | undefined>(value);
    const [progress, setProgress] = useState(0);

    const readerRef = useRef<FileReader | undefined>();

    useEffect(() => {
      if (!value) {
        return;
      }
      const previewFromValue = getPreview(value);
      if (previewFromValue) {
        setPreview(previewFromValue);
      }
    }, [value]);

    const onDrop = usePureCallback([onChange], ([change], acceptedFiles: File[]) => {
      acceptedFiles.forEach((f: File) => {
        setPreview(URL.createObjectURL(f));
        const reader = new FileReader();
        if (readerRef.current) {
          readerRef.current?.abort();
        }
        readerRef.current = reader;
        setFile(f);
        reader.onabort = () => {
          setLoading(false);
        };
        reader.onerror = () => {
          setLoading(false);
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            setProgress(Math.floor((event.loaded / event.total) * 100));
          }
        };
        reader.onload = () => {
          if (change) {
            change(f);
          }
          setLoading(false);
        };
        reader.readAsBinaryString(f);
        setLoading(true);
      });
      onChange?.(acceptedFiles[0]);
    });

    const videoClickHandler = (event: MouseEvent) => {
      event.preventDefault();
    };

    const handleOnDelete = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setFile(undefined);
        setPreview(undefined);
        onChange?.(undefined);
      },
      [onChange],
    );

    const deleteIconJsx = (
      <Button
        className="rounded-xl py-[12px] px-[13px]"
        type="button"
        skin="secondary"
        onClick={handleOnDelete}
        icon={<DeleteIcon />}
      />
    );

    const { getInputProps, getRootProps } = useDropzone({
      maxFiles,
      onDrop,
      disabled: loading,
      accept,
      maxSize: maxFileSizeMb,
      noClick: true,
    });

    return (
      <ControlContainer disabled={loading}>
        <Paper
          variant="outlined"
          className={classnames(styles.root, {
            [styles['root-preview']]: preview && file,
          })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps()}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input ref={ref as any} {...getInputProps()} multiple={false} />
          <ConditionsList>
            <Condition condition={!file && !loading && !preview}>
              <ConditionsList>
                <Condition condition={type === 'image'}>
                  <UploadImageIcon />
                </Condition>
                <Condition condition={type !== 'image'}>
                  <UploadVideoIcon />
                </Condition>
              </ConditionsList>
              <h4 className="text-lg mt-2 text-white">
                <ConditionsList>
                  <Condition condition={type === 'zipFile'}>Upload zip file</Condition>
                  <Condition condition={type === 'image'}>Upload image</Condition>
                  <Condition condition={type === 'video'}>
                    Drag and drop a video, or <span className="text-pink-500">Browse</span>
                  </Condition>
                </ConditionsList>
              </h4>
              <p className="text-xs text-[#919EAB]">
                <ConditionsList>
                  <Condition condition={type === 'zipFile'}>Select or drag and drop yor files to upload</Condition>
                  <Condition condition={type !== 'zipFile'}>1024x768 or higher (png, jpg, gif) Max 10MB</Condition>
                </ConditionsList>
              </p>
            </Condition>
            <Condition condition={loading}>
              <ConditionsList>
                <Condition condition={type === 'image'}>
                  <UploadImageIcon />
                </Condition>
                <Condition condition={type !== 'image'}>
                  <UploadVideoIcon />
                </Condition>
              </ConditionsList>
              <h4 className="text-lg mt-2 text-white">Uploading {file?.name}</h4>
              <div className="flex gap-x-6 w-full justify-center items-center">
                <div className="flex flex-col w-full gap-y-2">
                  <LinearProgress className={styles.progress} value={progress} />
                  <p className="text-xs">
                    {progress}% of {file?.size} MB
                  </p>
                </div>
                {deleteIconJsx}
              </div>
            </Condition>
            <Condition condition={Boolean(preview)}>
              <ConditionsList>
                <Condition condition={type === 'image'}>
                  {preview && (
                    <>
                      <img
                        onLoad={() => URL.revokeObjectURL(preview)}
                        className={styles.preview}
                        alt="preview"
                        src={preview}
                      />
                      {deletable && (
                        <Button
                          className={styles.delete}
                          type="button"
                          skin="secondary"
                          onClick={handleOnDelete}
                          icon={<DeleteIcon />}
                        />
                      )}
                    </>
                  )}
                </Condition>
                <Condition condition={type === 'video' && !isImageType(file)}>
                  {preview && file && (
                    <video onClick={videoClickHandler} className={styles.preview} controls>
                      <source type={file.type} src={preview} />
                    </video>
                  )}
                </Condition>
                <Condition condition={type === 'zipFile'}>
                  {preview && file && (
                    <div className="flex justify-between items-center w-[100%] py-[23px] px-[20px]">
                      <Box className="text-white">
                        <h4 className="text-sm">{file.name}</h4>
                        <h5 className="text-xs text-[#FAFAFA]">{(file.size / (1024 * 1024)).toFixed(2)} MB</h5>
                      </Box>
                      {deletable && (
                        <Button type="button" skin="secondary" onClick={handleOnDelete} icon={<DeleteIcon />} />
                      )}
                    </div>
                  )}
                </Condition>
              </ConditionsList>
            </Condition>
          </ConditionsList>
        </Paper>
      </ControlContainer>
    );
  },
);
