import classnames from 'classnames';

import CloseIcon from '../assets/close-icon.svg?jsx';

type Props = {
  onClick: () => unknown;
};

export function CloseButton({ onClick }: Props) {
  return (
    <button
      aria-label="close"
      type="button"
      onClick={onClick}
      className={classnames(
        'absolute',
        'transition-colors',
        'w-8',
        'h-8',
        'flex',
        'justify-center',
        'items-center',
        'rounded-full',
        'bg-white/20',
        'hover:bg-white/10',
        'top-6',
        'right-6',
        'text-white/60',
        'hover:text-white/90',
      )}
    >
      <CloseIcon />
    </button>
  );
}
