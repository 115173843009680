import classnames from 'classnames';
import { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { deferred } from '~/lib/deferred/deferred';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { ItemDeleteButton } from '~/routes/video/components/item-delete-button';
import { ItemEditButton } from '~/routes/video/components/item-edit-button';

import { Confirmation } from './components/confirmation';
import { ItemPreviewButton } from './components/item-preview-button';
import { useDdcIfileUrl } from './hooks/use-ddc-ifile-url';
import { getParentSeasonForSeries, getParentSeasonForSeriesUrl } from './services/collections.service';
import { SeasonWithParent } from './services/types';
import { deleteVideoMetadata } from './services/video.services';
import sharedStyles from './shared.module.css';
import { VideoPortal } from './video-portal';

type Props = {
  id: number;
  title: string;
  bucketId?: number;
  videoCid?: string;
  coverCid: string;
  collections?: SeasonWithParent[];
  refreshList: () => Promise<unknown>;
  encrypted?: boolean;
};

export function VideoItem({
  id,
  title,
  bucketId,
  videoCid,
  coverCid,
  refreshList,
  collections = [],
  encrypted,
}: Props): ReactElement {
  const [open, setOpen] = useState(false);
  const collection: SeasonWithParent | undefined = collections[0];
  const url = collection == null ? null : getParentSeasonForSeriesUrl(collection.id);

  const { data, isLoading } = useSWR(url, getParentSeasonForSeries, {
    revalidateOnFocus: false,
    errorRetryCount: 0,
  });
  const [confirm, setConfirm] = useState(false);
  const ref = useRef(deferred());
  const { putActiveTenantUrlParam } = useUrlParams();
  const editUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_EDIT].url, { videoId: id });

  const coverUrl = useDdcIfileUrl(coverCid);

  const deleteVideo = useCallback(() => {
    ref.current = deferred();
    setConfirm(true);
    return ref.current
      .then(async () => deleteVideoMetadata(id))
      .then(() => refreshList())
      .catch((err) => console.error(err))
      .finally(() => {
        setConfirm(false);
      });
  }, [id, refreshList]);

  const collectionTitle: ReactNode = useMemo(() => {
    if (isLoading) {
      return <span className={classnames(sharedStyles.gradient, 'inline-block', 'h-[1em]', 'w-[100px]')} />;
    }

    if (data == null) {
      return <span className="text-fuchsia-200">Not attached to any collection so far</span>;
    }

    return (
      <span>
        {data?.name}. Season {collection?.name}
      </span>
    );
  }, [collection?.name, data, isLoading]);

  return (
    <>
      <div
        className={classnames(
          'w-full',
          'flex',
          'items-center',
          'gap-x-6',
          'h-[88px]',
          'bg-white/5',
          'box-border p-2 rounded-2xl border-[1px] border-white/10',
        )}
      >
        <button
          type="button"
          onClick={() => setOpen(true)}
          className={classnames('h-full', 'aspect-square', 'rounded-xl', 'overflow-hidden')}
        >
          {coverUrl && <img src={coverUrl} alt={title} className="w-full h-full object-cover" />}
        </button>
        <div>
          <h3 className="text-[20px] font-bold flex items-center gap-x-4">
            {title}
            {encrypted && (
              <span
                className={classnames(
                  'bg-red-500',
                  'inline-block',
                  'px-2',
                  'py-[1px]',
                  'text-white',
                  'rounded',
                  'text-[10px]',
                )}
              >
                Encrypted
              </span>
            )}
            {!videoCid && (
              <span
                className={classnames(
                  'bg-black',
                  'inline-block',
                  'px-2',
                  'py-[1px]',
                  'text-white',
                  'rounded',
                  'text-[10px]',
                )}
              >
                Processing video...
              </span>
            )}
          </h3>
          <h4 className="text-[14px] text-white/50">{collectionTitle}</h4>
        </div>
        <div className="ml-auto mr-4 flex items-center gap-x-4">
          <ItemPreviewButton onClick={() => setOpen(true)} />
          <ItemEditButton url={editUrl} title="Edit video" />
          <ItemDeleteButton label="delete" onClick={deleteVideo} />
        </div>
        {bucketId && videoCid && (
          <VideoPortal bucketId={bucketId} videoCid={videoCid} open={open} onClose={() => setOpen(false)} />
        )}
      </div>
      <Confirmation
        open={confirm}
        confirm={() => ref.current.resolve(null)}
        reject={() => ref.current.reject()}
        title="The deletion of the video is irreversible"
        confirmButton="Delete"
        confirmText="to delete the video"
        rejectText="to cancel deletion"
      />
    </>
  );
}
