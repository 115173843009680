import { useEffect } from 'react';
import useSWR from 'swr/immutable';

import { fetcher } from '~/constants/fetcher';
import { Reward } from '~/routes/gaming/gaming.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

export function useTournamentRewardQuery(id: number) {
  const { data, isLoading, error, mutate } = useSWR<Reward[], Error>(
    id ? { url: TournamentsApiEndpoints.getTournamentRewards(id) } : null,
    fetcher,
  );

  useEffect(() => {
    void mutate().then((r) => r);
  }, [mutate]);

  return { data, isLoading, error, mutate };
}
