import { CircularProgress } from '@mui/material';
import { ReactElement, ReactNode, StrictMode, useContext } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastProvider } from 'react-toast-notifications';

import { AppContext } from '~/context/app-context';
import { ThemeProvider } from '~/theme/theme-provider';

type Props = {
  children: ReactNode;
};

export const AppFrame = ({ children }: Props): ReactElement => {
  const { isLoading } = useContext(AppContext);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <CircularProgress thickness={3} size={32} color="inherit" />
      </div>
    );
  }

  return (
    <StrictMode>
      <HelmetProvider>
        <ToastProvider placement="bottom-left">
          <ThemeProvider>{children}</ThemeProvider>
        </ToastProvider>
      </HelmetProvider>
    </StrictMode>
  );
};
