import { isRecord } from '~/types/is-record';

export type Token = {
  nftId: string;
  minter: string;
  supply: number;
  quantity: number;
  collectionAddress?: string;
  priceInUsdCents?: number;
  priceInCereUnits?: number;
};

export const defaultToken: Token = {
  nftId: '',
  minter: '',
  supply: 0,
  quantity: 0,
  priceInUsdCents: 0,
  priceInCereUnits: 0,
};

export const isToken = (value: unknown): value is Token =>
  isRecord(value) && typeof value.nftId === 'string' && value.nftId !== '';
