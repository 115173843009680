import { Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const PageActions = () => {
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  return (
    <FlexBox>
      <NavLink to={putUrlParam(routes[RoutesEnum.MINT].url, {}, activeTenant)}>
        <Button variant="contained">Mint NFT</Button>
      </NavLink>
      <HelpPopover>
        <Typography sx={{ p: 2, maxWidth: 360 }}>Mint new NFT token</Typography>
      </HelpPopover>
    </FlexBox>
  );
};
