import { BigNumberish } from 'ethers';

import { isRecord } from '~/types/is-record';
import { isBigNumberish, isNumberish, isPositiveNumber } from '~/types/numbers';
import { isAddressString, isDateString } from '~/types/string';

export type TransferLog = {
  seller: string;
  amount: BigNumberish;
  receiver: string;
  created: string;
  price: number;
  nftid: BigNumberish;
  share: number;
  id: number;
  collection: string;
  royalty: number;
  buyer: string;
};

export const isTransferLog = (val: unknown): val is TransferLog =>
  isRecord(val) &&
  isAddressString(val.seller) &&
  isBigNumberish(val.amount) &&
  isAddressString(val.receiver) &&
  isDateString(val.created) &&
  isNumberish(val.price) &&
  isBigNumberish(val.nftid) &&
  isNumberish(val.share) &&
  isPositiveNumber(val.id) &&
  isAddressString(val.collection) &&
  isNumberish(val.royalty) &&
  isAddressString(val.buyer);
