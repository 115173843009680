import classnames from 'classnames';
import { ReactElement } from 'react';
import { matchPath, NavLink, Route, Switch, useLocation } from 'react-router-dom';

import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { GameUploadRoute } from '~/routes/gaming/game-upload-route';
import { GamingHomeRoute } from '~/routes/gaming/gaming-home-route';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import styles from './gaming.module.css';

export function Gaming(): ReactElement {
  const location = useLocation();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();
  const gamesUrl = putUrlParam(routes[RoutesEnum.GAMES].url, {}, activeTenant);
  const match = matchPath(location.pathname, {
    path: routes[RoutesEnum.GAMES].url,
    exact: true,
    strict: false,
  });
  return (
    <div
      className={classnames('flex grow mt-[-24px]', {
        [styles.container]: match,
      })}
    >
      {match && (
        <aside className={classnames(styles['side-menu'])}>
          <ul>
            <li>
              <NavLink exact className={styles.link} activeClassName={classnames(styles.active)} to={gamesUrl}>
                My Games
              </NavLink>
            </li>
          </ul>
        </aside>
      )}
      <PageLayout>
        <div className="container mx-auto pb-12 md:px-[100px] pt-12">
          <Switch>
            <Route exact path={routes[RoutesEnum.GAMES].url}>
              <GamingHomeRoute />
            </Route>
            <Route path={[routes[RoutesEnum.GAME_UPLOAD].url, routes[RoutesEnum.GAME_EDIT].url]}>
              <GameUploadRoute />
            </Route>
          </Switch>
        </div>
      </PageLayout>
    </div>
  );
}
