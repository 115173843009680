import { Backdrop, Box, Dialog, Theme } from '@mui/material';

import { VideoPlayer } from '~/components/video-player-2';

import { getUrl } from './services/shared';
import styles from './video-page.module.css';

type Props = {
  bucketId: number;
  videoCid: string;
  open?: boolean;
  onClose: () => unknown;
};

export const VideoPortal = ({ bucketId, videoCid, open = false, onClose }: Props) => {
  const videoSrc = getUrl(`/api/video/streaming/${bucketId}/${videoCid}`);

  return (
    <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: (theme: Theme) => theme.zIndex.appBar + 1 }} open={open}>
      <Dialog
        hideBackdrop
        open={open}
        classes={{ paper: styles.paper }}
        onClose={onClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <Box className={styles.player}>
          <VideoPlayer src={videoSrc} />
        </Box>
      </Dialog>
    </Backdrop>
  );
};
