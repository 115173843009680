import { SectionHeader } from '~/components/common/section-header';
import { StepperPropsType } from '~/components/common/wizard/components/stepper/stepper.types';

export function Stepper({ steps, titleArr, currentStep, className }: StepperPropsType) {
  const isLastStep = currentStep === steps.length - 1;
  return (
    <div className={className}>
      <div className={`grid grid-cols-[repeat(${steps.length}, minmax(0, 1fr))] gap-2`}>
        {isLastStep ? (
          <div>Last step</div>
        ) : (
          <>
            <div key={currentStep} data-value={currentStep}>
              Step <b>{currentStep + 1}</b> of {steps.length}
            </div>
          </>
        )}
        {titleArr && titleArr?.length > 0 && <SectionHeader className="mb-6" title={titleArr[currentStep]} />}
      </div>
    </div>
  );
}
