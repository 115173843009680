import { CircularProgress, Container, Typography } from '@mui/material';
import { memo, ReactElement, ReactNode } from 'react';

type Props = {
  title?: string;
  children: ReactNode;
  isLoading?: boolean;
};

export const PageLayout = memo(({ title, children, isLoading }: Props): ReactElement => {
  if (isLoading) {
    return (
      <div className="flex min-h-[200px] items-center justify-center">
        <CircularProgress thickness={3} size={32} color="inherit" />
      </div>
    );
  }

  return (
    <>
      {title && (
        <div className="text-center">
          <Container>
            <Typography variant="h1" sx={{ mb: 2 }}>
              {title}
            </Typography>
          </Container>
        </div>
      )}
      {children}
    </>
  );
});
