import { API_URL } from '~/constants/env';

enum RequestMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type DataType = FormData | Record<string, string | number | File> | string;
type HeaderType = Record<string | number | symbol, unknown>;

type RequestOptions = {
  method: RequestMethodEnum;
  data?: DataType;
  headers?: HeaderType;
};

const request = async (url: string, requestOptions: RequestOptions): Promise<Response> => {
  const requestParams = {
    method: requestOptions.method,
  };

  if (requestOptions.data) {
    Object.assign(requestParams, {
      body: requestOptions.data,
    });
  }

  if (requestOptions.headers) {
    Object.assign(requestParams, {
      headers: { ...requestOptions.headers },
    });
  }

  const resource = new URL(API_URL());
  resource.pathname = url;
  const response = await fetch(resource.href, requestParams);

  if (!response.ok) {
    const text = await response.text();

    throw new Error(
      JSON.stringify({
        status: response.status,
        statusText: response.statusText,
        body: text,
      }),
    );
  }

  return response;
};

export const get = async (url: string, headers?: HeaderType): Promise<Response> =>
  request(url, { method: RequestMethodEnum.GET, headers });

export const post = (url: string, requestOptions: Omit<RequestOptions, 'method'> = {}): Promise<Response> =>
  request(url, { ...requestOptions, method: RequestMethodEnum.POST });

export const put = (url: string, requestOptions: Omit<RequestOptions, 'method'> = {}): Promise<Response> =>
  request(url, { ...requestOptions, method: RequestMethodEnum.PUT });
