import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useUserPublicKey } from '~/context/app-context';
import { getOwnCollections } from '~/services/collections-service';

import { CollectionItem } from './collection-item';

const fetcher = ([, address]: [unknown, string]) => getOwnCollections(address);

export function Collections() {
  const { accountId } = useParams<{ accountId: string | undefined }>();
  const userAddress = useUserPublicKey();
  const swrKey =
    accountId?.toLowerCase?.() === userAddress?.toLowerCase()
      ? ['src/services/collections-service.ts/getOwnCollections', userAddress]
      : null;
  const { data: collections } = useSWR(swrKey, fetcher);

  if (collections == null) {
    return <></>;
  }

  return (
    <Container>
      <div className="w-[700px] mx-auto">
        {collections.map((collection) => (
          <CollectionItem collection={collection} key={collection.address} />
        ))}
      </div>
    </Container>
  );
}
