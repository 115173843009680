/* eslint-disable react/jsx-props-no-spreading,no-console,jsx-a11y/media-has-caption */
import classnames from 'classnames';
import { MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Condition, ConditionsList, Defaults } from '~/components/conditions';
import { usePureCallback } from '~/lib/hooks/use-pure-callback';

import uploadStyles from '../edit-video/video.upload.module.css';

type MimeType = 'image/png' | 'image/jpg' | 'image/jpeg' | 'image/svg+xml' | 'video/*';

type Props = {
  preview?: string;
  types?: MimeType[];
  type: 'video' | 'image';
  children: ReactNode;
  classname?: string;
  onChange: (value: File) => unknown;
};

const AcceptMap: Record<Props['type'], MimeType[]> = {
  video: ['video/*'],
  image: ['image/jpeg', 'image/png'],
};

const maxFileSize = 2 ** 32 * 50; // ~50 GB

export function UploadField({
  preview,
  type,
  types,
  children,
  onChange,
  classname = 'aspect-video',
}: Props): ReactElement {
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const readerRef = useRef<FileReader | undefined>();
  const [progress, setProgress] = useState(0);
  const uploaderClassname = classnames(
    uploadStyles.uploader,
    classname,
    'rounded-md',
    'flex',
    'items-center',
    'justify-center',
  );
  const onDrop = usePureCallback([onChange], ([change], acceptedFiles: File[]) => {
    acceptedFiles.forEach((f: File) => {
      setPreviewUrl(URL.createObjectURL(f));
      const reader = new FileReader();
      if (readerRef.current) {
        readerRef.current?.abort();
      }
      readerRef.current = reader;
      setFile(f);
      reader.onabort = () => {
        console.warn('file reading was aborted');
        setLoading(false);
      };
      reader.onerror = () => {
        console.error('file reading has failed');
        setLoading(false);
      };
      reader.onprogress = (event) => {
        setProgress(event.loaded);
      };
      reader.onload = () => {
        change(f);
        setLoading(false);
      };
      reader.readAsBinaryString(f);
      setLoading(true);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    disabled: loading,
    accept: types ?? AcceptMap[type],
    maxSize: maxFileSize,
  });

  const videoClickHandler = (event: MouseEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (preview) {
      setPreviewUrl(preview);
    }
  }, [preview]);

  useEffect(
    () => () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    },
    [previewUrl],
  );

  return (
    <div className={uploaderClassname} {...getRootProps()}>
      <input {...getInputProps()} />
      <ConditionsList>
        <Condition condition={loading}>
          <div className="flex flex-col items-center justify-center select-none">
            {file && <p className="text-[48px] text-white/50">{Math.floor((100 * progress) / file.size)}%</p>}
            <button
              onClick={() => readerRef.current?.abort()}
              type="button"
              className="text-sm hover:text-white transition-colors text-white/70"
            >
              Click to cancel uploading
            </button>
          </div>
        </Condition>
        <Condition condition={previewUrl != null}>
          <ConditionsList>
            <Condition condition={type === 'image'}>
              {previewUrl && (
                <img
                  onLoad={() => URL.revokeObjectURL(previewUrl)}
                  className={uploadStyles.preview}
                  alt="preview"
                  src={previewUrl}
                />
              )}
            </Condition>
            <Condition condition={type === 'video'}>
              {previewUrl && file && (
                <video onClick={videoClickHandler} className={uploadStyles.preview} controls>
                  <source type={file.type} src={previewUrl} />
                </video>
              )}
            </Condition>
          </ConditionsList>
        </Condition>
        <Defaults>{children}</Defaults>
      </ConditionsList>
    </div>
  );
}
