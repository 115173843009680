import * as process from 'process';

import { getSigner } from '~/services/get-signer';
import { generateWalletCredentials } from '~/services/wallet-auth/wallet-auth.service';
import { isPositiveNumber } from '~/types/numbers';
import { isString } from '~/types/string';

import type { AuthParams } from './shared';
import { getAuthHeaders, getUrl, typeGuardMiddleWare, xhrMiddleware } from './shared';
import { isMetadataResponse, isMetadataResponseArray, MetadataResponse } from './types';

type Metadata = {
  collections: number[];
  cover?: File;
  id?: number;
  video?: File;
  videoDescription: string;
  videoTitle: string;
  encrypted?: boolean;
} & AuthParams;

export function saveVideoMetadata({ address, signature, message, ...metadata }: Metadata): Promise<unknown> {
  const url = metadata.id ? getUrl(`api/video/metadata/${metadata.id}`) : getUrl('api/video/metadata');
  const method = metadata.id ? 'PUT' : 'POST';
  const form = new FormData();
  Object.entries(metadata).forEach(([key, value]) => {
    if (value instanceof File) {
      form.append(key, value);
    } else if (value != null && typeof value === 'object') {
      form.append(key, JSON.stringify(value));
    } else if (value != null && typeof value === 'boolean') {
      form.append(key, value ? 'true' : 'false');
    } else if (value != null) {
      form.append(key, value.toString());
    }
  });
  form.append('tenantId', process.env.REACT_APP_VIDEO_TENANT_ID ?? '');

  return fetch(url, {
    method,
    body: form,
    headers: getAuthHeaders({ message, address, signature }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return new Promise((_, reject) => {
      response.text().then(reject, reject);
    });
  });
}

type SearchQuery = {
  search?: string;
  skip?: number;
  take?: number;
  collectionId?: number;
};

export const getVideoMetadataUrl = (params: SearchQuery = {}): string => {
  const url = new URL(getUrl('api/video/metadata'));
  Object.entries(params).forEach(([key, value]) => {
    if (isString(value) && value.trim() === '') {
      return;
    }
    url.searchParams.append(key, value.toString());
  });
  return url.href;
};

export const getVideoMetadataUrlCount = (params: SearchQuery = {}): string => {
  const url = new URL(getVideoMetadataUrl(params));
  url.searchParams.append('count', 'count');
  return url.href;
};

export const getVideoMetadataList = async (url: string): Promise<MetadataResponse[]> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(url, { headers }).then(xhrMiddleware).then(typeGuardMiddleWare(isMetadataResponseArray));
};

export function getVideoMetadataListCount(url: string): Promise<number> {
  return fetch(url)
    .then((response) => xhrMiddleware(response, false))
    .then(Number)
    .then(typeGuardMiddleWare(isPositiveNumber));
}

export const getVideoMetadata = async (url: string, id: number): Promise<MetadataResponse> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));

  return fetch([url, id].join('/'), { headers })
    .then(xhrMiddleware)
    .then((data) => {
      if (isMetadataResponse(data)) {
        return data;
      }
      throw Error(`Unknown response:\n${JSON.stringify(data)}`);
    });
};

export const deleteVideoMetadata = async (id: number): Promise<unknown> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  return fetch(getUrl(`api/video/metadata/${id}`), { method: 'DELETE', headers }).then((response) =>
    xhrMiddleware(response, false),
  );
};
