import EditSharp from '@mui/icons-material/EditSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from '@mui/material';
import { differenceInDays, format, isPast } from 'date-fns';
import pluralize from 'pluralize';
import { ReactNode, SyntheticEvent, useMemo, useState } from 'react';

import { Button } from '~/components/common/buttons/button';
import { TOURNAMENT_DATE_FORMAT_WITH_DOTS } from '~/constants/date';
import { ExportTournamentButton } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/export-tournament-button';
import { TournamentStatus } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournaments-list/components/tournament-item/components';
import { TournamentItemPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step/components/tournaments-list/components/tournament-item/tournament-item.types';
import { TournamentCompetitors } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/tournament-competitors';
import { TournamentRewardsTable } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/tournament-rewards-table';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export function TournamentItem({ tournament, onEditTournament, game }: TournamentItemPropsType) {
  const [tabValue, setTabValue] = useState<number>(0);
  const [accordionStatus, setAccordionStatus] = useState<boolean>(false);
  const daysDiff = useMemo(
    () => differenceInDays(new Date(tournament.endDate), new Date(tournament.startDate)),
    [tournament.endDate, tournament.startDate],
  );

  const handleChangeTabs = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // TODO fix some css issues with accordion
  const isPassed = useMemo(() => isPast(new Date(tournament.endDate)), [tournament.endDate]);
  return (
    <>
      <Accordion sx={{ margin: '0 0 12px 0' }} expanded={accordionStatus}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
                setAccordionStatus((prev) => !prev);
              }}
              style={{ cursor: 'pointer' }}
            />
          }
          sx={{
            background: 'hsla(0,0%,100%,.1)',
            backdropFilter: 'blur(25px)',
            borderRadius: '16px',
            minHeight: '88px',
            marginTop: '36px',
            cursor: 'unset !important',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div className="flex flex-col">
              <h3 className="text-base text-[#FAFAFA] font-medium max-w-[556px]">{tournament.title}</h3>
              <div className="flex gap-[5px] mt-2">
                <span className="text-xs text-[#FAFAFA]">
                  {daysDiff} {pluralize('day', daysDiff)}.
                </span>
                <span className="text-xs text-[#FAFAFA]">
                  {format(new Date(tournament.startDate), TOURNAMENT_DATE_FORMAT_WITH_DOTS)} -{' '}
                  {format(new Date(tournament.endDate), TOURNAMENT_DATE_FORMAT_WITH_DOTS)}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[auto_auto] gap-x-8 items-center">
              <TournamentStatus isPassed={isPassed} />
              {!isPassed ? (
                <Button
                  onClick={() => onEditTournament(tournament)}
                  className="h-fit"
                  skin="secondary"
                  icon={<EditSharp />}
                />
              ) : (
                <ExportTournamentButton tournament={tournament} />
              )}
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Tabs value={tabValue} onChange={handleChangeTabs}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Tab label="Tournament Result" {...a11yProps(0)} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Tab label="History Payment" {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <TournamentCompetitors
              tournamentId={tournament.id}
              gameCode={game?.code}
              accordionStatus={accordionStatus}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <TournamentRewardsTable id={tournament.id} />
          </CustomTabPanel>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
