import { ListItemButton, MenuItem } from '@mui/material';
import classnames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { SectionHeader } from '~/components/common/section-header';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { usePureCallback } from '~/lib/hooks/use-pure-callback';
import { useRouteParam } from '~/lib/hooks/use-route-param';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { EditCollection } from '~/routes/video/collections/edit-collection';
import { getSigner } from '~/services/get-signer';
import { generateWalletCredentials } from '~/services/wallet-auth/wallet-auth.service';

import UploadImageIcon from '../assets/upload-image-icon.svg?jsx';
import UploadVideoIcon from '../assets/upload-video-icon.svg?jsx';
import { Button } from '../components/button';
import { FormField } from '../components/form-field';
import { ReturnBackLink } from '../components/return-back-link';
import styles from '../components/select.module.css';
import { FormSelect } from '../components/select/form-select';
import { UploadField } from '../components/upload-field';
import formGroupStyles from '../form-group.module.css';
import { getVideoSeasons, getVideoSeasonsUrl } from '../services/collections.service';
import { getDdcParams, getDdcParamsUrl } from '../services/ddc.service';
import { buildIFileUrl } from '../services/shared';
import type { MetadataResponse } from '../services/types';
import {
  deleteVideoMetadata,
  getVideoMetadata,
  getVideoMetadataUrl,
  saveVideoMetadata,
} from '../services/video.services';

type FormValues = {
  collections: number;
  cover: File;
  description: string;
  id?: number;
  videoCid?: string;
  coverCid?: string;
  bucketId?: number;
  title: string;
  video: File;
  videoLink?: string;
  encrypted?: boolean;
};

type H = ReturnType<typeof useHistory>;

type DeleteVideoDeps = [boolean, string, MetadataResponse | undefined, H, string];

export function EditVideo(): ReactElement {
  const videoId = useRouteParam('videoId');
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const isCreatePage = videoId === 'add';
  const { data: videoParams } = useSWR(
    isCreatePage ? null : [getVideoMetadataUrl(), videoId],
    ([url, id]: [string, number]) => getVideoMetadata(url, id),
  );
  const { data: ddcParams } = useSWR(getDdcParamsUrl(), getDdcParams, {
    revalidateOnFocus: false,
  });

  const { data: seasons, mutate: mutateSeasons } = useSWR(getVideoSeasonsUrl(), getVideoSeasons);

  const onCreateSeries = useCallback(async () => {
    setOpen(false);
    await mutateSeasons();
  }, [mutateSeasons]);

  const createSeries = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const seasonOptions = useMemo(
    () =>
      seasons?.map((season) => ({
        id: season.id,
        name: [season.parent?.name, `Season ${season.name}`].filter(Boolean).join(' | '),
      })),
    [seasons],
  );

  const coverUrl =
    ddcParams && videoParams
      ? buildIFileUrl(ddcParams.cdnUrl, ddcParams.publicBucketId, videoParams.coverCid)
      : undefined;

  const submitButtonText = isCreatePage ? 'Upload video' : 'Update video';
  const address = useUserPublicKey();
  const history = useHistory();
  const { putActiveTenantUrlParam } = useUrlParams();
  const videoListUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_LIBRARY].url);
  const [status, setStatus] = useState('');

  const saveData = useCallback(
    async (values: FormValues) => {
      setStatus('Preparing...');
      const signer = await getSigner();
      const { message, signature } = await generateWalletCredentials(signer);
      setStatus('Saving...');
      await saveVideoMetadata({
        id: values.id,
        signature,
        message,
        address,
        video: values.video,
        cover: values.cover,
        videoDescription: values.description,
        videoTitle: values.title,
        collections: values.collections > 0 ? [values.collections] : [],
        encrypted: values.encrypted,
      });
    },
    [address],
  );

  const submit = useCallback(
    (values: FormValues) =>
      saveData(values)
        .then(() => {
          history.push(videoListUrl);
          return null;
        })
        .catch(() => ({ [FORM_ERROR]: 'Saving failed. Please try again' })),
    [history, saveData, videoListUrl],
  );

  const deleteVideo = usePureCallback<DeleteVideoDeps, unknown[], unknown>(
    [confirmDelete, address, videoParams, history, videoListUrl],
    async ([confirm, , metadata, routerHistory, url]) => {
      if (confirm && metadata && metadata?.id) {
        deleteVideoMetadata(metadata?.id)
          .then(() => {
            routerHistory.push(url);
          })
          .catch((err) => console.error(err));
      } else {
        setConfirmDelete(true);
      }
    },
  );

  const initialValues = useMemo(
    () => ({
      collections: videoParams?.collections?.[0]?.id ?? 0,
      video: null,
      cover: null,
      videoLink: '',
      title: videoParams?.videoTitle ?? '',
      description: videoParams?.videoDescription ?? '',
      id: videoParams?.id,
      encrypted: true,
    }),
    [videoParams?.collections, videoParams?.id, videoParams?.videoDescription, videoParams?.videoTitle],
  );

  useEffect(() => {
    let timeout = -1;
    if (confirmDelete) {
      timeout = setTimeout(setConfirmDelete, 3000, false);
    }
    return () => clearTimeout(timeout);
  }, [confirmDelete]);

  return (
    <>
      <Form onSubmit={submit} initialValues={initialValues}>
        {({ handleSubmit, pristine, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div className={formGroupStyles.block}>
              <ReturnBackLink url={videoListUrl}>Back to videos</ReturnBackLink>
            </div>
            <SectionHeader className={formGroupStyles.block} title="Upload video" />
            {submitError && !submitting && <div className="text-center text-pink-400">{submitError}</div>}
            <div className={classnames(formGroupStyles.group, formGroupStyles.block)}>
              <div className="grid grid-cols-2 gap-x-8 gap-y-8">
                <Field name="video">
                  {({ input }) => (
                    <UploadField onChange={input.onChange} type="video">
                      <div className="flex flex-col items-center select-none">
                        <UploadVideoIcon />
                        <h4 className="text-lg mt-2 text-white">
                          Drag and drop a video, or <span className="text-pink-500">Browse</span>
                        </h4>
                        <p className="text-sm text-white/50">Any video file format</p>
                      </div>
                    </UploadField>
                  )}
                </Field>

                <Field name="cover">
                  {({ input }) => (
                    <UploadField preview={coverUrl} onChange={input.onChange} type="image">
                      <div className="flex flex-col items-center select-none">
                        <UploadImageIcon />
                        <h4 className="text-lg mt-2 text-white">
                          Drag and drop an image, or <span className="text-pink-500">Browse</span>
                        </h4>
                        <p className="text-sm text-white/50">1024x768 or higher (png, jpg, gif) Max 10mb</p>
                      </div>
                    </UploadField>
                  )}
                </Field>
                <div>
                  <FormField type="url" name="videoLink" placeholder="Or attach video via the link" />
                </div>
                <div className="flex content-center pt-4">
                  <Field name="encrypted" type="checkbox">
                    {({ input: { name, checked, onChange, onFocus, onBlur } }) => (
                      <label className="flex text-lg items-center gap-x-2 relative top-[-10px]">
                        <input
                          name={name}
                          checked={checked}
                          type="checkbox"
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={onFocus}
                        />
                        Encrypt Video Content
                      </label>
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className={classnames(formGroupStyles.group, formGroupStyles.block, 'flex', 'flex-col', 'gap-y-6')}>
              <FormField label="Title" name="title" required placeholder="Title for your video" />
              <FormField
                label="Description"
                name="description"
                type="textarea"
                required
                placeholder="Add short description"
              />
              <Field name="collections">
                {({ input }) => (
                  <FormSelect
                    name={input.name}
                    required
                    label="Collection"
                    value={input.value}
                    onChange={input.onChange}
                  >
                    <ListItemButton
                      onClick={createSeries}
                      classes={{ root: classnames(styles.button, 'bg-white/20') }}
                      component="button"
                    >
                      <span className={styles.text}>Create category</span>
                    </ListItemButton>
                    <MenuItem value={0}>Not selected</MenuItem>
                    {seasonOptions?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </FormSelect>
                )}
              </Field>
            </div>
            <div className={classnames(formGroupStyles.block, 'my-8', 'flex', 'items-center', 'gap-x-16')}>
              <Button submit disabled={submitting || pristine}>
                {submitting ? status : submitButtonText}
              </Button>
              {videoParams?.id != null && (
                <Button onClick={deleteVideo} variant="outlined" submit={false}>
                  {confirmDelete ? 'Do confirm deletion?' : 'Delete'}
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
      {open && <EditCollection open onClose={onCreateSeries} />}
    </>
  );
}
