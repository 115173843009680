import { Box, Divider } from '@mui/material';
import { FC, ReactNode } from 'react';

import { ItemLayoutProvider } from '~/context/item-layout-provider';

import { InnerPageNavigation } from './inner-page-navigation/inner-page-navigation';

type Props = {
  children: ReactNode;
  navigation: FC;
};

export const ItemLayout = ({ children, navigation: Navigation }: Props) => (
  <div>
    <ItemLayoutProvider>
      <InnerPageNavigation />
      <Navigation />
      <Box component={Divider} sx={{ mb: 5 }} />
      {children}
    </ItemLayoutProvider>
  </div>
);
