import { REACT_APP_GAMING_API_URL } from '~/constants/env';

const getUrl = (pathname = ''): string => {
  const url = new URL(REACT_APP_GAMING_API_URL);
  url.pathname = pathname;
  return url.href;
};

const ApiEndpoints = {
  tournamentsUrl() {
    return getUrl('/tournament');
  },
  leaderBoardUrl() {
    return getUrl('/leader-board');
  },
  rewardUrl() {
    return getUrl('/rewards/tournament');
  },
  tournamentUrl(tournamentId: number) {
    return `${this.tournamentsUrl()}/${tournamentId}`;
  },
  tournamentId(id: number, offset: number, limit: number) {
    return `${this.leaderBoardUrl()}/tournament/${id}?offset=${offset}&limit=${limit}`;
  },
  sendReward() {
    return `${this.rewardUrl()}`;
  },
  exportTournamentToCsvUrl(tournamentId: number) {
    return getUrl(`/leader-board/tournament/${tournamentId}/export-csv`);
  },
  allTournamentsUrl(gameCode: string) {
    return `${this.tournamentsUrl()}/all/${gameCode}`;
  },
  getTournamentRewards(id: number) {
    return `${this.rewardUrl()}/${id}`;
  },
};

export { ApiEndpoints as TournamentsApiEndpoints };
