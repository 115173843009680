/* eslint-disable react/jsx-props-no-spreading */
import { CloudUpload } from '@mui/icons-material';
import { Box, Button, Link, List, Typography } from '@mui/material';
import { MouseEvent as ReactMouseEvent } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { FilePreviewTile } from '~/components/form/fields/file-uploader/file-preview-tile';

type ButtonHandler = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = DropzoneOptions & {
  files?: File[];
  helperText?: string;
  onRemove?: (file: File) => unknown;
  onRemoveAll?: ButtonHandler;
  onUpload?: ButtonHandler;
  uploadProgress?: number;
};

export const FileDropzone = (props: Props) => {
  const { files = [], onRemove, helperText, onRemoveAll, onUpload, uploadProgress, ...dropZoneOptions } = props;
  const { maxFiles = 1 } = dropZoneOptions;
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropZoneOptions);

  const filePluralForm = maxFiles === 1 ? 'file' : 'files';
  const restRootProps = getRootProps();

  return (
    <div>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderColor: 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5,
          }),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5,
          },
        }}
        {...restRootProps}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CloudUpload sx={{ fontSize: '4rem' }} />
          <Box sx={{ pl: 2 }}>
            <Typography sx={{ fontSize: '1.125rem' }} color="textPrimary" variant="h5">
              Upload {filePluralForm}
            </Typography>
            <Typography color="textPrimary" variant="body1">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link color="inherit" underline="hover">
                Select
              </Link>{' '}
              or drag and drop your {filePluralForm} to upload
            </Typography>
          </Box>
        </Box>
      </Box>
      {files.length > 0 && (
        <Box sx={{ mt: 2, position: 'relative', zIndex: 10 }}>
          <List>
            {files.map((file) => (
              <FilePreviewTile
                key={file.lastModified}
                file={file}
                onRemove={onRemove}
                uploadProgress={uploadProgress}
              />
            ))}
          </List>
          {(onRemoveAll || onUpload) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              {onRemoveAll && (
                <Button color="primary" onClick={onRemoveAll} size="small" type="button" variant="text">
                  Remove All
                </Button>
              )}
              {onUpload && (
                <Button
                  color="primary"
                  onClick={onUpload}
                  size="small"
                  sx={{ ml: 2 }}
                  type="button"
                  variant="contained"
                >
                  Upload
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      {helperText && (
        <Typography component="div" sx={{ mt: 1.2, textAlign: 'center', fontSize: '0.9rem' }} color="text.secondary">
          {helperText}
        </Typography>
      )}
    </div>
  );
};
