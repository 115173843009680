import classnames from 'classnames';
import { ReactElement, useMemo } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { SectionHeader } from '~/components/common/section-header';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { getSeries, getSeriesUrl } from '~/routes/video/services/collections.service';

import styles from '../video.module.css';
import { CollectionItem } from './collection-item';
import { EditCollectionPage } from './edit-collection-page';

export function Collections(): ReactElement {
  const { putActiveTenantUrlParam } = useUrlParams();
  const { data, mutate } = useSWR(getSeriesUrl(), getSeries);
  const history = useHistory();
  const addCollectionUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_EDIT_COLLECTION].url, {
    collectionId: 'add',
  });
  const collectionsListUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_COLLECTIONS].url);

  const close = () => {
    history.push(collectionsListUrl);
    void mutate();
  };

  const videosCount = useMemo(
    () =>
      data?.reduce((acc: Record<number, number>, collection) => {
        acc[collection.id] = collection.seasons.reduce((c, s) => c + s.videosCount, 0);
        return acc;
      }, {}),
    [data],
  );

  return (
    <>
      <div className="flex gap-x-8 items-center">
        <SectionHeader title="Collections" />
        <Link to={addCollectionUrl} className={classnames(styles['link-button'])}>
          Add collection +
        </Link>
      </div>
      <div className="mt-12 flex flex-col gap-y-6">
        {data?.map((series) => (
          <CollectionItem
            key={series.id}
            id={series.id}
            seasons={series.seasons}
            videos={videosCount?.[series.id] ?? 0}
            name={series.name}
            updateCollections={mutate}
          />
        ))}
      </div>
      <Switch>
        <Route exact path={routes[RoutesEnum.VIDEO_EDIT_COLLECTION].url}>
          <EditCollectionPage onClose={close} open />
        </Route>
      </Switch>
    </>
  );
}
