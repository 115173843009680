import { createFreeportCollection, createMarketplace, getMarketplaceAddress } from '@cere/freeport-sc-sdk';
import { BigNumber, BigNumberish, utils } from 'ethers';

import { verifyGuard } from '~/lib/verify-guard';
import { getSigner } from '~/services/get-signer';
import { isBigNumberish } from '~/types/numbers';

type Auction = [
  BigNumberish, // 0 uint256 id;
  BigNumberish, // 1 uint256 endDate;
  BigNumberish, // 2 uint256 tokenId;
  BigNumberish, // 3 uint256 amount;
  BigNumberish, // 4 uint256 highestBid;
  BigNumberish, // 5 uint256 royaltyFee;
  string, // 6 address collection;
  string, // 7 address paymentToken;
  string, // 8 address bidder;
  string, // 9 address creator;
  string, // 10 address receiver;
];
const isAuction = (val: unknown): val is Auction =>
  Array.isArray(val) &&
  isBigNumberish(val[0]) &&
  isBigNumberish(val[1]) &&
  isBigNumberish(val[2]) &&
  isBigNumberish(val[3]) &&
  isBigNumberish(val[4]) &&
  isBigNumberish(val[5]) &&
  utils.isAddress(val[6]) &&
  utils.isAddress(val[7]) &&
  utils.isAddress(val[8]) &&
  utils.isAddress(val[9]) &&
  utils.isAddress(val[10]);

export const getAuctionInfo = async (auctionId: BigNumberish) => {
  const signer = await getSigner();
  const marketplaceAddress = await getMarketplaceAddress(signer.provider);
  const marketplace = createMarketplace({ signer, contractAddress: marketplaceAddress });
  const auctionInfo = await marketplace.auctions(auctionId);
  const [
    ,
    endDate,
    nftId,
    amount,
    highestBid,
    royaltyFee,
    collectionAddress,
    paymentToken,
    bidder,
    creator,
    royaltyReceiver,
  ] = verifyGuard(auctionInfo, isAuction);
  return {
    endDate,
    nftId,
    amount,
    highestBid,
    royaltyFee,
    collectionAddress,
    paymentToken,
    bidder,
    creator,
    royaltyReceiver,
    summary: BigNumber.from(highestBid).add(royaltyFee),
  };
};

export const calculateBidInfo = async (
  auctionId: BigNumberish,
  bid: BigNumberish,
  collectionAddress: string,
): Promise<{ price: BigNumber; royaltyFee: BigNumber }> => {
  const signer = await getSigner();
  const collection = createFreeportCollection({ signer, contractAddress: collectionAddress });
  const auctionInfo = await getAuctionInfo(auctionId);
  const [, royaltyFee] = await collection.royaltyInfo(auctionInfo.nftId, bid);
  return {
    price: BigNumber.from(bid).mul(auctionInfo.amount),
    royaltyFee: royaltyFee.mul(auctionInfo.amount),
  };
};
