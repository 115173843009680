import { WalletNetworkType } from '~/context/types';
import { post } from '~/lib/request';
import { unwrap } from '~/lib/unwrap';

export async function uploadContent(
  chainNamespace: string,
  chainId: string,
  address: string,
  {
    name,
    assets,
    description,
    collectionAddress,
    previews,
    nftId,
  }: {
    name: string;
    description: string;
    assets?: File[];
    previews?: File[];
    nftId?: string;
    collectionAddress: string;
  },
): Promise<string> {
  const form = new FormData();
  form.append('name', name);
  form.append('description', description);
  form.append('asset', unwrap(assets)[0]);
  form.append('preview', unwrap(previews)[0]);
  form.append('collection', collectionAddress);
  form.append('nftId', chainNamespace === WalletNetworkType.SOLANA ? '0' : nftId);

  return post(`api/wallet/${address}/content`, {
    data: form,
    headers: {
      'Chain-Namespace': chainNamespace,
      'Chain-Id': chainId,
    },
  }).then((r) => r.text());
}
