import { useCallback } from 'react';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';

export const useMessages = (): {
  showMessage: (message: string, appearance?: AppearanceTypes) => void;
} => {
  const { addToast } = useToasts();

  const showMessage = useCallback(
    (message: string, appearance: AppearanceTypes = 'info') => {
      addToast(message, {
        appearance,
        autoDismiss: true,
        placement: 'top-center',
      });
    },
    [addToast],
  );

  return {
    showMessage,
  };
};
