import { Box, Tooltip, Typography } from '@mui/material';
import classnames from 'classnames';
import React from 'react';
import { Form } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { InputFormField } from '~/components/common/form/input-form-field';
import styles from '~/components/common/form-section/form-section.module.css';
import { Condition } from '~/components/conditions';
import { shortenHexAddress } from '~/lib/utils';
import { GameSkeleton } from '~/routes/gaming/gaming-home-route/components/game-skeleton';

import { TournamentListType } from '../../tournament-competitors.types';

export const CompetitorForm = ({
  list,
  showMore,
  onSubmit,
  loading,
  showButton,
  resetFormError,
}: TournamentListType) => (
  <Box>
    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
      Do you want to reward your gamers?
    </Typography>
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {list?.map(({ walletId, score }, index) => (
            <div className={classnames('flex items-center justify-around p-5 gap-4', styles.container)} key={walletId}>
              <Typography>{`${index + 1}.`}</Typography>
              <Tooltip title={walletId}>
                <Typography
                  sx={{
                    marginLeft: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {shortenHexAddress(walletId)}
                </Typography>
              </Tooltip>
              <Typography sx={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>{score}</Typography>
              <Box sx={{ marginLeft: '20px' }}>
                <InputFormField
                  onChange={() => {
                    resetFormError();
                  }}
                  name={`+${walletId}`}
                  placeholder="USDT"
                  type="number"
                />
              </Box>
            </div>
          ))}
          <Condition condition={loading}>
            <GameSkeleton />
          </Condition>
          {showButton && (
            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <Button onClick={showMore} skin="outlined" style={{ border: 'none' }}>
                <Typography variant="h5" align="center" sx={{ textDecoration: 'underline', color: '#e851c3' }}>
                  Show More
                </Typography>
              </Button>
            </Box>
          )}
          <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" skin="primary">
              Send
            </Button>
          </Box>
        </form>
      )}
    </Form>
  </Box>
);
