import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import folderFill from '@iconify/icons-eva/folder-fill';
import imageFill from '@iconify/icons-eva/image-fill';
import imageOutline from '@iconify/icons-eva/image-outline';
import peopleFill from '@iconify/icons-eva/people-fill';
import { Icon, IconifyIcon } from '@iconify/react';
import { useParams } from 'react-router-dom';

import { GenericNavigation } from '~/components/layout/generic-navigation';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const AccountNavigation = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const accountTabs: Array<[RoutesEnum, IconifyIcon]> = [
    [RoutesEnum.ACCOUNT_COLLECTIONS, folderFill],
    [RoutesEnum.ACCOUNT_MINTED, imageOutline],
    [RoutesEnum.ACCOUNT_OWNED, imageFill],
    [RoutesEnum.ACCOUNT_BENEFITS, briefcaseFill],
    [RoutesEnum.ACCOUNT_JOINT_LIST, peopleFill],
  ];

  return (
    <GenericNavigation
      tabs={accountTabs.map(([route, icon]) => ({
        url: putUrlParam(routes[route].url, { accountId }, activeTenant),
        title: routes[route].title ?? '',
        icon: <Icon icon={icon} width={20} height={20} />,
      }))}
    />
  );
};
