import { useMemo } from 'react';

import iconBack from '~/assets/icons/icon-back.svg';
import { BackButtonLinkPropsType } from '~/components/common/buttons/back-button-link/back-button-link.types';
import { Button } from '~/components/common/buttons/button';
import { buildOffsetStyles } from '~/utils/dom';

export function BackButtonLink({ className, offset, to, children }: BackButtonLinkPropsType) {
  const componentStyles = useMemo(() => buildOffsetStyles(offset), [offset]);

  return (
    <Button
      style={componentStyles}
      link={to}
      skin="link"
      icon={<img src={iconBack} alt="back" />}
      className={className}
    >
      {children}
    </Button>
  );
}
