import { useParams } from 'react-router-dom';

import { ItemLayout } from '~/components/layout/item-layout';
import { Wallet } from '~/routes/wallet/wallet';

import { WalletType } from '../wallet/types/wallet-type';
import { AccountNavigation } from './account-navigation';

export const AccountMinted = () => {
  const { accountId } = useParams<{ accountId: string }>();

  return (
    <ItemLayout navigation={AccountNavigation}>
      <Wallet type={WalletType.MINTED} address={accountId} />
    </ItemLayout>
  );
};
