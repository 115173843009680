export enum FileMimeTypes {
  DOCX = '.docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = '.doc, application/msword',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS = 'application/vnd.ms-excel',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT = 'application/vnd.ms-powerpoint',
  PPSX = 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PDF = 'application/pdf',
  HTML = 'text/html',
  TXT = 'text/plain',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  GIF = 'image/gif',
  VCF = 'text/vcard',
  XVCF = 'text/x-vcard',
  CSV = 'text/csv',
  DIRECTORY = 'text/directory',
  VIDEO = 'video/*',
  ZIP = '.zip',
}

export const ALLOWED_ATTACH_MIME_TYPES = [
  FileMimeTypes.JPEG,
  FileMimeTypes.PNG,
  FileMimeTypes.VIDEO,
  FileMimeTypes.ZIP,
].join(', ');

export const ALLOWED_ATTACH_IMAGE_TYPES = [
  FileMimeTypes.JPEG,
  FileMimeTypes.JPG,
  FileMimeTypes.PNG,
  FileMimeTypes.GIF,
].join(', ');

export const MAX_ATTACH_FILE_SIZE_MB = 2 ** 32 * 50; // ~50 GB
