import { CSSProperties } from 'react';

export type OffsetType = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export function buildOffsetStyles(offset?: OffsetType): CSSProperties {
  return { marginTop: offset?.top, marginRight: offset?.right, marginBottom: offset?.bottom, marginLeft: offset?.left };
}
