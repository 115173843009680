import classNames from 'classnames';
import { ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom';

import gamingHomeCard from '~/assets/gaming-home-card.png';
import nftHomeCard from '~/assets/nft-home-card.png';
import SmallArrow from '~/assets/small-arrow.svg?jsx';
import videoHomeCard from '~/assets/video-home-card.png';
import { PageLayout } from '~/components/layout/page-layout';
import { DEFAULT_TENANT } from '~/constants/env';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import styles from './home.module.css';

export function Home(): ReactElement {
  const { putUrlParam } = useUrlParams();
  const activeTenantId = useActiveTenantId();

  if (activeTenantId === '') {
    return <Redirect to={putUrlParam(routes[RoutesEnum.HOME].url, {}, String(DEFAULT_TENANT))} />;
  }

  const creatorUrl = putUrlParam(routes[RoutesEnum.LANDING].url, {}, activeTenantId);
  const videoUrl = putUrlParam(routes[RoutesEnum.VIDEO_LIBRARY].url, {}, activeTenantId);
  const gameUrl = putUrlParam(routes[RoutesEnum.GAMES].url, {}, activeTenantId);

  const buildHeader = (title: string, url: string, bannerUrl: string) => (
    <Link
      to={url}
      className={classNames(
        styles.link,
        'w-[400px]',
        'h-[488px]',
        'transition-colors',
        'text-white',
        'box-border',
        'p-8',
        'border-2',
        'border-white/10',
        'rounded-[20px]',
        'relative',
        'overflow-hidden',
      )}
    >
      <img alt="" aria-hidden src={bannerUrl} className={classNames(styles['image-card'], 'z-0')} />
      <h2 className={classNames('font-bold', 'leading-tight', 'text-[36px]', 'z-1', 'relative', styles.header)}>
        {title}
      </h2>
      <div
        className={classNames(
          styles['enter-button'],
          'font-bold',
          'absolute',
          'left-8',
          'bottom-8',
          'rounded-full',
          'w-[120px]',
          'h-[48px]',
          'flex',
          'z-10',
          'justify-center',
        )}
      >
        <div className={classNames(styles['enter-button-content'])}>Enter</div>
        <span className={styles['enter-button-arrow']}>
          <SmallArrow />
        </span>
      </div>
    </Link>
  );

  return (
    <PageLayout>
      <div className="w-[1280px] mx-auto flex mt-8 justify-between">
        {buildHeader('NFT minter & Creator suite', creatorUrl, nftHomeCard)}
        {buildHeader('Game admin panel', gameUrl, gamingHomeCard)}
        {buildHeader('Video streaming', videoUrl, videoHomeCard)}
      </div>
    </PageLayout>
  );
}
