import classnames from 'classnames';

import styles from './styles.module.css';

type Props = {
  current?: number;
  max: number;
  visibleRange?: number;
  hideEmpty?: boolean;
  onClick?: (val: number) => unknown;
};

export function Paginator({ max, visibleRange = 5, current = 1, hideEmpty = true, onClick = () => null }: Props) {
  const min = 1;
  const includedRange = Array.from(new Array(visibleRange))
    .map((_, i) => i + current)
    .filter((num) => num <= max)
    .filter((num) => num >= min);
  const precedingRange = Array.from(new Array(visibleRange))
    .map((_, i) => current - i - 1)
    .reverse()
    .filter((num) => num <= max)
    .filter((num) => num >= min);
  const range = [...precedingRange, ...includedRange].slice(-1 * visibleRange);
  const minRange = range[0];
  const maxRange = range[visibleRange - 1];
  const itemClasses = classnames(
    'w-[36px]',
    'h-[36px]',
    'border-[1px]',
    'border-white/50',
    'flex',
    'items-center',
    'justify-center',
    'box-border',
    'rounded',
  );
  const separator = <div className="mx-1 text-xl">...</div>;
  const start = (
    <button type="button" className={itemClasses}>
      {min}
    </button>
  );
  const end = (
    <button type="button" className={itemClasses}>
      {max}
    </button>
  );
  if (hideEmpty && max <= min) {
    return null;
  }
  return (
    <div className="flex gap-x-2">
      {!range.includes(min) && (
        <>
          {start}
          {minRange - min > 1 && separator}
        </>
      )}
      {range.map((val) =>
        val === current ? (
          <div className={classnames([styles.active], itemClasses, 'font-bold')} key={val}>
            {val}
          </div>
        ) : (
          <button onClick={() => onClick(val)} key={val} type="button" className={itemClasses}>
            {val}
          </button>
        ),
      )}
      {!range.includes(max) && (
        <>
          {max - maxRange > 1 && separator}
          {end}
        </>
      )}
    </div>
  );
}
