import { TenantParams } from '~/types/tenant-params';

import { TENANT_PARAMS_KEY } from './constants';

let cachedParams: TenantParams;

export const getTenantParams = (): TenantParams => {
  if (cachedParams) {
    return cachedParams;
  }

  const paramsJson = window.localStorage.getItem(TENANT_PARAMS_KEY);
  if (!paramsJson) {
    throw new Error('Tenant params are empty!');
  }

  const params: unknown = JSON.parse(paramsJson);

  cachedParams = params as TenantParams;

  return cachedParams;
};
