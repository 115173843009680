import { createContext, FC, useCallback, useContext, useMemo } from 'react';

import { ProgressUpdateCallback, UploadPayload } from '~/model/uploader/types';
import { Uploader } from '~/model/uploader/uploader';

type FileUploadContextType = {
  upload: (payload: UploadPayload, onProgressUpdate?: ProgressUpdateCallback) => Promise<string>;
};

const FileUploadContext = createContext<FileUploadContextType>({
  upload: async () => '',
});

export const useFileUpload = () => {
  const uploadContext = useContext(FileUploadContext);
  return uploadContext.upload;
};

export const FileUploadProvider: FC<{ uploader: Uploader }> = ({ children, uploader }) => {
  const upload = useCallback(
    async (payload: UploadPayload, onProgressUpdate?: ProgressUpdateCallback) =>
      uploader.upload(payload, onProgressUpdate),
    [uploader],
  );

  const value = useMemo(
    () => ({
      upload,
    }),
    [upload],
  );

  return <FileUploadContext.Provider value={value}>{children}</FileUploadContext.Provider>;
};
