import classnames from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';

import { getSubscriptions, getSubscriptionsUrl } from '../services/subscription.service';
import styles from '../video.module.css';
import { SubscriptionItem } from './subscription-item';

export function Subscriptions(): ReactElement {
  const { putActiveTenantUrlParam } = useUrlParams();
  const address = useUserPublicKey();
  const { data, mutate } = useSWR([getSubscriptionsUrl(), address], getSubscriptions);
  const url = putActiveTenantUrlParam(routes[RoutesEnum.EDIT_VIDEO_SUBSCRIPTIONS].url, {
    subscriptionId: 'add',
  });

  return (
    <>
      <div className="flex gap-x-8 items-center">
        <h1 className={classnames(styles['section-header'], 'text-white')}>Subscriptions</h1>
        <Link to={url} className={classnames(styles['link-button'])}>
          Add subscription +
        </Link>
      </div>
      <section className="mt-16 flex flex-col gap-y-6">
        {data?.map((subscription) => (
          <SubscriptionItem
            refreshList={mutate}
            key={subscription.id}
            id={subscription.id}
            title={subscription.title}
            activated={subscription.status}
            coverCid={subscription.metadata.coverCid}
          />
        ))}
      </section>
    </>
  );
}
