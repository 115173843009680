import { BigNumber, BigNumberish } from 'ethers';

import { get, put } from '~/lib/request';
import { createArrayGuard } from '~/lib/type-guards';
import { getAuthHeaders, getUrl, typeGuardMiddleWare } from '~/routes/video/services/shared';
import { NftAssetUpdate } from '~/routes/wallet/types/nft-asset-update';
import { getFreeportChainHeaders } from '~/services/get-freeport-chain-headers.service';
import { getSigner } from '~/services/get-signer';
import { buildMediaApiUrl } from '~/services/utils';
import { AssetContentType, AssetDdc, isAssetDdc } from '~/types/asset-ddc';
import { DdcUploadResponse, isDdcUploadResponse } from '~/types/ddc';
import { FullNft, isFullNft, isNft, Nft } from '~/types/nft';
import { isToken, Token } from '~/types/token';

import { generateWalletCredentials } from './wallet-auth/wallet-auth.service';

const reportError = (response: Response): Error =>
  new Error(`Server response status code: ${response.status}, ${response.statusText}`);

export const getMintedTokens = async (account: string): Promise<Nft[]> =>
  get(`api/wallet/${account}/minted`, getFreeportChainHeaders())
    .then((r) => r.json())
    .then(typeGuardMiddleWare(createArrayGuard(isNft)));

export const getCollectionNft = async (collectionAddress: string, nftId: BigNumberish): Promise<FullNft> =>
  get(`api/registry/${collectionAddress}/${BigNumber.from(nftId).toString()}`, getFreeportChainHeaders())
    .then((r) => r.json())
    .then(typeGuardMiddleWare(isFullNft));

export const getOwnTokens = async (account: string): Promise<Nft[]> =>
  get(`api/wallet/${account}/owned`, getFreeportChainHeaders())
    .then((r) => r.json())
    .then(typeGuardMiddleWare(createArrayGuard(isNft)));

/**
 * @deprecated
 * @param nftId
 */
export const getNft = async (nftId: string): Promise<Token> => {
  const token: unknown = await get(`/nft/${nftId}`).then((r) => r.json());

  if (!isToken(token)) {
    throw new Error('Not Found!');
  }

  return token;
};

export const getNftAssets = async (collection: string, nftId: BigNumberish): Promise<string[]> => {
  console.log(`Assets for ${collection}:${nftId.toString()}`);
  return [];
};

type UpdateNftParams = {
  address: string;
  collection: string;
  nftId: BigNumberish;
  assets: NftAssetUpdate[];
};

export const putNftAssets = async ({ collection, nftId, assets, address }: UpdateNftParams): Promise<unknown> => {
  const form = new FormData();
  assets.forEach((asset, i) => {
    form.set(`assets[${i}].name`, asset.name);
    form.set(`assets[${i}].description`, asset.description);
    if (asset.assetUrl) {
      form.set(`assets[${i}].assetUrl`, asset.assetUrl);
    }
    if (asset.previewUrl) {
      form.set(`assets[${i}].previewUrl`, asset.previewUrl);
    }
    if (asset.asset) {
      form.set(`assets[${i}].asset`, asset.asset);
    }
    if (asset.preview) {
      form.set(`assets[${i}].preview`, asset.preview);
    }
  });
  return put(`api/wallet/${address}/assets/${collection}/${BigNumber.from(nftId).toString()}`, {
    data: form,
  });
};

export const getAssetFromDdc = async (minter: string, cid: string): Promise<AssetDdc> => {
  const url = buildMediaApiUrl(`assets/v2/${minter}/${cid}`);
  return fetch(url.href)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }

      throw reportError(response);
    })
    .then((response) => {
      if (isAssetDdc(response)) {
        return response;
      }

      throw reportError(response);
    });
};

export const getDdcUploadInfo = async (uploadId: string): Promise<DdcUploadResponse> => {
  const url = buildMediaApiUrl('assets', 'v1', uploadId);
  return fetch(url.href)
    .then(async (response) => {
      if (!response.ok) {
        throw reportError(response);
      }

      return response.json();
    })
    .then((response) => {
      if (!isDdcUploadResponse(response)) {
        throw new Error(JSON.stringify(response));
      }

      return response;
    });
};

type DdcContentProps = {
  minter: string;
  owner: string;
  cid: string;
};

export const getAssetContentFromDdc = async ({ minter, owner, cid }: DdcContentProps): Promise<Blob> => {
  const signer = await getSigner();
  const signature = await signer.signMessage(`${minter}${cid}${owner}`);
  const url = buildMediaApiUrl(`assets/v2/${minter}/${cid}/content`);
  return fetch(url.href, {
    headers: {
      'X-DDC-Signature': signature,
      'X-DDC-Address': owner,
    },
  }).then(async (response) => response.blob());
};

/**
 * Defines the asset to be retrieved
 * - preview: The 'preview' asset in the NFT metadata
 * - asset: The 'encrypted' asset in the NFT metadata
 * - {asset | preview}-{index}: The index of the attached 'asset' in the NFT metadata
 */
export type AssetIdentifier = 'preview' | 'asset' | `preview-${number}` | `asset-${number}`;

export interface TokenAssetDetails {
  index: number;
  nftId: BigNumberish;
  collectionAddress: string;
  contentType?: AssetContentType;
  assetUrl?: string;
}

export const getEncryptedAsset = async ({ index, collectionAddress, nftId }: TokenAssetDetails): Promise<Blob> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  const assetIdentifier = `asset-${index}`;
  const url = getUrl(`/api/content/${collectionAddress}/${nftId.toString()}/${assetIdentifier}`);
  const asset = await fetch(url, { headers: { ...headers, responseType: 'blob' } });
  return asset.blob();
};

export const getEncryptedAssetDek = async (
  collectionAddress: string,
  nftId: string,
  assetId: string,
): Promise<string> => {
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  const url = getUrl(`/api/content/${collectionAddress}/${nftId}/${assetId}/dek`);
  const asset = await fetch(url, { headers });
  return asset.text();
};

export interface DdcContentEntity {
  id: number;
  bucketId: number;
  encrypted: boolean;
  contentType: AssetContentType;
  name: string;
  description: string;
  assetCid?: string;
  previewCid?: string;
  error?: string;
  progress?: string;
  previewUrl?: string;
}

export interface ContentProcessingResponse {
  isProcessing: boolean;
  unprocessedAssets: DdcContentEntity[];
}

export const getAssetProcessing = async (
  collectionAddress: string,
  nftId: number,
): Promise<ContentProcessingResponse> => {
  const url = getUrl(`/api/content/${collectionAddress}/${nftId}/processing`);
  const asset = await fetch(url);
  return (await asset.json()) as ContentProcessingResponse;
};

export const retryAssetProcessing = async (id: number): Promise<void> => {
  const url = getUrl(`/api/content/${id}/retry`);
  const signer = await getSigner();
  const headers = getAuthHeaders(await generateWalletCredentials(signer));
  await fetch(url, { method: 'PUT', headers });
};
