import { BigNumberish } from 'ethers';

import { isOrder, Order } from '~/types/order';

import { Collection, isCollection } from './collection';
import { isRecord } from './is-record';
import { isBigNumberish, isPositiveAndSafeInteger } from './numbers';

export type Nft = {
  id: number;
  nftId: BigNumberish;
  supply: BigNumberish;
  collection: Collection;
};

export const isNft = (val: unknown): val is Nft =>
  isRecord(val) &&
  isPositiveAndSafeInteger(val.id) &&
  isBigNumberish(val.nftId) &&
  isBigNumberish(val.supply) &&
  isCollection(val.collection);

export type FullNft = {
  id: number;
  nftId: BigNumberish;
  supply: BigNumberish;
  collection: Collection;
  orders: Order[];
  auctions: unknown[];
  minter: {
    wallet: string;
  };
};

export const emptyFullNft: FullNft = {
  id: 0,
  nftId: '',
  supply: 0,
  orders: [],
  collection: {
    id: 0,
    address: '',
    name: '',
    uri: '',
  },
  auctions: [],
  minter: {
    wallet: '',
  },
};

export const isFullNft = (val: unknown): val is FullNft =>
  isRecord(val) && Array.isArray(val.orders) && val.orders.every(isOrder) && isNft(val);
