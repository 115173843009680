import { constants } from 'ethers';

import { verifyGuard } from '~/lib/verify-guard';
import { isAddressString } from '~/types/string';

export const addressValidator = (value: unknown): null | string => {
  if (value === constants.AddressZero) {
    return "Zero address isn't allowed";
  }
  try {
    verifyGuard(value, isAddressString);
    return null;
  } catch (e) {
    return "The entered value isn't valid address";
  }
};
