import { Button, CircularProgress, FormLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BigNumber } from 'ethers';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';

import { Input, NumberInput, Textarea } from '~/components/form/fields';
import { CollectionField } from '~/components/form/fields/collection-field';
import { FileDropzoneField } from '~/components/form/fields/file-uploader/file-dropzone-field';
import { addressValidator, royaltyValidator } from '~/components/form/fields/validators';
import { SimpleForm } from '~/components/form/simple-form';
import { PageLayoutV2 } from '~/components/layout/page/page-layout-v2';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { WalletNetworkType } from '~/context/types';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import { FormConfirmationDialog } from '~/routes/mint/form-confirmation-dialog';
import { useActiveTenantId } from '~/services/use-active-tenant-id';
import { defaultCollection } from '~/types/collection';

import { FormValues } from './form-values';

const useStyles = makeStyles(() => ({
  confirmHeader: {
    fontSize: '1.5rem',
    marginTop: '1rem',
  },
  confirmListHeader: {
    fontSize: '1rem',
  },
  confirmListText: {
    fontSize: '1rem',
    margin: '0.3rem 0 2rem',
  },
  confirmationAssets: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, max-content)',
  },
}));

export const Mint = () => {
  const { showMessage } = useMessages();
  const { userPubKey, analyticsService, network, driver } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [created, setCreated] = useState(false);
  const styles = useStyles();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const closeConfirmation = useCallback(() => {
    setOpen(false);
  }, []);

  const askConfirmation = useCallback(() => {
    setOpen(true);
  }, []);

  const submit = useCallback(
    async (values: FormValues) => {
      closeConfirmation();
      try {
        const mintResult = await driver?.mintNft(values);
        analyticsService.track('NFT_CREATED');
        setCreated(true);
        showMessage(`Your NFT ${BigNumber.from(mintResult?.nftId).toString()} has been minted`);
      } catch (err) {
        showMessage(err);
      }
    },
    [analyticsService, closeConfirmation, showMessage, driver],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      name: '',
      description: '',
      supply: 0,
      assets: [],
      previews: [],
      collection: defaultCollection,
      tokenRoyalty: {
        address: userPubKey,
        fraction: 0,
      },
      tokenPrimaryRoyalty: {
        address: userPubKey,
        fraction: 0,
      },
    }),
    [userPubKey],
  );

  if (created) {
    return (
      <Redirect
        to={putUrlParam(
          routes[RoutesEnum.ACCOUNT_MINTED].url,
          {
            accountId: userPubKey,
          },
          activeTenant,
        )}
      />
    );
  }

  return (
    <PageLayoutV2 title="Create new item">
      <div className="my-5">
        <Form initialValues={initialValues} onSubmit={submit}>
          {({ handleSubmit, valid, submitting, pristine, invalid, values }) => (
            <SimpleForm
              formId="create-nft"
              submitDisabled={pristine}
              onSubmit={handleSubmit}
              hideSubmit
              submitButtonTitle="Create"
              isValid={() => valid}
              actionInProgress={submitting}
            >
              <div className="my-6">
                <FormLabel component="div">
                  <div className="inline-block mb-1 ml-1">Image, Video or Audio *</div>
                  <FileDropzoneField
                    required
                    accept="image/*,video/*,audio/*"
                    name="assets"
                    maxFiles={1}
                    helperText="File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
                  />
                </FormLabel>
              </div>
              <div className="my-6">
                <FormLabel>
                  <div className="inline-block mb-1 ml-1">Name *</div>
                  <Input name="name" required />
                </FormLabel>
              </div>
              <div className="my-6">
                <FormLabel>
                  <div className="inline-block mb-1 ml-1">Description *</div>
                  <Textarea
                    helperText="This description will be shown on the item’s detail page underneath the file"
                    name="description"
                    rows={4}
                    required
                  />
                </FormLabel>
              </div>
              <div className="grid gap-x-4 my-6 grid-cols-2">
                <div>
                  <FormLabel>
                    <div>Collection *</div>
                  </FormLabel>
                  <CollectionField />
                </div>
                <div>
                  <FormLabel>
                    <div>Quantity *</div>
                  </FormLabel>
                  <NumberInput
                    helperText="The number of copies to be minted"
                    name="supply"
                    min={1}
                    inputMode="numeric"
                  />
                </div>
              </div>

              {network === WalletNetworkType.EIP155 && (
                <>
                  <div className="my-6">
                    <div className="grid gap-x-4 grid-cols-2">
                      <div>
                        <FormLabel>
                          <div className="inline-block mb-1 ml-1">Primary royalties receiver *</div>
                          <Input validate={addressValidator} name="tokenPrimaryRoyalty.address" required />
                        </FormLabel>
                      </div>
                      <div>
                        <FormLabel>
                          <div className="inline-block mb-1 ml-1">Primary royalties value *</div>
                          <NumberInput
                            validate={royaltyValidator}
                            min={0}
                            max={99}
                            inputMode="numeric"
                            name="tokenPrimaryRoyalty.fraction"
                            required
                          />
                        </FormLabel>
                      </div>
                    </div>
                  </div>
                  <div className="my-6">
                    <div className="grid gap-x-4 grid-cols-2">
                      <div>
                        <FormLabel>
                          <div className="inline-block mb-1 ml-1">Royalties receiver *</div>
                          <Input validate={addressValidator} name="tokenRoyalty.address" required />
                        </FormLabel>
                      </div>
                      <div>
                        <FormLabel>
                          <div className="inline-block mb-1 ml-1">Royalties value *</div>
                          <NumberInput
                            validate={royaltyValidator}
                            min={0}
                            max={99}
                            inputMode="numeric"
                            name="tokenRoyalty.fraction"
                            required
                          />
                        </FormLabel>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="my-6">
                <FormLabel component="div">
                  <div className="inline-block mb-1 ml-1">Cover image *</div>
                  <FileDropzoneField
                    required
                    accept="image/*"
                    name="previews"
                    maxFiles={1}
                    helperText="This image will be on the cover of the item"
                  />
                </FormLabel>
              </div>
              <div className="flex justify-center my-6">
                <Button disabled={invalid || submitting} onClick={askConfirmation} size="large" variant="contained">
                  <span>Create</span>
                  {submitting && <CircularProgress sx={{ ml: 1 }} size={24} />}
                </Button>
              </div>
              <FormConfirmationDialog open={open} onClose={closeConfirmation} styles={styles} values={values} />
            </SimpleForm>
          )}
        </Form>
      </div>
    </PageLayoutV2>
  );
};
