import classnames from 'classnames';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';

import EditIcon from '../assets/edit-icon.svg?jsx';
import ExpandIcon from '../assets/expand-icon.svg?jsx';
import { SeasonInfo } from '../services/types';
import transitions from '../styles/transitions.module.css';
import { SeasonItem } from './season-item';

type Props = {
  id: number;
  name: string;
  seasons: SeasonInfo[];
  videos?: number;
  updateCollections: () => Promise<unknown>;
};

export function CollectionItem({ id, name, seasons, videos = 0, updateCollections }: Props) {
  const videosLabel = videos === 1 ? 'video' : 'videos';
  const { putActiveTenantUrlParam } = useUrlParams();
  const seasonsRef = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState(false);

  const editCollectionUrl = putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_EDIT_COLLECTION].url, {
    collectionId: id,
  });

  return (
    <article className="bg-white/5 border-2 p-3 text-white border-white/10 rounded-xl">
      <div className="flex items-center">
        <h3 className="text-lg font-bold">{name}</h3>
        <div className="flex ml-auto items-center gap-x-4 mr-4">
          <div className="flex gap-x-2">
            {seasons.length > 1 && <div>{seasons.length} seasons</div>}
            <div className="text-white/60">
              ({videos} {videosLabel})
            </div>
          </div>
          <Link
            to={editCollectionUrl}
            className={classnames(
              'flex',
              'items-center',
              'bg-white/20',
              'rounded-full',
              'ml-2',
              'py-2',
              'px-6',
              'transition-colors',
              'hover:bg-white/10',
            )}
            type="button"
            aria-label="edit"
          >
            <EditIcon />
          </Link>
          <button type="button" onClick={() => setExpanded((s) => !s)}>
            <ExpandIcon className={classnames({ 'rotate-180': !expanded }, 'transition-transform')} />
          </button>
        </div>
      </div>
      <CSSTransition
        in={expanded}
        timeout={500}
        mountOnEnter
        unmountOnExit
        classNames={{
          enter: transitions.enter,
          enterActive: transitions['enter-active'],
          exit: transitions.exit,
          exitActive: transitions['exit-active'],
        }}
        nodeRef={seasonsRef}
      >
        <div ref={seasonsRef} className="mt-6 flex flex-col gap-y-4">
          {seasons?.map((season) => (
            <SeasonItem
              updateCollections={updateCollections}
              id={season.seasonId}
              name={season.seasonName}
              key={season.seasonId}
              videos={season.videosCount}
            />
          ))}
        </div>
      </CSSTransition>
    </article>
  );
}
