import settings from '@iconify/icons-eva/settings-2-outline';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { shortenHexAddress } from '~/lib/utils';
import { Collection } from '~/types/collection';

import { CollectionRoyaltyForm } from './collection-royalty-form';

type Props = {
  collection: Collection;
};

export function CollectionItem({ collection }: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="border-b-[1px] border-dashed py-4 border-gray-600" key={collection.address}>
      <div className="flex gap-x-4 items-baseline">
        <div className="text-2xl text-gray-200">{collection.name}</div>
        <div className="text-sm text-gray-500">
          {shortenHexAddress(collection.address)}
          <CopyToClipboard value={collection.address} />
        </div>
        <button className="ml-auto" onClick={() => setExpanded((state) => !state)} type="button">
          <Icon icon={settings} width={16} height={16} />
        </button>
      </div>
      {expanded && <CollectionRoyaltyForm collection={collection} />}
    </div>
  );
}
