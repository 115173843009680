import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { KeyedMutator, useSWRConfig } from 'swr';

import { Tournament } from '~/routes/gaming/gaming.types';
import { TournamentsApiEndpoints } from '~/services/tournaments';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTournamentEditMutation = ({ mutateListQuery }: { mutateListQuery: KeyedMutator<Tournament[]> }) => {
  const { mutate } = useSWRConfig();
  const [isTournamentEditUploading, setUploading] = useState(false);
  const [isTournamentEditUploaded, setTournamentUploaded] = useState(false);

  const editTournament = useCallback(
    async ({
      gameId,
      title,
      subtitle,
      startDate,
      endDate,
      tournamentId,
      mainImage,
      images,
      guidsToUpdate,
      imagesToUpdate,
      twitterHashTag,
      address,
      signature,
    }: {
      gameId: number;
      title: string;
      subtitle?: string;
      startDate?: string;
      endDate?: string;
      tournamentId: number;
      mainImage?: File;
      images?: File[];
      guidsToUpdate?: string[];
      imagesToUpdate?: File[];
      address: string;
      twitterHashTag: string;
      signature: string;
    }) => {
      setUploading(true);

      const form = new FormData();
      form.append('gameId', String(gameId));

      if (title) {
        form.append('title', title);
      }

      if (subtitle) {
        form.append('subtitle', subtitle);
      }

      if (startDate) {
        form.append('startDate', String(new Date(startDate.split('/').reverse().join('/'))));
      }

      if (endDate) {
        form.append('endDate', String(new Date(endDate.split('/').reverse().join('/'))));
      }

      if (twitterHashTag) {
        form.append('twitterHashTag', twitterHashTag);
      }

      if (mainImage && !images) {
        form.append('mainImage', mainImage);
      }

      if (!mainImage && images) {
        images.forEach((item) => {
          form.append(`images`, item);
        });
      }

      if (guidsToUpdate) {
        const guidsString = guidsToUpdate.join(', ');
        form.append(`guids`, guidsString);
      }

      if (imagesToUpdate) {
        imagesToUpdate.forEach((item) => {
          form.append(`images`, item);
        });
      }

      try {
        const response = await mutate<AxiosResponse<Tournament>>(
          TournamentsApiEndpoints.tournamentUrl(tournamentId),
          fetcher(TournamentsApiEndpoints.tournamentUrl(tournamentId), {
            method: 'PUT',
            data: form,
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': title,
            },
          }),
        );

        if (response?.status === 200) {
          await mutateListQuery();
          setUploading(false);
          setTournamentUploaded(true);
        }
      } catch (e) {
        setUploading(false);
      } finally {
        setUploading(false);
      }
    },
    [mutate, mutateListQuery],
  );

  return { editTournament, isTournamentEditUploading, isTournamentEditUploaded };
};
