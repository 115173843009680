import { Done } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { ClassNameMap } from '@mui/styles';
import { useContext } from 'react';

import { MediaFileThumbnail } from '~/components/media-file-thumbnail';
import { AppContext } from '~/context/app-context';

import type { FormValues } from './form-values';

export const FormConfirmationDialog = ({
  onClose,
  open,
  styles: { confirmHeader, confirmListHeader, confirmListText },
  values,
}: {
  open: boolean;
  onClose: () => void;
  styles: ClassNameMap<'confirmListText' | 'confirmListHeader' | 'confirmationAssets' | 'confirmHeader'>;
  values: FormValues;
}) => {
  const { userPubKey } = useContext(AppContext);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 48,
              height: 48,
              background: green[50],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
            }}
          >
            <Done color="success" sx={{ fontSize: 24 }} />
          </Box>
          <Typography component="div" classes={{ root: confirmHeader }} variant="h3">
            Creation ready?
          </Typography>
          <Typography component="div" variant="body2" sx={{ color: 'text.secondary' }}>
            Uploaded data cannot be changed after confirming, so make to check the summary below.
          </Typography>
        </Box>
      </DialogTitle>
      <Divider sx={{ my: 1, mx: 2 }} />
      <DialogContent>
        <DialogContentText component="div" id="confirmation-dialog-description">
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            Nft name
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary' }}
            variant="body1"
          >
            {values.name}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            Quantity
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary' }}
            variant="body1"
          >
            {values?.supply}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            Description
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary', mb: 1 }}
            variant="body1"
          >
            {values?.description}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            Collection name
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary', mb: 1 }}
            variant="body1"
          >
            {values?.collection?.name}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            Collection address
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary', mb: 1 }}
            variant="body1"
          >
            {values?.collection?.address}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            NFT royalties receiver
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary', mb: 1 }}
            variant="body1"
          >
            {values?.tokenRoyalty?.address}
            {userPubKey.toLowerCase() === values?.tokenRoyalty?.address?.toLowerCase() ? (
              <span className="italic inline-block ml-2 text-gray-400">(you)</span>
            ) : null}
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListHeader }}
            sx={{ color: 'text.secondary' }}
            variant="h5"
          >
            NFT royalties receiver fraction
          </Typography>
          <Typography
            component="div"
            classes={{ root: confirmListText }}
            sx={{ color: 'text.primary', mb: 1 }}
            variant="body1"
          >
            {values?.tokenRoyalty?.fraction}%
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridGap: '1rem',
              gridTemplateColumns: 'repeat(auto-fit, 64px)',
            }}
          >
            {values?.assets?.map((asset: File) => (
              <MediaFileThumbnail key={asset.name} file={asset} />
            ))}
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider sx={{ mt: 1, mx: 2 }} />
      <DialogActions sx={{ py: 3, justifyContent: 'center', gap: 2 }}>
        <Button sx={{ width: 200 }} variant="outlined" onClick={onClose}>
          Discard
        </Button>
        <Button sx={{ width: 200 }} variant="contained" form="create-nft" type="submit" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
