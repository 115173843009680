import { ethers } from 'ethers';

import { connectionManager } from '~/model/wallet';

export const randomSigner = async (): Promise<{ address: string; signer: ethers.Signer }> => {
  const provider = await connectionManager.getProvider();
  const wallet = ethers.Wallet.createRandom();
  const signer = new ethers.Wallet(wallet.privateKey, provider);
  const address = await signer.getAddress();
  return { signer, address };
};
