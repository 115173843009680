import { Backdrop, Box, Button, Dialog, Theme } from '@mui/material';
import { useState } from 'react';

import { VideoPlayer } from '~/components/video-player-2';
import { HlsEncryptionLoader } from '~/components/video-player-2/hls-encryption-loader';
import { TokenAssetDetails } from '~/services/assets.service';

import styles from './video-page.module.css';

type Props = {
  videoSrc: string;
  tokenAsset: TokenAssetDetails;
};

export const EncryptedVideoPortal = ({ videoSrc, tokenAsset }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const loader = HlsEncryptionLoader.create({
    collectionAddress: tokenAsset.collectionAddress,
    nftId: tokenAsset.nftId.toString(),
    assetId: `asset-${tokenAsset.index}`,
  });

  if (!isOpen) {
    return (
      <Button variant="outlined" onClick={onOpen}>
        Play
      </Button>
    );
  }

  return (
    <Backdrop sx={{ backdropFilter: 'blur(5px)', zIndex: (theme: Theme) => theme.zIndex.appBar + 1 }} open={isOpen}>
      <Dialog
        hideBackdrop
        open={isOpen}
        classes={{ paper: styles.paper }}
        onClose={onClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <Box className={styles.player}>
          <VideoPlayer src={videoSrc} loader={loader} />
        </Box>
      </Dialog>
    </Backdrop>
  );
};
