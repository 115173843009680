import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { FileDropzoneField } from '~/components/form/fields/file-uploader/file-dropzone-field';
import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';
import { TokenAssetDetails } from '~/services/assets.service';
import { AssetContentType } from '~/types/asset-ddc';

import { AssetContentView } from './asset-content-view';
import { EncryptedAssetContentView } from './encrypted-asset-content-view';
import { FormAsset } from './types/form-asset';

type Props = {
  asset: FormAsset;
  fileFieldName: string;
  tokenAsset: TokenAssetDetails;
};

export const AssetContent = ({ asset, fileFieldName, tokenAsset }: Props) => {
  const [previewContentType, setContentType] = useState<AssetContentType | undefined>(
    asset.upload.asset?.[0]?.type as AssetContentType,
  );

  useEffect(() => {
    if (!previewContentType) {
      void fetch(asset.preview, { redirect: 'follow' }).then((response) => {
        setContentType(response.headers.get('content-type') as AssetContentType);
      });
    }
  }, [asset.preview, previewContentType]);

  if (asset.preview && asset.asset && previewContentType) {
    return (
      <FlexBox sx={{ alignItems: 'flex-start' }}>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
          <Typography sx={{ p: 2 }}>Preview</Typography>
          <AssetContentView contentUrl={asset.preview} type={previewContentType} title={asset.name} />
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
          <Typography sx={{ p: 2 }}>Asset</Typography>
          <EncryptedAssetContentView tokenAsset={tokenAsset} title={asset.name} />
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <>
      <FlexBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography sx={{ p: 2 }}>Asset file</Typography>
        <FlexBox>
          <FileDropzoneField
            accept="image/*,video/*,audio/*"
            name={`${fileFieldName}.asset`}
            maxFiles={1}
            helperText="File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
          />
          <HelpPopover>
            <Typography sx={{ p: 2 }}>Asset file</Typography>
          </HelpPopover>
        </FlexBox>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography sx={{ p: 2 }}>Preview File</Typography>
        <FlexBox>
          <FileDropzoneField
            accept="image/*,video/*,audio/*"
            name={`${fileFieldName}.preview`}
            maxFiles={1}
            helperText="Preview file types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
          />
          <HelpPopover>
            <Typography sx={{ p: 2 }}>Asset file preview</Typography>
          </HelpPopover>
        </FlexBox>
      </FlexBox>
    </>
  );
};
