import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { KeyedMutator, useSWRConfig } from 'swr';

import { Game } from '~/routes/gaming/gaming.types';
import { GamesApiEndpoints } from '~/services/games';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}
export function useDeleteGameMutation(mutateGamesList: KeyedMutator<Game[]>) {
  const { mutate } = useSWRConfig();

  const deleteGame = useCallback(
    async ({
      gameId,
      title,
      address,
      signature,
    }: {
      gameId: string;
      title: string;
      signature: string;
      address: string;
    }) => {
      if (!gameId) {
        return;
      }
      try {
        const response = await mutate(
          GamesApiEndpoints.gameUrl(gameId),
          fetcher(GamesApiEndpoints.gameUrl(gameId), {
            method: 'DELETE',
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': title,
            },
          }),
        );
        if (response?.status === 200) {
          await mutateGamesList();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [mutate, mutateGamesList],
  );

  return { deleteGame };
}
