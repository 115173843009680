import classNames from 'classnames';

// eslint-disable-next-line import/no-cycle
import { ControlContainerPropsType } from '~/components/ui';

import styles from './control-container.module.css';

export function ControlContainer({
  children,
  containerClassName,
  disabled,
  hasError,
  prefix,
  suffix,
  skin = 'primary',
}: ControlContainerPropsType) {
  return (
    <label
      className={classNames(styles.container, containerClassName, {
        [styles.primary]: skin === 'primary',
        [styles.shell]: skin === 'shell',
        [styles.disabled]: disabled,
        [styles['has-error']]: hasError,
        [styles['has-prefix']]: !!prefix,
      })}
    >
      {prefix && <div className={styles.prefix}>{prefix}</div>}
      <div className={styles.content}>{children}</div>
      {suffix && <span className={styles.suffix}>{suffix}</span>}
    </label>
  );
}
