import { post } from '~/lib/request';
import { unwrap } from '~/lib/unwrap';
import { FormValues } from '~/routes/mint/form-values';
import { getFreeportChainHeaders } from '~/services/get-freeport-chain-headers.service';

export async function uploadNftContent(
  address: string,
  {
    name,
    assets,
    description,
    collection,
    previews,
  }: Pick<FormValues, 'name' | 'assets' | 'description' | 'collection' | 'previews'>,
): Promise<string> {
  const form = new FormData();
  form.append('name', name);
  form.append('description', description);
  form.append('collection', collection.address);
  form.append('asset', unwrap(assets)[0]);
  form.append('preview', unwrap(previews)[0]);
  return post(`api/wallet/${address}/mint`, { data: form, headers: getFreeportChainHeaders() }).then((r) => r.text());
}
