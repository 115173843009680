import { Typography } from '@mui/material';

import { HelpPopover } from '~/components/icons/help-popover';
import { FlexBox } from '~/components/layout/flex-box';

import { WalletType } from './types/wallet-type';

type PageTitleProps = { walletType: WalletType };

export const PageTitle = ({ walletType }: PageTitleProps) => {
  if (walletType === WalletType.MINTED) {
    return (
      <FlexBox>
        <Typography variant="h4">Minted NFTs</Typography>
        <HelpPopover>
          <Typography sx={{ p: 2, maxWidth: 360 }}>NFTs that were issued from your account</Typography>
        </HelpPopover>
      </FlexBox>
    );
  }

  if (walletType === WalletType.OWNED) {
    return (
      <FlexBox>
        <Typography variant="h4">Owned NFTs</Typography>
        <HelpPopover>
          <Typography sx={{ p: 2, maxWidth: 360 }}>
            NFTs with an available balance greater than zero that you own with your account
          </Typography>
        </HelpPopover>
      </FlexBox>
    );
  }

  return <Typography variant="h4">NFTs</Typography>;
};
