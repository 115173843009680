import {
  ApplicationEnum,
  createERC20MockToken,
  createMarketplace,
  getERC20Address,
  getMarketplaceAddress,
} from '@cere/freeport-sc-sdk';
import { BigNumberish, utils } from 'ethers';

import { APPLICATION, CONTRACTS_DEPLOYMENT } from '~/constants/env';
import { assertOk } from '~/lib/assert-ok';
import { unitToDecimals } from '~/lib/decimals';
import { verifyGuard } from '~/lib/verify-guard';
import { getSigner } from '~/services/get-signer';
import { isBigNumberish } from '~/types/numbers';

type Signer = Awaited<ReturnType<typeof getSigner>>;

type Params = {
  owner: Signer;
  nftId: BigNumberish;
  collectionAddress: string;
  price: BigNumberish;
  saleAmount: BigNumberish;
};

export async function createOrder({
  owner,
  nftId,
  collectionAddress,
  price,
  saleAmount,
}: Params): Promise<[string, BigNumberish]> {
  assertOk(utils.isAddress(collectionAddress), 'Invalid collection address');
  const { provider } = owner;
  const marketplaceAddress = await getMarketplaceAddress(
    provider,
    CONTRACTS_DEPLOYMENT,
    APPLICATION() as ApplicationEnum,
  );
  const marketPlace = createMarketplace({ signer: owner, contractAddress: marketplaceAddress });

  const erc20Address = await getERC20Address(provider, CONTRACTS_DEPLOYMENT, APPLICATION() as ApplicationEnum);
  const erc20Token = createERC20MockToken({ signer: owner, contractAddress: erc20Address });
  const decimals = await erc20Token.decimals();

  const tx = await marketPlace['makeOrder(address,uint256,uint256,address,uint256)'](
    collectionAddress,
    nftId,
    saleAmount,
    erc20Address,
    unitToDecimals(price, decimals),
  );

  const response = await tx.wait();
  const orderCreatedEventArgs = response.events?.find(({ event }) => event === 'OrderCreated')?.args;
  return [tx.hash, verifyGuard(orderCreatedEventArgs?.[0], isBigNumberish)];
}
