import { BigNumber } from 'ethers';

import { get } from '~/lib/request';
import { benefitRawToBenefitMapper } from '~/mappers/benefit.mapper';
import { getFreeport } from '~/services/get-freeport';
import { Benefit } from '~/types/benefit';
import { isBenefitRaw } from '~/types/benefit-raw';
import { JointAccount } from '~/types/joint-account';

import { getFToken } from './get-ftoken';

export const createJointAccount = async (owners: string[], fractions: number[]): Promise<string> => {
  const freeport = await getFreeport();
  const { hash } = await freeport.createJointAccount(owners, fractions);

  return hash;
};

export const getAccountBenefits = async (account: string): Promise<Benefit[]> => {
  const response = await get(`/wallet/${account}/benefits`);
  const benefitsRaw = (await response.json()) as unknown[];

  return benefitsRaw.filter(isBenefitRaw).map(benefitRawToBenefitMapper);
};

export const balanceOfJA = async (account: string): Promise<BigNumber> => {
  const fToken = await getFToken();
  const balance = await fToken.balanceOf(account);

  return balance;
};

export const distributeJointAccount = async (account: string, onConfirmed: () => void): Promise<string> => {
  const freeport = await getFreeport();
  const ftoken = await getFToken();
  const tx = await freeport.distribution(account, [ftoken.address]);

  // eslint-disable-next-line no-void
  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};

export const getJointAccountsByOwner = async (owner: string): Promise<JointAccount[]> =>
  get(`api/wallet/${owner}/joint-accounts`).then((res) => res.json());
