import classNames from 'classnames';
import { ComponentType, ReactElement } from 'react';

import { Error } from '~/components/common/error';
import { FieldLabel } from '~/components/ui';

import styles from './field.module.css';
import { FieldOptions, FieldPropsType, WrappedComponentPropsType } from './field.types';

export const field =
  <TProps extends WrappedComponentPropsType>({
    error,
    fieldClassName,
    label,
    hideErrorMsg = false,
    disabled,
    required,
    hideLabel,
    showMaxSymbols,
    maxLength,
    currentSymbolsLength,
    isDate,
    ...rest
  }: FieldPropsType<TProps>) =>
  (Component: ComponentType<TProps>, options?: FieldOptions): ReactElement => {
    const hasError = !!error;
    const showError = hasError && !hideErrorMsg;

    return (
      <div
        className={classNames(styles.field, fieldClassName, {
          [styles['with-error']]: showError,
        })}
      >
        <FieldLabel
          disabled={disabled}
          title={hideLabel ? undefined : label}
          required={required}
          as={options?.labelAs}
          isDate={isDate}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Component
            maxLength={maxLength}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            disabled={disabled}
            hasError={hasError}
          />
        </FieldLabel>
        <div className={`flex ${!error ? 'justify-end' : 'justify-between'}`}>
          {showError && <Error error={error} />}
          {showMaxSymbols && (
            <span className={styles['with-symbols-count']}>
              Max symbols {currentSymbolsLength}/{maxLength}
            </span>
          )}
        </div>
      </div>
    );
  };
