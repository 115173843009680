import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { Form, FormSpy } from 'react-final-form';

import { Button } from '~/components/common/buttons/button';
import { MintedNftSelectFormField } from '~/components/common/form/select/minted-nft-select-form-field';
import { FormGrid } from '~/components/common/form-grid';
import { FormSection } from '~/components/common/form-section';
import { WizardContext } from '~/components/common/wizard/wizard.context';
import { GameUploadFormSecondStepPropsType } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-second-step/game-upload-form-second-step.types';

export function GameUploadFormSecondStep({ initialValues, onChange }: GameUploadFormSecondStepPropsType) {
  const { goToPrevStep, goToNextStep } = useContext(WizardContext);

  const handleOnSubmit = useCallback(() => {
    goToNextStep();
  }, [goToNextStep]);
  return (
    <Form initialValues={initialValues} onSubmit={handleOnSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} />
          <FormGrid>
            <FormSection title="NFT Reward after gamer plays first game">
              <div className="grid grid-cols-[auto_300px] gap-x-6">
                <Box
                  className="mb-8 p-3 rounded-lg border-1 border-white/10 w-full"
                  sx={{ backgroundColor: 'rgba(236, 240, 255, 0.2)' }}
                >
                  <div className="grid grid-cols-[auto_auto] gap-x-3">
                    <InfoOutlinedIcon />
                    <Typography className="font-medium text-xs">
                      Attach NFT which will consist gamer’s score and achievements and allow them to trade in Cere
                      marketplace and giving you royalties. After the first game session gamer will claim this NFT.
                    </Typography>
                  </div>
                </Box>
                <Box />
              </div>
              <div className="flex flex-col">
                <MintedNftSelectFormField />
              </div>
            </FormSection>
            <div className="flex justify-between">
              <Button className="min-w-[200px]" skin="outlined" icon={<KeyboardBackspaceIcon />} onClick={goToPrevStep}>
                Back
              </Button>
              <Button className="min-w-[266px]" skin="primary" postIcon={<ArrowRightAltIcon />} onClick={goToNextStep}>
                Setup tournaments
              </Button>
            </div>
          </FormGrid>
        </form>
      )}
    </Form>
  );
}
