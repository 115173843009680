import { createFreeportCollection } from '@cere/freeport-sc-sdk';
import { BigNumberish } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import { verifyGuard } from '~/lib/verify-guard';
import { isNftMetadata, NftMetadata } from '~/routes/wallet/types/nft-metadata';
import { getSigner } from '~/services/get-signer';

const fetchMetadata = (url: string) =>
  fetch(url)
    .then((response) => response.json())
    .then((data) => verifyGuard(data, isNftMetadata));

export function useNftMetadata(
  collectionAddress: string,
  nftId: BigNumberish,
): { isLoading: boolean; metadata: NftMetadata | undefined; mutate: () => Promise<unknown> } {
  const [uri, setUri] = useState<string | undefined>();
  const controller = useRef(new AbortController());
  const { data: metadata, isLoading, mutate } = useSWR(uri, fetchMetadata);

  useEffect(
    () => () => {
      controller.current.abort();
    },
    [],
  );

  useEffect(() => {
    void getSigner()
      .then((signer) => {
        if (!collectionAddress) return '';
        const collection = createFreeportCollection({ signer, contractAddress: collectionAddress });
        return collection.uri(nftId);
      })
      .then((url) => {
        if (!controller.current.signal.aborted) {
          setUri(url);
        }
      });
  }, [collectionAddress, nftId]);

  return { isLoading, metadata, mutate };
}
