import { FormState } from 'final-form';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Wizard } from '~/components/common/wizard';
import { WizardStep } from '~/components/common/wizard/components/wizard-step';
import { Condition, ConditionsList } from '~/components/conditions';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useGameSettingEditMutation } from '~/hooks';
import { useGameEditMutation } from '~/hooks/games/useGameEditMutation';
import { useGameQuery } from '~/hooks/games/useGameQuery';
import { useGameUploadMutation } from '~/hooks/games/useGameUploadMutation';
import { useRouteParam } from '~/lib/hooks/use-route-param';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { GameUploadFormFirstStep } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-first-step';
import { GameUploadFormSecondStep } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-second-step';
import { GameUploadThirdStep } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-form-third-step';
import { GameUploadFormFourthStep } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/game-upload-fourth-step';
import {
  GameUploadFormDataLocal,
  GameUploadFormDataType,
} from '~/routes/gaming/game-upload-route/components/game-upload-form/game-upload-form.types';
import { getSigner } from '~/services/get-signer';

export function GameUploadForm() {
  const { state } = useLocation<{ step?: number }>();

  const address = useUserPublicKey();

  const [gameId, setGameId] = useState(useRouteParam('gameId'));
  const isEdit = Boolean(gameId);

  const { data: game, isLoading, error } = useGameQuery({ id: gameId as string, enable: isEdit });
  const [formData, setFormData] = useState<GameUploadFormDataType>({
    gameFiles: undefined,
    gameTitle: game?.title || '',
    gameCode: game?.code || '',
    nftId: game?.nftId || undefined,
    messageTitle: game?.preloaderTitle || '',
    messageDescription: game?.preloaderDescription,
    preloaderImage: game?.preloaderPath || undefined,
    tournamentTitle: '',
    tournamentSubtitle: '',
    tournamentStartDate: undefined,
    tournamentEndDate: undefined,
  });

  const { uploadGameMutation, isGameUploaded, isGameUploading } = useGameUploadMutation({ enable: !isEdit, setGameId });
  const { editGameMutation, isGameEdited, isGameEditing } = useGameEditMutation({
    id: gameId as string,
    enable: isEdit,
  });
  const { uploadGameSettingsMutation, isGameSettingsUploading, isGameSettingsUploaded } = useGameSettingEditMutation({
    id: gameId as string,
    enable: isEdit,
  });

  useEffect(() => {
    if (game) {
      if (game.preloaderDescription) {
        game.preloaderDescription.forEach((description: string, index) =>
          setFormData((prevState) => ({
            ...prevState,
            [`messageDescription${index + 1}`]: description,
          })),
        );
      }
      setFormData((prevState) => ({
        ...prevState,
        gameTitle: game.title,
        gameCode: game.code,
        nftId: game?.nftId || undefined,
        messageTitle: game?.preloaderTitle,
        preloaderImage: game?.preloaderPath,
      }));
    }
  }, [game]);

  const history = useHistory();
  const { putActiveTenantUrlParam } = useUrlParams();
  const gamesListUrl = putActiveTenantUrlParam(routes[RoutesEnum.GAMES].url);

  const handleOnFormChange = useCallback(({ values: newValues }: FormState<GameUploadFormDataType>) => {
    setFormData((currentData) => ({ ...currentData, ...newValues }));
  }, []);

  const handleOnUploadGame = useCallback(
    async ({ gameFiles, gameTitle, gameCode }: GameUploadFormDataType) => {
      const signer = await getSigner();
      const signature = await signer.signMessage(gameTitle);
      await uploadGameMutation({ gameTitle, file: gameFiles as File, address, signature, gameCode });
    },
    [address, uploadGameMutation],
  );

  const handleOnEditGame = useCallback(
    async ({ gameTitle, gameFiles, gameCode }: GameUploadFormDataType) => {
      const signer = await getSigner();
      const signature = await signer.signMessage(gameTitle);
      await editGameMutation({ file: gameFiles, signature, address, title: gameTitle, code: gameCode });
    },
    [address, editGameMutation],
  );

  const handleOnSubmit = useCallback(
    async ({
      gameTitle,
      nftId,
      preloaderImage,
      messageTitle,
      messageDescription1,
      messageDescription2,
      messageDescription3,
    }: GameUploadFormDataLocal) => {
      const signer = await getSigner();
      const signature = await signer.signMessage(gameTitle);
      const preloaderDescriptions: string[] = [];
      if (messageDescription1) {
        preloaderDescriptions.push(messageDescription1);
      }

      if (messageDescription2) {
        preloaderDescriptions.push(messageDescription2);
      }

      if (messageDescription3) {
        preloaderDescriptions.push(messageDescription3);
      }
      await uploadGameSettingsMutation({
        title: gameTitle,
        signature,
        address,
        nftId,
        preloaderTitle: messageTitle,
        preloaderDescription: preloaderDescriptions,
        preloaderFile: preloaderImage,
      });
      history.push(gamesListUrl);
    },
    [address, gamesListUrl, history, uploadGameSettingsMutation],
  );

  const hasError = Boolean(error);

  return (
    <ConditionsList>
      <Condition condition={!hasError}>
        <Wizard
          isLoading={isLoading}
          currentStep={state?.step}
          titleArr={[
            isEdit ? (game?.title as string) : 'Upload your game',
            'Do you want to reward your gamers?',
            'Setup tournament leaderboards',
            'Welcome your gamers',
          ]}
        >
          <WizardStep>
            <GameUploadFormFirstStep
              initialValues={formData}
              onChange={handleOnFormChange}
              onSubmit={isEdit ? handleOnEditGame : handleOnUploadGame}
              isGameUploading={isGameUploading || isGameEditing || isGameSettingsUploading}
              isGameUploaded={isGameUploaded || isGameEdited || isGameSettingsUploaded}
              isLoading={isLoading}
              isEdit={isEdit}
            />
          </WizardStep>
          <WizardStep>
            <GameUploadFormSecondStep initialValues={formData} onChange={handleOnFormChange} />
          </WizardStep>
          <WizardStep>
            <GameUploadThirdStep game={game} initialValues={formData} onChange={handleOnFormChange} />
          </WizardStep>
          <WizardStep>
            <GameUploadFormFourthStep
              initialValues={formData}
              onChange={handleOnFormChange}
              onSubmit={handleOnSubmit}
            />
          </WizardStep>
        </Wizard>
      </Condition>
    </ConditionsList>
  );
}
