import {
  isSubscription,
  isSubscriptionArray,
  Subscription,
  SubscriptionDataAccessStrategy,
  SubscriptionPlanType,
} from '../types/subscriptions';
import { AuthParams, getAuthHeaders, getUrl, typeGuardMiddleWare, xhrMiddleware } from './shared';

type SubscriptionParams = {
  id?: number;
  title?: string;
  description?: string;
  priceCents?: number;
  durationAmount?: number;
  durationUnits?: 'day' | 'week' | 'month';
  relatedVideoCollections?: number[];
  cover?: File;
  coverCid?: string;
  status: boolean;
  dataAccessStrategy: SubscriptionDataAccessStrategy;
  plan: SubscriptionPlanType;
} & AuthParams;

export function saveSubscription({ address, signature, message, ...params }: SubscriptionParams): Promise<unknown> {
  const url = params.id ? getUrl(`/video/subscription-plans/${params.id}`) : getUrl('/video/subscription-plans');
  const method = params.id ? 'PUT' : 'POST';
  const form = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    if (value instanceof File) {
      form.append(key, value);
    } else if (typeof value === 'boolean') {
      form.append(key, value ? '1' : '');
    } else if (value != null && typeof value === 'object') {
      form.append(key, JSON.stringify(value));
    } else if (value != null) {
      form.append(key, value.toString());
    }
  });

  return fetch(url, {
    method,
    body: form,
    headers: getAuthHeaders({ message, address, signature }),
  }).then(xhrMiddleware);
}

export const getSubscriptionsUrl = (): string => getUrl('/video/subscription-plans');

export const getSubscriptions = ([url, address]: [string, string]): Promise<Subscription[]> =>
  fetch(url, {
    headers: {
      'x-public-key': address,
    },
  })
    .then(xhrMiddleware)
    .then(typeGuardMiddleWare(isSubscriptionArray));

export const getSubscriptionUrl = (id: string): string => getUrl(`/video/subscription-plans/${id}`);

export const getSubscription = ([url, address]: [string, string]): Promise<Subscription> =>
  fetch(url, {
    headers: {
      'x-public-key': address,
    },
  })
    .then(xhrMiddleware)
    .then(typeGuardMiddleWare(isSubscription));

export const deleteSubscriptionPlan = (id: number, { address, signature, message }: AuthParams): Promise<unknown> =>
  fetch(getUrl(`/video/subscription-plans/${id}`), {
    method: 'DELETE',
    headers: getAuthHeaders({ message, address, signature }),
  }).then((response) => xhrMiddleware(response, false));
