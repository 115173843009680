/* eslint-disable class-methods-use-this */
import { ProviderInterface } from '~/services/analytics/types';

declare global {
  interface Window {
    analytics: {
      track: (...args: unknown[]) => unknown;
    };
  }
}

export class SegmentProvider implements ProviderInterface {
  init(): void {}

  track(eventName: string, payload: unknown): void {
    window.analytics?.track?.(eventName, payload);
  }
}
