import { Container } from '@mui/material';
import { useContext, useMemo } from 'react';

import { AppContext } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { isStringEqual } from '~/lib/utils';
import { AssetUploadContextProvider } from '~/routes/wallet/asset-upload-context';
import { AssetsList } from '~/routes/wallet/assets-list';

export const TokenDetails = () => {
  const { userPubKey } = useContext(AppContext);
  const { nft } = useContext(ItemLayoutContext);

  const isNftOwner = useMemo(() => isStringEqual(nft.minter.wallet, userPubKey), [nft.minter, userPubKey]);

  return (
    <AssetUploadContextProvider>
      <Container>
        <AssetsList isOwner={isNftOwner} />
      </Container>
    </AssetUploadContextProvider>
  );
};
