import { createContext, useContext } from 'react';

import { AnalyticService } from '~/services/analytics/analitycs-service';
import { NetworkServiceInterface } from '~/services/v2/interface/network.service.interface';

import { AppContextType, WalletNetworkType } from './types';

export const AppContext = createContext<AppContextType>({
  userPubKey: '',
  // saveUserPubKey: () => null,
  changeNetworkDriver: () => null,
  analyticsService: new AnalyticService([]),
  isLoading: true,
  network: WalletNetworkType.EIP155,
  driver: null,
  connectWallet: () => {},
  disconnectWallet: () => {},
});

export const useUserPublicKey = (): string => {
  const appContext = useContext(AppContext);
  return appContext.userPubKey;
};

export const useNetwork = (): { driver: NetworkServiceInterface; network: WalletNetworkType | null } => {
  const appContext = useContext(AppContext);
  if (appContext.driver) {
    return {
      driver: appContext.driver,
      network: appContext.network,
    };
  }
  throw new Error('Drive not found');
};
