import classnames from 'classnames';

import { operationButtonClass } from '~/routes/video/components/constants';

import DeleteIcon from '../assets/delete.svg?jsx';

type Props = {
  onClick?: () => Promise<unknown>;
  label: string;
};

export function ItemDeleteButton({ onClick, label }: Props) {
  return (
    <button onClick={onClick} className={classnames(operationButtonClass, 'px-4')} type="button" aria-label={label}>
      <DeleteIcon />
    </button>
  );
}
