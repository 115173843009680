import { matchPath, useLocation } from 'react-router-dom';

import { tenantDetectPattern } from '~/constants/routes';
import { ACTIVE_TENANT_KEY } from '~/services/constants';
import { isRecord } from '~/types/is-record';

export const useActiveTenantId = (): string => {
  const location = useLocation();
  const storedTenant = window.localStorage.getItem(ACTIVE_TENANT_KEY);
  const m = matchPath(location.pathname, { path: tenantDetectPattern, exact: false });
  const params = m?.params;
  if (isRecord(params)) {
    return String(params.tenantId);
  }
  return storedTenant != null ? storedTenant : '';
};
