import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import React, { forwardRef, Ref } from 'react';
import { Link } from 'react-router-dom';

import { ButtonPropsTypes } from '~/components/common/buttons/button/button.types';

import styles from './button.module.css';

export const Button = forwardRef(
  <Link extends string | undefined>(
    {
      icon,
      postIcon,
      onClick,
      loading = false,
      children,
      className,
      disabled,
      skin = 'primary',
      fluid = false,
      style,
      link,
      type,
    }: ButtonPropsTypes<Link>,
    ref: null | Link extends string ? Ref<HTMLAnchorElement | null> : Ref<HTMLButtonElement | null>,
  ) => {
    const isLinkButton = skin === 'link';

    const commonProps = {
      disabled: disabled || loading,
      className: classNames(
        'text-base font-medium inline-flex justify-center',
        styles.button,
        {
          [styles.primary]: skin === 'primary',
          [styles.secondary]: skin === 'secondary',
          [styles.outlined]: skin === 'outlined',
          [styles.link]: isLinkButton,
          'w-full': fluid,
          'w-max': !children,
        },
        className,
      ),
      style,
    };

    if (link !== undefined) {
      return (
        <Link
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...commonProps}
          role="button"
          ref={ref as Ref<HTMLAnchorElement>}
          to={link}
        >
          {icon && <p className={!children ? 'm-0' : 'mr-[9px]'}>{icon}</p>}
          <p className={!postIcon ? 'mr-0' : 'mr-[9px]'}>{children}</p>
          {postIcon && <p>{postIcon}</p>}
        </Link>
      );
    }
    return (
      <button
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...commonProps}
        ref={ref as Ref<HTMLButtonElement>}
        /* eslint-disable-next-line react/button-has-type */
        type={type || 'button'}
        onClick={onClick as (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined}
      >
        {loading && <CircularProgress className="mr-3 text-white" size={20} />}
        {icon && <p className={!children ? 'm-0' : 'mr-[9px]'}>{icon}</p>}
        <p className={!postIcon ? 'mr-0' : 'mr-[9px]'}>{children}</p>
        {postIcon && <p>{postIcon}</p>}
      </button>
    );
  },
);
