import { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';

import { Collections } from './collections/collections';
import { EditVideo } from './edit-video/edit-video';
import { EditSubscription } from './subscriptions/edit-subscription';
import { Subscriptions } from './subscriptions/subscriptions';
import { VideoHome } from './video-home';

export function Video(): ReactElement {
  const { putActiveTenantUrlParam } = useUrlParams();

  return (
    <PageLayout>
      <div className="pb-12 md:px-[100px] pt-8">
        <Switch>
          <Route exact path={routes[RoutesEnum.VIDEO_SUBSCRIPTIONS].url}>
            <Subscriptions />
          </Route>
          <Route exact path={routes[RoutesEnum.EDIT_VIDEO_SUBSCRIPTIONS].url}>
            <EditSubscription />
          </Route>
          <Route path={routes[RoutesEnum.VIDEO_COLLECTIONS].url}>
            <Collections />
          </Route>
          <Route exact path={routes[RoutesEnum.VIDEO_EDIT].url}>
            <EditVideo />
          </Route>
          <Route exact path={routes[RoutesEnum.VIDEO_LIBRARY].url}>
            <VideoHome />
          </Route>
          <Route path="*">
            <Redirect to={putActiveTenantUrlParam(routes[RoutesEnum.VIDEO_LIBRARY].url, {})} />
          </Route>
        </Switch>
      </div>
    </PageLayout>
  );
}
