import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import classnames from 'classnames';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { useUserPublicKey } from '~/context/app-context';
import { delay } from '~/lib/delay';
import { getSigner } from '~/services/get-signer';

import SearchIcon from '../assets/search-icon.svg?jsx';
import { CloseButton } from '../components/close-button';
import { Dialog } from '../components/dialog';
import { Paginator } from '../components/paginator/paginator';
import { useDdcParams } from '../hooks/use-ddc-params';
import { buildIFileUrl } from '../services/shared';
import {
  getVideoMetadataList,
  getVideoMetadataListCount,
  getVideoMetadataUrl,
  getVideoMetadataUrlCount,
  saveVideoMetadata,
} from '../services/video.services';
import styles from './season-item.module.css';

type Props = {
  id: number;
  name: string;
  videos?: number;
  updateCollections: () => Promise<unknown>;
};

const TAKE = 30;

export function SeasonItem({ id, name, videos = 0, updateCollections }: Props) {
  const videosLabel = videos === 1 ? 'episode' : 'episodes';
  const [open, setOpen] = useState(false);
  const [processingId, setProcessingId] = useState(0);
  const [search, setSearch] = useState('');
  const [current] = useState(1);
  const params = { search, take: TAKE, skip: (current - 1) * TAKE };
  const searchUrl = getVideoMetadataUrl(params);
  const searchUrlCount = getVideoMetadataUrlCount(params);
  const mounted = useRef(true);
  const signsCache = useMemo(() => new Map<string, string>(), []);
  const signMessage = useCallback(
    async (signer: Awaited<ReturnType<typeof getSigner>>, message: string): Promise<string> => {
      if (signsCache.has(message)) {
        return signsCache.get(message) as string;
      }
      const signature = await signer.signMessage(message);
      signsCache.set(message, signature);
      return signature;
    },
    [signsCache],
  );

  const ddcParams = useDdcParams();

  const { data: movies, mutate } = useSWR(() => (open ? searchUrl : null), getVideoMetadataList);
  const { data: amount } = useSWR(() => (open ? searchUrlCount : null), getVideoMetadataListCount);

  const onClose = () => setOpen(false);
  const address = useUserPublicKey();

  useEffect(() => {
    mounted.current = open;
    return () => {
      mounted.current = false;
      setSearch('');
      setProcessingId(0);
    };
  }, [open]);

  const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const el = event.target;
    delay(300)
      .then(() => {
        if (mounted.current) {
          setSearch(el.value);
        }
      })
      .catch(() => {});
  }, []);

  const onChangeCollection = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const el = event.target;
      const { checked } = el;
      const movieId = Number(el.dataset.movieId);
      const movie = movies?.find((video) => video.id === movieId);
      if (!movie) {
        return;
      }
      setProcessingId(movie.id);
      const signer = await getSigner();
      const signature = await signMessage(signer, movie.videoTitle);
      const collections = movie.collections
        .filter((collection) => collection.type !== 'Season')
        .map((collection) => collection.id);

      if (checked) {
        collections.push(id);
      }
      await saveVideoMetadata({
        address,
        message: movie.videoTitle,
        signature,
        collections,
        id: movieId,
        videoTitle: movie.videoTitle,
        videoDescription: movie.videoDescription,
      });

      await mutate();
      await updateCollections();

      if (mounted.current) {
        setProcessingId(9);
      }
    },
    [address, id, movies, mutate, signMessage, updateCollections],
  );

  return (
    <>
      <div id="22" className="flex items-center rounded-md p-3 border-2 border-white/10 bg-white/5">
        <h4>Season {name}</h4>
        <button
          onClick={() => setOpen(true)}
          type="button"
          className="ml-auto border-dashed border-b-[1px] border-white/50"
        >
          {`${videos} ${videosLabel}`}
        </button>
      </div>
      <Dialog maxWidth="md" onClose={onClose} open={open}>
        <DialogTitle classes={{ root: 'flex items-center gap-x-6 pr-24 pt-8' }}>
          <div className="text-3xl grow-0">Add video to collection</div>
          <div className="border-white/30 border-[1px] gap-x-4 rounded-full pl-6 pr-4 h-[48px] grow flex items-center justify-start">
            <SearchIcon />
            <input
              onChange={onSearch}
              placeholder="search"
              className={classnames(styles.search, 'outline-0', 'bg-transparent', 'border-0', 'text-white/70')}
              type="search"
              name="search"
            />
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: 'pt-8' }}>
          <CloseButton onClick={onClose} />
          <div className="flex flex-col gap-y-6 mt-8">
            {movies?.map((movie) => (
              <div
                className={classnames(
                  { 'opacity-50': movie.id === processingId },
                  { 'blur-sm': movie.id === processingId },
                  { 'pointer-events-none': movie.id === processingId },
                  'flex p-2 items-center gap-x-3 border-[1px] border-white/20 rounded-2xl',
                )}
                key={movie.id}
              >
                <div className="w-[72px] h-[72px] rounded-xl overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    alt=""
                    src={buildIFileUrl(ddcParams?.cdnUrl, ddcParams?.publicBucketId, movie.coverCid)}
                  />
                </div>
                <h4 className="font-bold">{movie.videoTitle}</h4>
                <label className="block ml-auto pr-6 h-12 w-24 flex items-center justify-end cursor-pointer">
                  <input
                    data-movie-id={movie.id}
                    onChange={onChangeCollection}
                    checked={movie.collections.map((col) => col.id).includes(id)}
                    className="text-2xl cursor-pointer"
                    type="checkbox"
                  />
                </label>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions classes={{ root: 'justify-start' }}>
          <div className="py-4 px-2">
            <Paginator max={amount ? Math.floor(amount / TAKE) : 1} visibleRange={5} current={current} />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
