import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Field } from 'react-final-form';

type Props = {
  name: string;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

export const Checkbox = ({ name, label, readOnly = false, disabled = false, size = 'medium' }: Props) => (
  <Field name={name} type="checkbox">
    {({ input, meta }) => (
      <FormControl>
        <FormControlLabel
          sx={readOnly ? { pointerEvents: 'none' } : undefined}
          disabled={disabled}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          control={<MuiCheckbox checked={input.checked} size={size} name={input.name} />}
          label={label}
        />
        {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    )}
  </Field>
);
