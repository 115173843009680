import { Box, Container, Grid } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { AppContext } from '~/context/app-context';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { NftCard } from '~/routes/wallet/nft-card';
import { getMintedTokens, getOwnTokens } from '~/services/assets.service';
import { Nft } from '~/types/nft';

import { PageActions } from './page-actions';
import { PageTitle } from './page-title';
import { WalletType } from './types/wallet-type';

const fetcher = async (address: string, walletType: WalletType): Promise<Nft[]> => {
  if (walletType === WalletType.MINTED) {
    return getMintedTokens(address);
  }

  if (walletType === WalletType.OWNED) {
    return getOwnTokens(address);
  }

  throw new Error(`WalletType "${walletType}" is not supported`);
};

type WalletProps = {
  type: WalletType;
  address: string;
};

export const Wallet = ({ type, address }: WalletProps) => {
  const { userPubKey } = useContext(AppContext);
  const { showMessage } = useMessages();

  const { data, error: tokensLoadingError } = useSWR<Nft[], Error>({ walletType: type }, ({ walletType }) =>
    fetcher(address, walletType),
  );
  const tokens = useMemo(() => data ?? [], [data]);
  const isLoading = useMemo(() => data == null, [data]);

  useEffect(() => {
    if (tokensLoadingError) {
      showMessage(`Error occurred. ${formatError(tokensLoadingError)}`, 'error');
    }
  }, [showMessage, tokensLoadingError]);

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, ml: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <PageTitle walletType={type} />
          </Box>
          {userPubKey === address && (
            <Box sx={{ flexShrink: 1 }}>
              <PageActions />
            </Box>
          )}
        </FlexBox>

        {tokens.length > 0 ? (
          <Grid container spacing={3}>
            {tokens.map((item: Nft) => (
              <Grid key={item.id} item xs={12} sm={6} md={3}>
                <NftCard nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty>Create and configure your NFT</Empty>
        )}
      </Container>
    </PageLayout>
  );
};
