import axios from 'axios';

import { SubmitFormTypes } from '~/routes/gaming/game-upload-route/components/game-upload-form/components/tournament-competitors/tournament-competitors.types';

import { TournamentsApiEndpoints } from './endpoints';

export const submitTournamentCompetitors = (
  competitors: SubmitFormTypes[],
  message: string,
  signature: string,
  address: string,
) =>
  axios.post(TournamentsApiEndpoints.rewardUrl(), [...competitors], {
    headers: {
      'x-public-key': address,
      'x-signature': signature,
      'x-message': message,
    },
  });
