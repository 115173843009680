import { Box, Skeleton } from '@mui/material';

export function GameSkeleton() {
  return (
    <>
      <Box className="bg-white/5 rounded-xl pt-2 pr-5 pb-2 pl-2 mb-3 mt-12">
        <Box className="flex items-center justify-between">
          <Box className="grid grid-cols-[auto_auto] gap-5 items-center">
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={72} />
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={144} height={34} />
          </Box>
          <Box className="grid grid-cols-[auto_auto] gap-8 items-center">
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={26} />
            <Box className="grid grid-cols-[auto_auto] gap-4 items-center">
              <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={48} />
              <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={120} height={48} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="bg-white/5 rounded-xl pt-2 pr-5 pb-2 pl-2">
        <Box className="flex items-center justify-between">
          <Box className="grid grid-cols-[auto_auto] gap-5 items-center">
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={72} />
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={144} height={34} />
          </Box>
          <Box className="grid grid-cols-[auto_auto] gap-8 items-center">
            <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={26} />
            <Box className="grid grid-cols-[auto_auto] gap-4 items-center">
              <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={72} height={48} />
              <Skeleton animation="wave" className="rounded-xl	" variant="rectangular" width={120} height={48} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
