import classnames from 'classnames';

import { StatusPropsType } from '~/components/common/status/status.types';
import { StatusTheme } from '~/types/status';

import styles from './status.module.css';

export function Status({ theme, label }: StatusPropsType) {
  return (
    <div className={classnames('flex', 'items-center', 'justify-between', 'gap-x-1.5', styles.status)}>
      <span
        className={classnames(styles.marker, {
          [styles.default]: theme === StatusTheme.PENDING,
          [styles.success]: theme === StatusTheme.PUBLISHED,
          [styles.error]: theme === StatusTheme.REJECTED,
        })}
      />
      <span className="font-semibold">{label}</span>
    </div>
  );
}
