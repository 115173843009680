import Inputmask from 'inputmask';
import { forwardRef, useImperativeHandle, useLayoutEffect, useRef } from 'react';

import { CustomInput } from '~/components/form/fields';
import { ControlContainer } from '~/components/ui';

import { MaskedInputPropsType } from './masked-input.types';

export const MaskedInput = forwardRef<HTMLInputElement, Omit<MaskedInputPropsType, 'ref'>>(
  ({ unmaskValue, mask, maskChar = '', value, showMaskOnFocus, showMaskOnHover, hasError, ...props }, forwardedRef) => {
    const ref = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
      Inputmask({
        alias: 'date',
        mask,
        autoUnmask: unmaskValue,
        placeholder: maskChar,
        showMaskOnFocus,
        showMaskOnHover,
      }).mask(ref.current as HTMLInputElement);
    }, [mask, maskChar, props.placeholder, showMaskOnFocus, showMaskOnHover, unmaskValue]);

    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

    return (
      <ControlContainer hasError={hasError}>
        <CustomInput
          ref={ref}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </ControlContainer>
    );
  },
);
