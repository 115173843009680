import { FieldState } from 'final-form';

import { FieldValue } from '~/components/form/fields/types';
import { SyncFieldValidator } from '~/components/form/fields/validators/types';

export const toNumber = (value: FieldValue): number => Number(value) || 0;

export const composeSyncValidators =
  <T>(...validators: Array<SyncFieldValidator<T>>): SyncFieldValidator<T> =>
  (value: T, allValues?: Record<string, unknown>, meta?: FieldState<T>) => {
    for (const validator of validators) {
      const result = validator(value, allValues, meta);
      if (result !== null) {
        return result;
      }
    }

    return null;
  };
