import classNames from 'classnames';

import styles from './error.module.css';
import { ErrorPropsType } from './error.types';

export function Error({ error, theme, className }: ErrorPropsType) {
  if (!error || Array.isArray(error)) {
    return null;
  }

  return <p className={classNames(styles.error, { [styles['error-block']]: theme === 'BLOCK' }, className)}>{error}</p>;
}
