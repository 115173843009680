import upperFirst from 'lodash/upperFirst';
import { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Field, useField } from 'react-final-form';

import { InputField } from '~/components/common/form/input-field';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { getFieldError } from '~/utils/form';

import { InputFormFieldPropsType } from './input-form-field.types';

export function InputFormField({
  name,
  required,
  onBlur,
  onChange,
  max,
  min,
  maxLength,
  ...props
}: InputFormFieldPropsType) {
  const { type, trimSpaces, upperFirst: isUpperFirst } = props;

  const fieldValidator = useTextInputValidator<string>({
    required,
  });

  const {
    input,
    meta: { error, touched },
  } = useField<string>(name, { validate: fieldValidator });
  const { value, onChange: setValue } = input;

  const isTypeNumber = type === 'number';

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onBlur?.(e);
      if (trimSpaces && !isTypeNumber && typeof value === 'string') {
        onChange?.(value?.trim() as unknown as ChangeEvent<HTMLInputElement>);
      }

      input.onBlur(e);
    },
    [input, isTypeNumber, onBlur, onChange, trimSpaces, value],
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);

      const { selectionStart, selectionEnd } = event.target;

      let rawInputValue: string | number = event.target.value;

      if (isTypeNumber) {
        const strValue = rawInputValue.replace(/[^0-9]/g, '');
        rawInputValue = strValue || strValue === '0' ? Number(strValue) : 0;

        if (typeof max === 'number' && rawInputValue > max) {
          return;
        }

        if (typeof min === 'number' && rawInputValue < min) {
          return;
        }
      } else if (isUpperFirst) {
        rawInputValue = rawInputValue
          .split(' ')
          .map((part: string) => upperFirst(part))
          .join(' ');
      }

      if (rawInputValue !== event.target.value) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = rawInputValue as string;
        // eslint-disable-next-line no-param-reassign
        event.target.selectionStart = selectionStart;
        // eslint-disable-next-line no-param-reassign
        event.target.selectionEnd = selectionEnd;
      }

      setValue(rawInputValue);
    },
    [onChange, isTypeNumber, isUpperFirst, setValue, max, min],
  );

  return (
    <Field name={name} type={type}>
      {(iProps) => (
        <InputField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...iProps}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          max={maxLength}
          maxLength={maxLength}
          min={min}
          currentSymbolsLength={value.length}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={value || ''}
          type={!isTypeNumber ? type : undefined}
          error={getFieldError({ error, touched: touched as boolean })}
        />
      )}
    </Field>
  );
}
