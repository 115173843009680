import { BigNumber, BigNumberish } from 'ethers';

const PRECISION = 2;

const ALPHABET = ['K', 'M', 'B', 'T'];
const THRESHOLD = 1e3;

function humanNumber(num: BigNumberish, fn?: (v: BigNumberish) => string): string {
  let idx = 0;
  let current = typeof num === 'number' ? BigNumber.from(Math.round(num)) : BigNumber.from(num);
  while (current.gte(THRESHOLD) && idx < ALPHABET.length) {
    current = current.div(THRESHOLD);
    idx += 1;
  }
  const result = fn == null ? current.toString() : fn(current);
  return String(idx === 0 ? result : result + ALPHABET[idx - 1]);
}

export function getHumanTokenValue(tokenInDecimals: BigNumberish, decimals: BigNumberish): string {
  const divider = BigNumber.from(10).pow(BigNumber.from(decimals).sub(PRECISION));
  const valueWithPower = BigNumber.from(tokenInDecimals).div(divider);
  return valueWithPower.lte(Number.MAX_SAFE_INTEGER - 1)
    ? humanNumber(valueWithPower.toNumber() / 10 ** PRECISION, (v) => BigNumber.from(v).toNumber().toFixed(PRECISION))
    : humanNumber(valueWithPower.div(10 ** PRECISION));
}
