import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Card, Typography } from '@mui/material';
import { formatUnits } from 'ethers/lib/utils';
import { useCallback, useState } from 'react';
import useSWR from 'swr';

import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { Identicon } from '~/components/layout/identicon';
import { formatError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { fractionToPercent, shortenHexAddress, TOKEN_DECIMALS, TOKEN_TITLE } from '~/lib/utils';
import { balanceOfJA, distributeJointAccount } from '~/services/joint-accounts.service';
import { JointAccount } from '~/types/joint-account';

type Props = { jointAccount: JointAccount };

export const JointAccountCard = ({ jointAccount }: Props) => {
  const {
    account: { wallet },
    fractions,
    participants,
  } = jointAccount;
  const [isLoading, setLoading] = useState(false);
  const { showMessage } = useMessages();
  const { data: balance } = useSWR([`get-ja-balance`, wallet], () => balanceOfJA(wallet));

  const withdraw = useCallback(async () => {
    try {
      setLoading(true);
      const txHash = await distributeJointAccount(wallet, () => {
        showMessage('Your transaction has been confirmed');
      });
      showMessage(`Transaction submitted successfully with hash: ${txHash}!`, 'success');
    } catch (error) {
      showMessage(`Failed to distribute Joint Account. ${formatError(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, wallet]);

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar alt={wallet} sx={{ bgcolor: 'white', width: 48, height: 48 }}>
        <Identicon value={wallet} />
      </Avatar>
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {shortenHexAddress(wallet)} <CopyToClipboard value={wallet} />
        </Typography>
        {participants.map(({ wallet: participantWallet }, index) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={participantWallet}>
            <Box component={Icon} icon={pieChartOutline} sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }} />
            <Typography variant="body2" noWrap>
              {fractionToPercent(fractions[index])}% - {shortenHexAddress(participantWallet)}
            </Typography>
          </Box>
        ))}
      </Box>
      <LoadingButton loading={isLoading} size="small" variant="outlined" color="inherit" onClick={withdraw}>
        Withdraw {balance ? formatUnits(balance, TOKEN_DECIMALS) : 0} {TOKEN_TITLE}
      </LoadingButton>
    </Card>
  );
};
