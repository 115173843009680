import { providers } from 'ethers';

import { connectionManager } from '~/model/wallet';

export const getSigner = async (): Promise<providers.JsonRpcSigner> => {
  const signer = await connectionManager.getSigner();
  if (!signer) {
    throw new Error('You need to connect a wallet first!');
  }

  return signer;
};
