import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';

import { ChangeTenantModal } from '~/components/tenant/change-tenant-modal';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const ChangeTenantButton = () => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const activeTenantId = useActiveTenantId();
  const [tenant, setTenant] = useState(activeTenantId);
  const toggleModal = useCallback(() => {
    setModalOpened((opened) => !opened);
    setTenant(activeTenantId);
  }, [activeTenantId]);

  return (
    <>
      <Button className="mr-[10px] px-6 bg-white/5 flex items-center gap-x-2" onClick={toggleModal}>
        <div className="capitalize">{tenant}</div>
        <ChangeCircleIcon />
      </Button>
      <ChangeTenantModal open={isModalOpened} onClose={toggleModal} />
    </>
  );
};
