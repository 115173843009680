import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import useSwr from 'swr';

import { ContentProcessingResponse, getAssetProcessing } from '~/services/assets.service';

import { AssetProcessingItem } from './asset-processing-banner-item';

interface AssetsProcessingBannerProps {
  collectionAddress: string;
  nftId: number;
  trigger?: () => void;
}

const defaultProcessingResponse: ContentProcessingResponse = {
  isProcessing: false,
  unprocessedAssets: [],
};

export const AssetsProcessingBanner = ({ collectionAddress, nftId, trigger }: AssetsProcessingBannerProps) => {
  const { data: { isProcessing, unprocessedAssets } = defaultProcessingResponse, mutate } = useSwr(
    `${collectionAddress}-${nftId}`,
    () => getAssetProcessing(collectionAddress, nftId),
  );

  useEffect(() => {
    if (trigger) {
      void mutate();
    }
  }, [trigger, mutate]);

  useEffect(() => {
    const refetch = async () => {
      await mutate();
    };
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const interval = setInterval(refetch, 10000);

    return () => clearInterval(interval);
  }, [mutate]);

  if (!isProcessing) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center w-full p-4 text-white bg-red-500 rounded-lg gap-1">
      <div className="flex flex-row">
        <div className="mr-2">
          <CircularProgress size={20} color="warning" />
        </div>
        <div className="text-sm">Processing assets and updating metadata</div>
      </div>
      {unprocessedAssets.map((asset) => (
        <AssetProcessingItem
          key={asset.id}
          asset={asset}
          refetch={async () => {
            await mutate();
          }}
        />
      ))}
    </div>
  );
};
