import { useState } from 'react';

type UseOpenReturnType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const useOpen = (defaultState: boolean): UseOpenReturnType => {
  const [isOpen, setOpen] = useState(defaultState);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return { isOpen, open, close };
};
