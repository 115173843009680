import BoringAvatar from 'boring-avatars';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CopyToClipboard } from '~/components/layout/copy-to-clipboard';
import { colorsSchema } from '~/constants/avatar';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { ItemLayoutContext } from '~/context/item-layout-context';
import { putUrlParam } from '~/lib/hooks/use-url-param';
import { shortenHexAddress } from '~/lib/utils';
import { useNftMetadata } from '~/routes/wallet/hooks/use-nft-metadata';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export function NftsTitle() {
  const { nft } = useContext(ItemLayoutContext);
  const { nftId } = nft;
  const { metadata } = useNftMetadata(nft.collection.address, nftId);
  const userPubKey = useUserPublicKey();
  const activeTenant = useActiveTenantId();
  const accountHomeUrl = putUrlParam(
    routes[RoutesEnum.ACCOUNT_COLLECTIONS].url,
    {
      accountId: userPubKey,
    },
    activeTenant,
  );
  return (
    <div className="flex items-center gap-x-2">
      <Link to={accountHomeUrl} className="text-gray-300 text-sm">
        ← To minter account
      </Link>
      <div className="border-l-[1px] border-gray-500 mx-2 h-6" />
      <BoringAvatar size={32} name={nftId.toString()} variant="marble" colors={colorsSchema} />
      <h4 className="px-2 text-[2rem]">{metadata?.name ? metadata?.name : 'NFT'}</h4>
      <div className="flex gap-x-4 items-center">
        <span className="flex gap-x-1 items-center">
          <span className="text-gray-400">collection:</span>
          {shortenHexAddress(nft.collection.address)}
          <CopyToClipboard value={nft.collection.address} />
        </span>
        <span className="flex gap-x-1 items-center">
          <span className="text-gray-400">nftId:</span>
          {nftId}
          <CopyToClipboard value={nftId.toString()} />
        </span>
      </div>
    </div>
  );
}
