import { Button, Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';

import { HelpPopover } from '~/components/icons/help-popover';
import { Empty } from '~/components/layout/empty';
import { FlexBox } from '~/components/layout/flex-box';
import { PageLayout } from '~/components/layout/page-layout';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { getJointAccountsByOwner } from '~/services/joint-accounts.service';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

import { JointAccountCard } from './joint-account-card';

type Props = {
  owner: string;
};

export const ListJointAccounts = ({ owner }: Props) => {
  const { userPubKey } = useContext(AppContext);
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const { data: accounts, isLoading } = useSWR('get-joint-accounts', () => getJointAccountsByOwner(owner));

  return (
    <PageLayout isLoading={isLoading}>
      <Container sx={{ mb: 5 }}>
        <FlexBox sx={{ mb: 3, ml: 2 }}>
          <FlexBox sx={{ flexGrow: 1 }}>
            <Typography variant="h4">Joint Accounts</Typography>
            <HelpPopover>
              <Typography sx={{ m: 2, maxWidth: 360 }}>
                Joint accounts that distribute revenues between multiple parties.
              </Typography>
              <Typography sx={{ m: 2, maxWidth: 360 }}>
                Any one of the parties in the joint account can initiate a withdrawal and distribute USDC tokens from
                the joint accounts to one of its owners.
              </Typography>
            </HelpPopover>
          </FlexBox>
          {userPubKey === owner && (
            <FlexBox sx={{ flexShrink: 0 }}>
              <NavLink to={putUrlParam(routes[RoutesEnum.CREATE_JOINT_ACCOUNT].url, {}, activeTenant)}>
                <Button variant="contained">Create JA</Button>
              </NavLink>
              <HelpPopover>
                <Typography sx={{ p: 2, maxWidth: 360 }}>
                  In case you want to split royalties between multiple parties you can create a joint account
                </Typography>
              </HelpPopover>
            </FlexBox>
          )}
        </FlexBox>

        {accounts && accounts?.length > 0 ? (
          <Grid container spacing={3}>
            {accounts?.map((jointAccount) => (
              <Grid key={jointAccount.account.wallet} item xs={12} md={4}>
                <JointAccountCard jointAccount={jointAccount} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Empty />
        )}
      </Container>
    </PageLayout>
  );
};
