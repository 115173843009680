import classnames from 'classnames';
import { Link } from 'react-router-dom';

import EditIcon from '../assets/edit-icon.svg?jsx';
import { operationButtonClass } from './constants';

type Props = {
  url: string;
  title: string;
};

export function ItemEditButton({ url, title }: Props) {
  return (
    <Link to={url} className={classnames(operationButtonClass, 'px-6')} title={title}>
      <EditIcon />
    </Link>
  );
}
