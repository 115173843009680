import { ReactNode, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { AppLoader } from '~/components/loader/app-loader';
import { useMessages } from '~/lib/notificator';
import { getCollectionNft } from '~/services/assets.service';
import { emptyFullNft, FullNft } from '~/types/nft';

import { ItemLayoutContext } from './item-layout-context';

type Props = {
  children: ReactNode;
};

const fetcher = ([collection, nftId]: [string, string]) => getCollectionNft(collection, nftId);

export const ItemLayoutProvider = ({ children }: Props) => {
  const { nftId, collection } = useParams<{ nftId: string; collection: string }>();
  const { showMessage } = useMessages();

  const {
    data: nft,
    error,
    mutate,
  } = useSWR<FullNft, unknown>(collection && nftId ? [collection, nftId] : null, fetcher);
  const isReady = collection && nftId ? nft != null : true;

  useEffect(() => {
    if (error) {
      showMessage(`Error occurred. ${String(error)}`, 'error');
    }
  }, [error, showMessage]);

  const context = useMemo(() => ({ nft: nft || emptyFullNft, mutate }), [mutate, nft]);

  return <ItemLayoutContext.Provider value={context}>{isReady ? children : <AppLoader />}</ItemLayoutContext.Provider>;
};
