import { Box, Typography } from '@mui/material';

import img from '~/assets/img/nothing-here.svg';

const DEFAULT_WIDTH = 320;

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',

  '& img': {
    height: 'auto',
  },
};

type Props = {
  children?: string;
};

export const Empty = ({ children }: Props) => (
  <Box sx={style}>
    <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
      <img width={DEFAULT_WIDTH} src={img} alt="empty state" />
      <Typography sx={{ fontSize: '1.4rem!important', color: 'text.secondary' }} variant="h4">
        There is nothing here yet
      </Typography>
      {children && (
        <Box sx={{ color: 'text.secondary' }}>
          <Typography variant="body1">{children}</Typography>
        </Box>
      )}
    </Box>
  </Box>
);
