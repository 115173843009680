import { TenantParams } from '~/types/tenant-params';

import { ACTIVE_TENANT_KEY, TENANT_PARAMS_KEY } from './constants';

export const saveTenantParams = (params: TenantParams): void => {
  window.localStorage.setItem(TENANT_PARAMS_KEY, JSON.stringify(params));
};

export const setActiveTenant = (tenantId: string): void => {
  window.localStorage.setItem(ACTIVE_TENANT_KEY, tenantId);
};

export const getTenantServicePropertyByKey = async <T>(tenantId: string, propertyName: string): Promise<T> => {
  // TODO: Implement fetch once config service ready instead of get from ENV params
  const temporaryParamsJson = process.env.REACT_APP_TEMP_SERVICE_TENANTS_PARAMS;
  const temporaryParams = JSON.parse(temporaryParamsJson!);
  const tenantConfig = temporaryParams[tenantId];

  if (!tenantConfig) {
    throw new Error(`No config for tenant: ${tenantId}`);
  }

  return tenantConfig[propertyName];
};

export const getTenants = async (): Promise<string[]> => {
  // TODO: Implement fetch once config service ready instead of get from ENV params
  const temporaryParamsJson = process.env.REACT_APP_TEMP_SERVICE_TENANTS_PARAMS;
  const temporaryParams = JSON.parse(temporaryParamsJson!);

  return Object.keys(temporaryParams).map((temporaryParam) => temporaryParam);
};
