import { ChangeEvent, FocusEvent, forwardRef, useCallback } from 'react';
import { Field, useField } from 'react-final-form';

import { MaskedInputField } from '~/components/common/form/masked-input-field';
import { MaskedInputFormFieldPropsType } from '~/components/common/form/masked-input-form-field/masked-input-form-field.types';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';
import { getFieldError } from '~/utils/form';

export const MaskedInputFormField = forwardRef<HTMLInputElement, MaskedInputFormFieldPropsType>(
  ({ name, onBlur, onChange, ...props }, ref) => {
    const fieldValidator = useTextInputValidator<string>({
      required: props.required,
    });

    const {
      input,
      meta: { error, touched },
    } = useField<string>(name, { validate: fieldValidator });
    const { value, onChange: setValue } = input;
    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);

        setValue(event.target.value);
      },
      [onChange, setValue],
    );

    const handleOnBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        onBlur?.(e);

        input.onBlur(e);
      },
      [input, onBlur],
    );

    return (
      <Field name={name} type={props.type}>
        {(iProps) => (
          <MaskedInputField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...iProps}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            ref={ref}
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            error={getFieldError({ error, touched: touched as boolean })}
          />
        )}
      </Field>
    );
  },
);
