import { createContext } from 'react';

import { emptyFullNft } from '~/types/nft';

import { ItemLayoutType } from './types';

export const ItemLayoutContext = createContext<ItemLayoutType>({
  nft: emptyFullNft,
  mutate: () => Promise.resolve(),
});
