import { ItemLayout } from '~/components/layout/item-layout';
import { NftRoyalties } from '~/routes/royalties/nft-royalties';

import { NftNavigation } from './nft-navigation';

export const Royalties = () => (
  <ItemLayout navigation={NftNavigation}>
    <NftRoyalties />
  </ItemLayout>
);
