import { BigNumber } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';

import { TOKEN_DECIMALS } from '~/lib/utils';
import { getERC20 } from '~/services/get-erc20';

import { getCollection } from './get-collection';
import { getFToken } from './get-ftoken';

const getAmountToSend = (amount: string): BigNumber => parseUnits(amount, TOKEN_DECIMALS);

async function safeTransferFromCollectionContract(
  collectionAddress: string,
  from: string,
  toAccount: string,
  tokenId: string,
  amountToSend: BigNumber,
  onConfirmed: () => void,
) {
  const collection = await getCollection(collectionAddress);
  const tx = await collection.safeTransferFrom(from, toAccount, tokenId, amountToSend, [0]);

  // eslint-disable-next-line no-void
  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
}

export const safeTransferFrom = async (
  from: string,
  toAccount: string,
  tokenId: string,
  amount: string,
  onConfirmed: () => void,
  collectionAddress: string,
): Promise<string> => {
  const amountToSend = BigNumber.from(amount);

  return safeTransferFromCollectionContract(collectionAddress, from, toAccount, tokenId, amountToSend, onConfirmed);
};

export const withdraw = async (amount: string, onConfirmed: () => void): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const fToken = await getFToken();
  const tx = await fToken['withdraw(uint256)'](amountToSend);

  // eslint-disable-next-line no-void
  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};

export const transferErc20 = async (to: string, amount: string, onConfirmed: () => void): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const erc20 = await getERC20();
  const tx = await erc20.transfer(to, amountToSend);

  // eslint-disable-next-line no-void
  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};

/** Mint test ERC20 tokens in a development environment. */
export const devMint = async (toAccount: string, amount: string, onConfirmed: () => void): Promise<string> => {
  const amountToSend = getAmountToSend(amount);

  const erc20 = await getERC20();

  const tx = await erc20.mint(toAccount, amountToSend);

  // eslint-disable-next-line no-void
  void tx.wait().then(() => {
    onConfirmed();
  });

  return tx.hash;
};
