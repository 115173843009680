import { useContext } from 'react';
import useSWR from 'swr';

import { fetcher } from '~/constants/fetcher';
import { AppContext } from '~/context/app-context';
import { Game } from '~/routes/gaming/gaming.types';
import { GamesApiEndpoints } from '~/services/games';

export function useGamesListQuery() {
  const { userPubKey } = useContext(AppContext);

  const { data, isLoading, error, mutate } = useSWR<Game[], Error>(
    {
      url: GamesApiEndpoints.gamesUrl(),
      init: { headers: { 'x-public-key': userPubKey } },
    },
    fetcher,
  );

  return {
    games: data,
    isLoading,
    error,
    mutateGamesList: mutate,
  };
}
