import { isRecord } from '~/types/is-record';
import { isNumber, isNumberOrNullish, isPositiveNumber } from '~/types/numbers';
import { isString, isStringOrNullish } from '~/types/string';

type CollectionType = 'Series' | 'Season';

const isCollectionType = (val: unknown): val is CollectionType => val === 'Series' || val === 'Season';

export type Series = {
  id: number;
  name: string;
  type: CollectionType;
  metadata: {
    coverCid: string;
    logoCid?: string;
    description: string;
  };
};

export const isSeries = (val: unknown): val is Series =>
  isRecord(val) &&
  isPositiveNumber(val.id) &&
  isString(val.name) &&
  isCollectionType(val.type) &&
  isRecord(val.metadata) &&
  isString(val.metadata.coverCid) &&
  isString(val.metadata.description) &&
  val.metadata.logoCid != null
    ? isString(val.metadata.logoCid)
    : true;

export type SeasonInfo = {
  seasonId: number;
  seasonName: string;
  videosCount: number;
};

const IsSeasonInfo = (val: unknown): val is SeasonInfo =>
  isRecord(val) &&
  isPositiveNumber(val.seasonId) &&
  isString(val.seasonName) &&
  isNumber(val.videosCount) &&
  val.videosCount >= 0;

const IsSeasonInfoArray = (val: unknown): val is SeasonInfo[] => Array.isArray(val) && val.every(IsSeasonInfo);

export type SeriesFull = Series & {
  seasons: Array<SeasonInfo>;
};

export type SeriesEdit = Series & {
  seasons: number;
};

export const isSeriesEdit = (val: unknown): val is SeriesEdit =>
  isRecord(val) && typeof val.seasons === 'number' && val.seasons >= 0;

export type SeasonWithParent = Pick<Series, 'id' | 'name' | 'type'> & {
  parent?: Series;
};

const isSeriesFull = (val: unknown): val is SeriesFull =>
  isRecord(val) && IsSeasonInfoArray(val.seasons) && isSeries(val);

export const isSeriesFullArray = (val: unknown): val is SeriesFull[] => Array.isArray(val) && val.every(isSeriesFull);

const isSeasonWithParent = (val: unknown): val is SeasonWithParent =>
  isRecord(val) &&
  isPositiveNumber(val.id) &&
  isString(val.name) &&
  isCollectionType(val.type) &&
  (val.parent != null ? isSeries(val.parent) : true);

const isShortSeasonWithParent = (val: unknown): val is SeasonWithParent =>
  isRecord(val) && isPositiveNumber(val.id) && val.parent != null && isSeries(val.parent);

export const isSeasonsWithParentArray = (val: unknown): val is SeasonWithParent[] =>
  Array.isArray(val) && val.every(isSeasonWithParent);

export const isShortSeasonsWithParentArray = (val: unknown): val is SeasonWithParent[] =>
  Array.isArray(val) && val.every(isShortSeasonWithParent);

export type MetadataResponse = {
  id: number;
  tenantId: string;
  bucketId?: number;
  videoCid?: string;
  coverCid: string;
  videoTitle: string;
  videoDescription: string;
  collections: SeasonWithParent[];
  encrypted?: boolean;
};

export const isMetadataResponse = (val: unknown): val is MetadataResponse =>
  isRecord(val) &&
  isNumberOrNullish(val.bucketId) &&
  isStringOrNullish(val.videoCid) &&
  isString(val.coverCid) &&
  isString(val.videoTitle) &&
  isString(val.videoDescription) &&
  isString(val.tenantId) &&
  isSeasonsWithParentArray(val.collections);

export const isMetadataResponseArray = (val: unknown): val is MetadataResponse[] =>
  Array.isArray(val) && val.every((item) => isMetadataResponse(item));
