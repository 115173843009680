import { Button, CircularProgress, FormLabel, MenuItem, Select } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';

import { Input, Textarea } from '~/components/form/fields';
import { FileDropzoneField } from '~/components/form/fields/file-uploader/file-dropzone-field';
import { SimpleForm } from '~/components/form/simple-form';
import { PageLayoutV2 } from '~/components/layout/page/page-layout-v2';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { AppContext } from '~/context/app-context';
import { WalletNetworkType } from '~/context/types';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useMessages } from '~/lib/notificator';
import { uploadContent } from '~/services/upload-content';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

interface ContentUploadFormInterface {
  chainNamespace: WalletNetworkType;
  chainId: string;
  name: string;
  description: string;
  assets?: File[];
  previews?: File[];
  collectionAddress?: string;
  nftId?: string;
  wallet?: string;
}

export const ContentUpload = () => {
  const { showMessage } = useMessages();
  const { userPubKey, network } = useContext(AppContext);
  const [created, setCreated] = useState(false);
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();
  const [namespace, setNamespace] = useState<WalletNetworkType>(network);

  const networkTypeList = Object.keys(WalletNetworkType);

  const submit = useCallback(
    async ({ chainId, wallet, ...values }: ContentUploadFormInterface) => {
      let result;
      const publicKey = userPubKey;

      try {
        result = await uploadContent(
          namespace,
          chainId,
          namespace === WalletNetworkType.EIP155 ? publicKey : wallet!,
          values,
        );
        setCreated(true);
        showMessage(`Content ${result} was successful uploaded`, 'info');
      } catch (err: any) {
        const message = err?.message || String(err);
        showMessage(message, 'error');
      }
    },
    [showMessage, namespace, userPubKey],
  );

  const onChangeNetwork = useCallback(({ target }: EventTarget & HTMLInputElement) => {
    setNamespace(target.value);
  }, []);

  const initialValues: ContentUploadFormInterface = useMemo(
    () => ({
      chainNamespace: network,
      chainId: '80002',
      name: '',
      description: '',
      assets: [],
      previews: [],
      collectionAddress: '',
      nftId: '',
    }),
    [network],
  );

  if (created) {
    return (
      <Redirect
        to={putUrlParam(
          routes[RoutesEnum.CONTENT_LIST].url,
          {
            accountId: userPubKey,
          },
          activeTenant,
        )}
      />
    );
  }

  return (
    <PageLayoutV2 title="Upload new Content">
      <div className="my-5">
        <Form initialValues={initialValues} onSubmit={submit}>
          {({ handleSubmit, valid, submitting, pristine, invalid }) => (
            <SimpleForm
              formId="create-nft"
              submitDisabled={pristine}
              onSubmit={handleSubmit}
              hideSubmit
              submitButtonTitle="Create"
              isValid={() => valid}
              actionInProgress={submitting}
            >
              <div className="grid gap-x-4 my-6 grid-cols-2">
                <div>
                  <FormLabel>
                    <div>Network *</div>
                  </FormLabel>
                  <Select
                    className="w-full"
                    id="network"
                    variant="outlined"
                    name="chainNamespace"
                    value={namespace}
                    onChange={onChangeNetwork}
                  >
                    {networkTypeList.map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <FormLabel>
                    <div>Chain Id *</div>
                  </FormLabel>
                  <Input name="chainId" required />
                </div>
              </div>
              <div className="my-6">
                <FormLabel component="div">
                  <div className="inline-block mb-1 ml-1">Image, Video or Audio *</div>
                  <FileDropzoneField
                    required
                    accept="image/*,video/*,audio/*"
                    name="assets"
                    maxFiles={1}
                    helperText="File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF."
                  />
                </FormLabel>
              </div>
              <div className="my-6">
                <FormLabel>
                  <div className="inline-block mb-1 ml-1">Name *</div>
                  <Input name="name" required />
                </FormLabel>
              </div>
              <div className="my-6">
                <FormLabel>
                  <div className="inline-block mb-1 ml-1">Description *</div>
                  <Textarea
                    helperText="This description will be shown on the item’s detail page underneath the file"
                    name="description"
                    rows={4}
                    required
                  />
                </FormLabel>
              </div>
              <div className="grid gap-x-4 my-6 grid-cols-2">
                {namespace === WalletNetworkType.SOLANA && (
                  <div>
                    <FormLabel>
                      <div className="inline-block mb-1 ml-1">Wallet Address *</div>
                      <Input name="wallet" required />
                    </FormLabel>
                  </div>
                )}

                <div>
                  <FormLabel>
                    <div className="inline-block mb-1 ml-1">
                      {namespace === WalletNetworkType.SOLANA ? 'NFT Address' : 'Collection Address'} *
                    </div>
                    <Input name="collectionAddress" required />
                  </FormLabel>
                </div>

                {namespace === WalletNetworkType.EIP155 && (
                  <div>
                    <FormLabel>
                      <div className="inline-block mb-1 ml-1">NFT Id *</div>
                      <Input name="nftId" required />
                    </FormLabel>
                  </div>
                )}
              </div>

              <div className="my-6">
                <FormLabel component="div">
                  <div className="inline-block mb-1 ml-1">Cover image *</div>
                  <FileDropzoneField
                    required
                    accept="image/*"
                    name="previews"
                    maxFiles={1}
                    helperText="This image will be on the cover of the item"
                  />
                </FormLabel>
              </div>
              <div className="flex justify-center my-6">
                <Button disabled={invalid || submitting} type="submit" size="large" variant="contained">
                  <span>Create</span>
                  {submitting && <CircularProgress sx={{ ml: 1 }} size={24} />}
                </Button>
              </div>
            </SimpleForm>
          )}
        </Form>
      </div>
    </PageLayoutV2>
  );
};
