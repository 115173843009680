import { useCallback, useMemo } from 'react';

import { DEFAULT_TENANT } from '~/constants/env';
import { putUrlParam as putUrlParamLibrary } from '~/lib/url';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const putUrlParam = (urlPattern: string, params: Record<string, string | number>, defaultTenant: string) => {
  try {
    return putUrlParamLibrary(urlPattern, {
      tenantId: defaultTenant,
      ...params,
    });
  } catch (e) {
    return putUrlParamLibrary(urlPattern, {
      tenantId: String(DEFAULT_TENANT),
      ...params,
    });
  }
};

export const useUrlParams = () => {
  const activeTenant = useActiveTenantId();
  const putDefaultTenantUrlParam = useCallback(
    (urlPattern: string) => putUrlParam(urlPattern, {}, String(DEFAULT_TENANT)),
    [],
  );
  const putActiveTenantUrlParam = useCallback(
    (urlPattern: string, params: Record<string, string | number> = {}) => putUrlParam(urlPattern, params, activeTenant),
    [activeTenant],
  );

  return useMemo(
    () => ({
      putUrlParam,
      putDefaultTenantUrlParam,
      putActiveTenantUrlParam,
    }),
    [putActiveTenantUrlParam, putDefaultTenantUrlParam],
  );
};
