import classnames from 'classnames';
import { useCallback, useRef, useState } from 'react';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { deferred } from '~/lib/deferred/deferred';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { Confirmation } from '~/routes/video/components/confirmation';
import { deleteSubscriptionPlan } from '~/routes/video/services/subscription.service';
import { getSigner } from '~/services/get-signer';
import { generateWalletCredentials } from '~/services/wallet-auth/wallet-auth.service';

import GreenCheck from '../assets/green-check.svg?jsx';
import { ItemDeleteButton } from '../components/item-delete-button';
import { ItemEditButton } from '../components/item-edit-button';
import { useDdcIfileUrl } from '../hooks/use-ddc-ifile-url';

type Props = {
  id: number;
  title: string;
  coverCid?: string;
  activated?: boolean;
  refreshList: () => Promise<unknown>;
};

export function SubscriptionItem({ title, coverCid, activated, id, refreshList }: Props) {
  const url = useDdcIfileUrl(coverCid);
  const { putActiveTenantUrlParam } = useUrlParams();
  const subscriptionEditUrl = putActiveTenantUrlParam(routes[RoutesEnum.EDIT_VIDEO_SUBSCRIPTIONS].url, {
    subscriptionId: id,
  });
  const [confirm, setConfirm] = useState(false);
  const ref = useRef(deferred());
  const address = useUserPublicKey();

  const deleteSubscription = useCallback(() => {
    ref.current = deferred();
    setConfirm(true);
    return ref.current
      .then(async () => {
        const signer = await getSigner();
        const { message, signature } = await generateWalletCredentials(signer);
        return deleteSubscriptionPlan(id, { address, signature, message });
      })
      .then(() => refreshList())
      .catch((err) => console.error(err))
      .finally(() => {
        setConfirm(false);
      });
  }, [address, id, refreshList]);

  return (
    <article className="bg-white/5 border-2 p-3 gap-x-3 text-white items-center border-white/10 flex rounded-xl">
      <div className="rounded-lg overflow-hidden w-[72px] h-[72px] overflow-hidden">
        {url && <img src={url} className="w-full h-full object-cover" alt={title} />}
      </div>
      <h3 className="text-lg font-bold">{title}</h3>
      <button
        type="button"
        className={classnames(
          'flex',
          'gap-x-2',
          'ml-auto',
          'rounded-full',
          'py-0.5',
          'pl-3',
          'pr-5',
          'items-center',
          'transition-colors',
          'bg-white/20',
          'hover:bg-white/10',
        )}
      >
        {activated && <GreenCheck />}
        {activated ? 'Activated' : 'Deactivated'}
      </button>
      <div className="mx-4 flex items-center gap-x-4">
        <ItemEditButton url={subscriptionEditUrl} title="edit subscription plan" />
        <ItemDeleteButton onClick={deleteSubscription} label="delete subscription plan" />
      </div>
      <Confirmation
        open={confirm}
        confirm={() => ref.current.resolve(null)}
        reject={() => ref.current.reject()}
        title="The deletion of the subscription plan is irreversible"
        confirmButton="Delete"
        confirmText="to delete the subscription plan"
        rejectText="to cancel deletion"
      />
    </article>
  );
}
