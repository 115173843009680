import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';

import { formatResponseError } from '~/lib/formatters';
import { useMessages } from '~/lib/notificator';
import { Game } from '~/routes/gaming/gaming.types';
import { GamesApiEndpoints } from '~/services/games';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}

export function useGameUploadMutation({
  enable = false,
  setGameId,
}: {
  enable: boolean;
  setGameId: (gameId: string) => void;
}) {
  const { mutate } = useSWRConfig();
  const [isGameUploading, setUploading] = useState(false);
  const [isGameUploaded, setUploaded] = useState(false);
  const { showMessage } = useMessages();

  const uploadGameMutation = useCallback(
    async ({
      file,
      gameTitle,
      address,
      signature,
      gameCode,
    }: {
      file: File;
      gameTitle: string;
      address: string;
      signature: string;
      gameCode: string;
    }) => {
      if (!enable) {
        return;
      }
      setUploading(true);
      const form = new FormData();
      form.append('file', file);
      form.append('gameTitle', gameTitle);
      form.append('gameCode', gameCode);
      try {
        const response = await mutate<AxiosResponse<Game>>(
          GamesApiEndpoints.uploadGameUrl(),
          fetcher(GamesApiEndpoints.uploadGameUrl(), {
            method: 'POST',
            data: form,
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': gameTitle,
            },
          }),
        );
        if (response?.status === 201) {
          setUploading(false);
          setUploaded(true);
          setGameId(response.data.id);
        }
      } catch (e) {
        showMessage(`Error occurred. ${formatResponseError(e)}`, 'error');
        setUploading(false);
      } finally {
        setUploading(false);
      }
    },
    [enable, mutate, setGameId, showMessage],
  );
  return { uploadGameMutation, isGameUploading, isGameUploaded };
}
