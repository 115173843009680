import { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUserPublicKey } from '~/context/app-context';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const AuthWrapper: FC = ({ children }) => {
  const location = useLocation();
  const pubKey = useUserPublicKey();
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  const url = putUrlParam(routes[RoutesEnum.HOME].url, {}, activeTenant);

  if (!pubKey && location.pathname !== url) {
    return <Redirect to={url} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
