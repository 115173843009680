import axios, { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';

import { GamesApiEndpoints } from '~/services/games';

function fetcher(url: string, init?: AxiosRequestConfig) {
  return axios({ ...init, url });
}

export function useGameSettingEditMutation({ id, enable = false }: { id: string; enable: boolean }) {
  const { mutate } = useSWRConfig();
  const [isGameSettingsUploading, setSettingsUploading] = useState(false);
  const [isGameSettingsUploaded, setSettingsUploaded] = useState(false);

  const uploadGameSettingsMutation = useCallback(
    async ({
      nftId,
      title,
      address,
      signature,
      preloaderTitle,
      preloaderDescription,
      preloaderFile,
    }: {
      title: string;
      signature: string;
      address: string;
      nftId?: string;
      preloaderTitle?: string;
      preloaderDescription?: string[];
      preloaderFile?: File | string;
    }) => {
      if (!enable) {
        return;
      }
      setSettingsUploading(true);
      const form = new FormData();
      if (nftId) {
        form.append('nftId', nftId);
      }
      if (preloaderTitle) {
        form.append('preloaderTitle', preloaderTitle);
      }

      if (preloaderDescription) {
        preloaderDescription.forEach((description: string) => form.append('preloaderDescription', description));
      }

      if (preloaderFile) {
        form.append('preloaderFile', preloaderFile);
      }
      try {
        const response = await mutate(
          GamesApiEndpoints.gameSettingsUrl(id),
          fetcher(GamesApiEndpoints.gameSettingsUrl(id), {
            method: 'POST',
            data: form,
            headers: {
              'x-public-key': address,
              'x-signature': signature,
              'x-message': title,
            },
          }),
        );
        if (response?.status === 200) {
          await mutate(GamesApiEndpoints.gameUrl(id));
          setSettingsUploading(false);
          setSettingsUploaded(true);
        }
      } catch (e) {
        setSettingsUploading(false);
      } finally {
        setSettingsUploading(false);
      }
    },
    [enable, id, mutate],
  );
  return { uploadGameSettingsMutation, isGameSettingsUploading, isGameSettingsUploaded };
}
