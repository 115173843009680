import { BackButtonLink } from '~/components/common/buttons/back-button-link';
import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { GameUploadForm } from '~/routes/gaming/game-upload-route/components/game-upload-form';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export function GameUploadRoute() {
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();
  const gamesUrl = putUrlParam(routes[RoutesEnum.GAMES].url, {}, activeTenant);

  return (
    <div className="container mx-auto max-w-[900px]">
      <BackButtonLink to={gamesUrl} offset={{ bottom: 24 }}>
        My Games
      </BackButtonLink>
      <GameUploadForm />
    </div>
  );
}
