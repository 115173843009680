import {
  ApplicationEnum,
  createFreeport,
  createFreeportCollection,
  getERC20Address,
  getFreeportAddress,
} from '@cere/freeport-sc-sdk';

import { APPLICATION, CONTRACTS_DEPLOYMENT, DEFAULT_ROYALTY_RECEIVER } from '~/constants/env';
import { post } from '~/lib/request';
import { verifyGuard } from '~/lib/verify-guard';
import { uploadNftContent } from '~/routes/mint/upload-nft-content';
import { getERC20 } from '~/services/get-erc20';
import { getSigner } from '~/services/get-signer';
import { marketplaceGrantApproval } from '~/services/marketplace-grant-approval';
import { mintNft } from '~/services/mint-nft';
import { setupTokenRoyalties } from '~/services/royalties-service';
import { CollectionMain } from '~/types/collection';
import { isString } from '~/types/string';

import { NetworkServiceInterface } from './interface/network.service.interface';

export class EthNetworkService implements NetworkServiceInterface {
  public async signMessage(message: string): Promise<{ signature: string }> {
    const signer = await getSigner();
    const signature = await signer.signMessage(message);
    return { signature };
  }

  public async createCollection(collectionName: string): Promise<{ collectionAddress: string; createTxHash: string }> {
    const minter = await getSigner();
    const minterAddress = await minter.getAddress();
    const collectionUri = await post(`api/wallet/${minterAddress}/create-collection-bucket`).then((r) => r.text());
    const { provider } = minter;
    const erc20Token = await getERC20Address(provider, CONTRACTS_DEPLOYMENT, APPLICATION() as ApplicationEnum);
    const freeportAddress = await getFreeportAddress(provider, CONTRACTS_DEPLOYMENT, APPLICATION() as ApplicationEnum);
    const freeport = createFreeport({ signer: minter, contractAddress: freeportAddress });

    let tx = await freeport.createCollection(collectionName, collectionUri, 0, erc20Token, minterAddress);
    const createTxHash = tx.hash;

    const result = await tx.wait();
    const createEventArgs = result?.events?.find?.(({ event }) => event === 'CollectionCreated')?.args;
    const collectionAddress = verifyGuard(createEventArgs?.[2], isString);

    const collection = createFreeportCollection({ signer: minter, contractAddress: collectionAddress });
    const operatorRole = await collection.operatorRole();
    tx = await collection.grantRole(operatorRole, minterAddress);
    await tx.wait();

    tx = await collection['setDefaultPrimaryRoyalty(address,uint96)'](DEFAULT_ROYALTY_RECEIVER, 0);
    await tx.wait();

    tx = await collection.setDefaultRoyalty(DEFAULT_ROYALTY_RECEIVER, 0);
    await tx.wait();

    await post(`api/wallet/${minterAddress}/finalize/${collectionAddress}`);

    return { collectionAddress, createTxHash };
  }

  public async getBalance(): Promise<{ balance: number }> {
    const signer = await getSigner();
    const publicKey = await signer.getAddress();
    const erc20 = await getERC20();
    const balance = await erc20.balanceOf(publicKey);
    return { balance: balance.toString() };
  }

  public async mintNft(values: {
    name: string;
    assets?: File[];
    description: string;
    collection: CollectionMain;
    previews?: File[];
    supply: number;
    tokenRoyalty?: {
      address: string;
      fraction: number | string;
    };
    tokenPrimaryRoyalty?: {
      address: string;
      fraction: number | string;
    };
  }): Promise<any> {
    const signer = await getSigner();
    const userPubKey = await signer.getAddress();
    const cid = await uploadNftContent(userPubKey, values);
    const collectionAddress = values.collection.address;
    const [, nftId] = await mintNft(signer, {
      collectionAddress,
      cid,
      supply: values.supply,
    });
    if (values.tokenRoyalty?.address && values.tokenPrimaryRoyalty?.address) {
      await setupTokenRoyalties({
        tokenRoyalty: {
          address: values.tokenRoyalty.address,
          fraction: Number(values.tokenRoyalty.fraction),
        },
        tokenPrimaryRoyalty: {
          address: values.tokenPrimaryRoyalty.address,
          fraction: Number(values.tokenPrimaryRoyalty.fraction),
        },
        collectionAddress,
        nftId,
      });
    }
    const txHash = await marketplaceGrantApproval({ signer, collectionAddress });
    return { nftId, txHash };
  }
}
