import { BigNumber, BigNumberish } from 'ethers';

export const isNumber = (val: unknown): val is number => typeof val === 'number' && !Number.isNaN(val);

export const isPositiveNumber = (val: unknown): val is number => isNumber(val) && val > 0;

export const isNumberOrNullish = (val: unknown): val is number | undefined => (val == null ? true : isNumber(val));

export const isNumberish = (val: unknown): val is string => !Number.isNaN(Number(val));

export const isBigNumberish = (val: unknown): val is BigNumberish => {
  try {
    BigNumber.from(val);
    return true;
  } catch (e) {
    return false;
  }
};

export const isPositiveAndSafeInteger = (val: unknown): val is number =>
  isPositiveNumber(val) && val <= Number.MAX_SAFE_INTEGER;
