import { useParams } from 'react-router-dom';

import { ItemLayout } from '~/components/layout/item-layout';
import { Benefits } from '~/routes/benefits/benefits';

import { AccountNavigation } from './account-navigation';

export const AccountBenefits = () => {
  const { accountId } = useParams<{ accountId: string }>();

  return (
    <ItemLayout navigation={AccountNavigation}>
      <Benefits account={accountId} />
    </ItemLayout>
  );
};
