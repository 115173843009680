import { Redirect, Route, Switch } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { RoutesEnum } from '~/constants/routes-enum';
import { useUrlParams } from '~/lib/hooks/use-url-param';
import { AccountBenefits } from '~/routes/account/account-benefits';
import { AccountCollections } from '~/routes/account/account-collections';
import { AccountJointList } from '~/routes/account/account-joint-list';
import { AccountMinted } from '~/routes/account/account-minted';
import { AccountOwned } from '~/routes/account/account-owned';
import { ContentList } from '~/routes/content/content-list';
import { ContentUpload } from '~/routes/content/content-upload';
import { Gaming } from '~/routes/gaming/gaming';
import { Home } from '~/routes/home/home';
import { CreateJointAccount } from '~/routes/joint-accounts/create-joint-account';
import { Landing } from '~/routes/landing/landing';
import { Mint } from '~/routes/mint/mint';
import { NftAssets } from '~/routes/nft/nft-assets';
import { NftAuction } from '~/routes/nft/nft-auction';
import { NftAuctions } from '~/routes/nft/nft-auctions';
import { NftOffers } from '~/routes/nft/nft-offers';
import { Royalties } from '~/routes/nft/nft-royalties';
import { NftTransfer } from '~/routes/nft/nft-transfer';
import { Video } from '~/routes/video/video';
import { TransferCere } from '~/routes/wallet/transfer-cere';
import { useActiveTenantId } from '~/services/use-active-tenant-id';

export const Router = () => {
  const { putUrlParam } = useUrlParams();
  const activeTenant = useActiveTenantId();

  return (
    <Switch>
      <Route path={routes[RoutesEnum.ACCOUNT_COLLECTIONS].url}>
        <AccountCollections />
      </Route>
      <Route path={routes[RoutesEnum.ACCOUNT_MINTED].url}>
        <AccountMinted />
      </Route>
      <Route path={routes[RoutesEnum.ACCOUNT_OWNED].url}>
        <AccountOwned />
      </Route>
      <Route path={routes[RoutesEnum.ACCOUNT_BENEFITS].url}>
        <AccountBenefits />
      </Route>
      <Route path={routes[RoutesEnum.ACCOUNT_JOINT_LIST].url}>
        <AccountJointList />
      </Route>
      <Route path={routes[RoutesEnum.NFT_ASSETS].url}>
        <NftAssets />
      </Route>
      <Route path={routes[RoutesEnum.NFT_OFFERS].url}>
        <NftOffers />
      </Route>
      <Route path={routes[RoutesEnum.NFT_AUCTION].url}>
        <NftAuction />
      </Route>
      <Route path={routes[RoutesEnum.NFT_AUCTIONS].url}>
        <NftAuctions />
      </Route>
      <Route path={routes[RoutesEnum.NFT_ROYALTIES].url}>
        <Royalties />
      </Route>
      <Route path={routes[RoutesEnum.NFT_TRANSFER].url}>
        <NftTransfer />
      </Route>
      <Route path={routes[RoutesEnum.TRANSFER_CERE_TOKENS].url}>
        <TransferCere />
      </Route>
      <Route path={routes[RoutesEnum.CREATE_JOINT_ACCOUNT].url}>
        <CreateJointAccount />
      </Route>
      <Route path={routes[RoutesEnum.GAMES].url}>
        <Gaming />
      </Route>
      <Route path={routes[RoutesEnum.VIDEO].url}>
        <Video />
      </Route>
      <Route path={routes[RoutesEnum.MINT].url}>
        <Mint />
      </Route>
      <Route exact path={routes[RoutesEnum.LANDING].url}>
        <Landing />
      </Route>
      <Route exact path={routes[RoutesEnum.HOME].url}>
        <Home />
      </Route>

      <Route path={routes[RoutesEnum.CONTENT_LIST].url}>
        <ContentList />
      </Route>
      <Route path={routes[RoutesEnum.CONTENT_UPLOAD].url}>
        <ContentUpload />
      </Route>

      <Route path="*">
        <Redirect to={putUrlParam(routes[RoutesEnum.HOME].url, {}, activeTenant)} />
      </Route>
    </Switch>
  );
};
