import classnames from 'classnames';
import React from 'react';

import FolderIcon from '~/assets/icons/icon-folder.svg?jsx';

import styles from './folder-structure.module.css';

export function FolderStructure() {
  return (
    <div className={classnames(styles.tree, 'ml-[10px]')}>
      <ul className={styles['first-level']}>
        <li>
          <div className="flex gap-x-2 items-center">
            <FolderIcon /> <span>GAME_NAME</span>
          </div>
          <ul>
            <li>index.html</li>
            <li className="before:block before:absolute">
              <div className="flex gap-x-2 items-center">
                <FolderIcon /> <span>config</span>
              </div>
              <ul>
                <li>metadata.json</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
