import { ItemLayout } from '~/components/layout/item-layout';
import { TokenDetails } from '~/routes/wallet/token-details';

import { NftNavigation } from './nft-navigation';

export const NftAssets = () => (
  <ItemLayout navigation={NftNavigation}>
    <TokenDetails />
  </ItemLayout>
);
