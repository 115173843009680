import { getIn } from 'final-form';
import { useCallback } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { Field, useField, useForm } from 'react-final-form';

import { FileDropzone } from '~/components/form/fields/file-uploader/file-dropzone';
import { useTextInputValidator } from '~/components/form/fields/use-text-input-validator';

type Props = Pick<DropzoneOptions, 'accept' | 'maxFiles' | 'minSize' | 'maxSize' | 'disabled'> & {
  helperText?: string;
  name: string;
  uploadProgress?: number;
  required?: boolean;
};

export const FileDropzoneField = ({
  name,
  disabled,
  helperText,
  accept,
  maxFiles = 1,
  minSize,
  maxSize,
  uploadProgress,
  required = false,
}: Props) => {
  const fieldValidator = useTextInputValidator({
    required,
  });
  const form = useForm();
  const field = useField<File[]>(name);

  const onRemove = useCallback(
    (file: File) => {
      const value = getIn(form.getState().values, name) as File[];
      form.change(
        name,
        value.filter((uploadedFile) => uploadedFile !== file),
      );
    },
    [form, name],
  );

  const onDrop = useCallback(
    (files: File[]) => {
      const addedFiles = getIn(form.getState().values, name) as File[];
      if (maxFiles === 1) {
        form.change(name, files);
        return;
      }

      form.change(name, [...addedFiles, ...files].filter(Boolean));
    },
    [form, name, maxFiles],
  );

  return (
    <Field name={name} validate={fieldValidator}>
      {() => (
        <FileDropzone
          helperText={helperText}
          files={field.input.value}
          disabled={disabled}
          accept={accept}
          maxFiles={maxFiles}
          minSize={minSize}
          multiple={maxFiles > 1}
          maxSize={maxSize}
          uploadProgress={uploadProgress}
          onRemove={onRemove}
          onDrop={onDrop}
        />
      )}
    </Field>
  );
};
